import React, {Fragment, ReactComponentElement, useMemo} from "react";
import {
    alpha,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel, Typography
} from "@mui/material";
import {ExpandIcon} from "../icons";
import {borderRadius, theme} from "../theme";
import {Page} from "../data/types";
import {PagingSettings} from "../data/pagings";
import {ContentBody, ContentLoader} from "./ContentLayout";
import {useMessages} from "../i18n";

export type ColumnType = { [sortKey: string]: { name: string, align?: 'left'|'right'|'center', unsortable?: boolean } }

type ContentTableProps<T> = {
    pageable: PagingSettings<keyof ColumnType>,
    columns: ColumnType,
    onPageableChange: (pageable: PagingSettings<keyof ColumnType>) => void,
    page?: Page<T>,
    enableCheckbox?: boolean,
    renderTableBody?: ReactComponentElement<any>,
}

export const ContentTable = <T extends unknown>({pageable, columns, onPageableChange, page, enableCheckbox, renderTableBody}: ContentTableProps<T>) => {
    const currentPage = useMemo(() => page, [page]);
    const currentPageable = useMemo(() => pageable, [pageable]);

    const handleSortColumn = (key: keyof typeof columns) => {
        const {sort, direction} = currentPageable;
        let oD: 'desc'|'asc';
        if (sort == key) {
            oD = direction === 'desc' ? 'asc' : 'desc';
        } else {
            oD = 'desc';
        }
        updatePageable({
            ...currentPageable,
            sort: key,
            direction: oD
        });
    };

    const handlePageChange = (e: any, pageNumber: number) => {
        updatePageable({
            ...currentPageable,
            pageNumber: pageNumber
        });
    };

    const handleRowsPerPageChange = (e: any) => {
        updatePageable({
            ...currentPageable,
            pageSize: e.target.value as number
        });
    };

    const updatePageable = (settings: PagingSettings<keyof typeof columns>) => {
        onPageableChange(settings);
    }

    const m = useMessages();
    return (
        <ContentBody style={{ overflow: 'hidden' }}>
            {!currentPage && <ContentLoader />}
            {currentPage && (
                <Fragment>
                    <TableContainer>
                        <Table sx={{ '& .MuiTableCell-root': {
                                borderColor: theme.palette.grey['100']
                            } }}>
                            <TableHead sx={{
                                '& .MuiTableCell-root': {
                                    color: alpha('#000', 0.7),
                                    '& .Mui-active, & .Mui-active *': {
                                        color: theme.palette.primary.main + ' !important'
                                    }
                                }
                            }}>
                                <TableRow>
                                    {enableCheckbox && (
                                        <TableCell align="center">#</TableCell>
                                    )}
                                    {Object.keys(columns).map((k) => (
                                        <TableCell key={'data-table-' + k} align={columns[k].align} sx={{ whiteSpace: 'nowrap' }}>
                                            {!columns[k].unsortable && (
                                                <TableSortLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    active={currentPageable.sort === k}
                                                    direction={currentPageable.sort === k ? currentPageable.direction : 'asc'}
                                                    onClick={() => handleSortColumn(k)}
                                                >
                                                    {columns[k].name}
                                                </TableSortLabel>
                                            )}
                                            {columns[k].unsortable && (columns[k].name)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{
                                '& > tr:not(.empty)': {
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: `${alpha(theme.palette.grey['50'], 0.3)} !important`
                                    }
                                }
                            }}>
                                {currentPage.content.length === 0 && (
                                    <TableRow className={'empty'}>
                                        <TableCell colSpan={Object.keys(columns).length + (enableCheckbox ? 2 : 0)}>
                                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
                                                {/*<img src={emptyGraphic} alt="Empty" className={classes.graphic} />*/}
                                                {/*<Box my={2}/>*/}
                                                <Typography color="textSecondary">{m.emptyContent}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {renderTableBody && renderTableBody}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={currentPage.totalElements}
                        rowsPerPage={currentPageable.pageSize}
                        page={currentPageable.pageNumber}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        SelectProps={{
                            sx: {
                                zIndex: 10,
                                '&:focus': {
                                    borderRadius,
                                    backgroundColor: alpha(theme.palette.grey['100'], 0.5)
                                }
                            },
                            IconComponent: () => <ExpandIcon fontSize="inherit" sx={{
                                position: 'relative',
                                zIndex: 5,
                                right: theme.spacing(2)
                            }}/>
                        }}
                    />
                </Fragment>
            )}
        </ContentBody>
    )
};