import React, {PropsWithChildren, useContext, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {Box, Button, Container, Dialog, DialogContent, Typography} from "@mui/material";
import {theme} from "../theme";
import {AppNav} from "./AppNav";
import {AuthContext} from "../data/context";
import {TopBar} from "./TopBar";
import {useMessages} from "../i18n";
import {LockOpenIcon} from "../icons";

type AppContainerProps = {
    empty?: boolean;
    maxWidth?: 'lg'|'md'|'sm'|'xs';
}

const PUBLIC_PATHS = [
    'login',
    'logout',
    'signup/cf',
    'profile/cf',
    'reset-password',
    'user-accepts'
]

export const AppContainer = ({empty, maxWidth, children}: PropsWithChildren<AppContainerProps>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {authenticated} = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const containsPublicPath = (pathName: string) => {
        let result = false;
        for (let path of PUBLIC_PATHS) {
            if (pathName.indexOf(path) >= 0) return true;
        }
        return result;
    }

    const showLoginPrompt = useMemo(() => {
        return !authenticated && !containsPublicPath(location.pathname);
    }, [authenticated, location]);

    const showContent = useMemo(() => {
        return authenticated || containsPublicPath(location.pathname);
    }, [authenticated, location]);

    const m = useMessages();
    return (
        <div style={{
            display: 'flex',
            minHeight: '100%',
            overflow: 'hidden'
        }}>
            {(!empty && authenticated && !containsPublicPath(location.pathname)) && <TopBar onOpen={() => setMenuOpen(true)}/>}
            {(!empty && authenticated && !containsPublicPath(location.pathname)) && <AppNav open={menuOpen} onClose={() => setMenuOpen(false)}/>}

            {showContent && (
                <Container
                    maxWidth={false}
                    sx={{
                        paddingTop: theme.spacing(14),
                        paddingBottom: theme.spacing(10),
                        paddingLeft: theme.spacing(4),
                        paddingRight: theme.spacing(4),
                        minHeight: 'calc(100vh)',
                        overflowX: 'auto',
                        [theme.breakpoints.down('sm')]: {
                            paddingTop: theme.spacing(12),
                            paddingBottom: theme.spacing(12),
                            paddingLeft: theme.spacing(1.5),
                            paddingRight: theme.spacing(1.5),
                        }
                    }}
                    children={children as any}
                />
            )}

            <Dialog open={showLoginPrompt} fullWidth maxWidth="xs">
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5" align="center">{m.unauthorize.title}</Typography>
                    <Box my={0.5} />
                    <Typography align="center">{m.unauthorize.message}</Typography>
                    <Box my={1.5} />
                    <Button disableElevation variant="contained" color="primary"
                            onClick={() => navigate('/login')}
                            startIcon={<LockOpenIcon fontSize="inherit" />}
                    >
                        {m.unauthorize.btn}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}