import React, {Fragment, useMemo, useState} from "react";
import {Box, Button, Divider, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Facility, FacilityCreateRequest, FacilityType, FacilityUpdateRequest} from "../../data/types";

import {useMessages} from "../../i18n";
import {SaveIcon} from "../../icons";
import {CheckboxInput} from "../../component/CheckboxInput";
import {TextInput} from "../../component/TextInput";
import {CountrySelector} from "../../component/CountrySelector";
import {TaxedAmountEditor} from "../../component/TaxedAmountEditor";
import { authStore } from "../../store";
import {SelectInput} from "../../component/SelectInput";
import {formatAmountDigit} from "../../tools";

type FacilityFormProps = {
    inProgress: boolean,
    onCancel: () => void,
    facility?: Facility,
    onCreate?: (form: FacilityCreateRequest) => void,
    onSave?: (form: FacilityUpdateRequest) => void
}

export const FacilityForm = ({inProgress, onCancel, facility, onCreate, onSave}: FacilityFormProps) => {
    const [createForm, setCreateForm] = useState<FacilityCreateRequest>({ name: '', address: {}, lta: {}, allowedIntlWallet: false, rangefee: {} });
    const [updateForm, setUpdateForm] = useState<FacilityUpdateRequest|undefined>(facility ? { ...facility, address: facility.address || {}, lta: facility.lta || {} } : undefined);

    const getForm = useMemo(() => updateForm ? updateForm : createForm, [updateForm, createForm]);
    const setForm = useMemo(() => updateForm ? setUpdateForm : setCreateForm, [updateForm]);
    const canSubmit = useMemo(() => (!inProgress && getForm.name && getForm.address && getForm.address.addressLine1 && getForm.address.zipCode && getForm.address.city && getForm.address.country) as boolean, [inProgress, getForm]);

    const onFormChange = (name: string, value: any) => {
        setForm({
            ...getForm,
            [name]: value
        });
    }

    const onAddressChange = (name: string, value: string) => {
        setForm({
            ...getForm,
            address: {
                ...getForm.address,
                [name]: value
            }
        });
    }

    const onLtaChange = (name: string, value: string) => {
        setForm({
            ...getForm,
            lta: {
                ...getForm.lta,
                [name]: value
            }
        });
    }

    const submit = () => {
        if (facility && updateForm) {
            if (onSave) onSave(getForm);
        } else {
            if (onCreate) onCreate(getForm);
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <Typography variant="h6">{m.common.identity}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextInput label={m.common.name}
                               value={getForm.name} onChange={v => onFormChange('name', v)}
                               error={!getForm.name}
                               helperText={!getForm.name ? m.error.requiredField : undefined}
                    />
                </Grid>
                {authStore.isRoot && (
                    <Fragment>
                        <Grid item md={2} xs={12}>
                            <TextInput label={`LTA (${m.common.address.country})`} type="number"
                                       value={getForm.lta.land} onChange={v => onLtaChange('land', v)}
                                       error={!getForm.lta.land}
                                       helperText={!getForm.lta.land ? m.error.requiredField : undefined}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <TextInput label={`LTA (${m.common.type})`} type="number"
                                       value={getForm.lta.type} onChange={v => onLtaChange('type', v)}
                                       error={!getForm.lta.type}
                                       helperText={!getForm.lta.type ? m.error.requiredField : undefined}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <TextInput label={`LTA (${m.facilities.singular})`} type="number"
                                       value={getForm.lta.clubNr} onChange={v => onLtaChange('clubNr', v)}
                                       error={!getForm.lta.clubNr}
                                       helperText={!getForm.lta.clubNr ? m.error.requiredField : undefined}
                            />
                        </Grid>
                    </Fragment>
                )}
                <Grid item xs={12}>
                    <TextInput label={m.common.description}
                               value={getForm.description ?? ''} onChange={v => onFormChange('description', v)}
                               multiline rows={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxInput label={m.facilities.allowedIntlWallet}
                                   value={getForm.allowedIntlWallet} onChange={v => onFormChange('allowedIntlWallet', v)}
                    />
                </Grid>
                {authStore.isRoot && (
                    <Fragment>
                        <Grid item sm={6} xs={12}>
                            <SelectInput label={m.common.type}
                                         options={Object.values(FacilityType).map(t => ({ label: m.facilities.types[t], value: t }))}
                                         value={getForm.type}
                                         onChange={type => setForm({ ...getForm, type })}
                                         error={!getForm.type}
                                         helperText={!getForm.type ? m.error.requiredField : undefined}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}></Grid>
                    </Fragment>
                )}
                <Grid item xs={3}>
                    <TextInput label={m.facilities.offlineSessionDuration}
                               value={getForm.offlineSessionDuration ?? ''}
                               onChange={offlineSessionDuration => setForm({ ...getForm, offlineSessionDuration })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput label={m.facilities.maxScanDuration}
                               value={getForm.maxScanDuration ?? ''}
                               onChange={maxScanDuration => setForm({ ...getForm, maxScanDuration })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography><strong>Rangefee</strong></Typography>
                    <Box my={2} />
                    <TaxedAmountEditor
                        compact fromGross
                        taxedAmount={getForm.rangefee}
                        onChange={rangefee => setForm({...getForm, rangefee})}
                    />
                </Grid>
                {authStore.isRoot && (
                    <Grid item sm={3} xs={12}>
                        <Typography><strong>{m.common.serviceFee}</strong></Typography>
                        <Box my={2} />
                        <TextField fullWidth label={m.common.amount}
                                   name="pinpointServiceFee" value={getForm.pinpointServiceFee}
                                   variant="outlined"
                                   onChange={ev => setForm({...getForm, pinpointServiceFee: ev.target.value as any })}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                   }}
                        />
                    </Grid>
                )}
            </Grid>

            <Box my={3}><Divider/></Box>

            <Typography variant="h6">{m.common.address.title}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.address.addressLine1}
                               value={getForm.address.addressLine1 ?? ''}
                               onChange={v => onAddressChange('addressLine1', v)}
                               error={!getForm.address.addressLine1}
                               helperText={!getForm.address.addressLine1 ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.address.addressLine2}
                               value={getForm.address.addressLine2 ?? ''}
                               onChange={v => onAddressChange('addressLine2', v)}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextInput label={m.common.address.zipCode}
                               value={getForm.address.zipCode ?? ''}
                               onChange={v => onAddressChange('zipCode', v)}
                               error={!getForm.address.zipCode}
                               helperText={!getForm.address.zipCode ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextInput label={m.common.address.city}
                               value={getForm.address.city ?? ''}
                               onChange={v => onAddressChange('city', v)}
                               error={!getForm.address.city}
                               helperText={!getForm.address.city ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CountrySelector countryCode={getForm.address.country}
                                     onChange={cc => onAddressChange('country', cc)}
                                     required
                    />
                </Grid>
            </Grid>

            <Box my={2}><Divider/></Box>

            <Typography variant="h6">{m.common.contactDetails}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.contactPerson}
                               value={getForm.contactPerson ?? ''}
                               onChange={v => onFormChange('contactPerson', v)}
                    />
                </Grid>
            </Grid>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.email}
                               value={getForm.contactEmail ?? ''}
                               onChange={v => onFormChange('contactEmail', v)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.facilities.supportEmail}
                               value={getForm.supportEmail ?? ''}
                               onChange={v => onFormChange('supportEmail', v)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.phone}
                               value={getForm.contactPhone ?? ''}
                               onChange={v => onFormChange('contactPhone', v)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.phone}
                               value={getForm.supportPhone ?? ''}
                               onChange={v => onFormChange('supportPhone', v)}
                    />
                </Grid>
            </Grid>

            <Box my={3}/>

            <Box display="flex" justifyContent="flex-end">
                <Button disableElevation variant="contained" color="primary" size="medium"
                        disabled={!canSubmit} startIcon={<SaveIcon/>}
                        onClick={submit}>{updateForm ? m.actions.save : m.actions.create}</Button>
                <Box mx={1}/>
                <Button size="medium" onClick={onCancel}>{m.actions.cancel}</Button>
            </Box>
        </Fragment>
    )
}