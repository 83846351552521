import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {Alert, Box, Grid, MenuItem, Snackbar, TextField, Typography} from "@mui/material";
import {UserContext} from "../../data/context";
import {i18n, useMessages} from "../../i18n";
import {ContentBody, ContentTitle} from "../../component/ContentLayout";
import {changePassword, updateProfile} from "../../data/preferences";
import {authStore} from "../../store";

export const Preferences = () => {
    const {user, setUser} = useContext(UserContext);
    const [passwordForm, setPasswordForm] = useState<{ newPw: string, confirmPw: string }>({ newPw: '', confirmPw: '' });
    const [profileForm, setProfileForm] = useState<{ position?: string, firstName?: string, lastName?: string }>({ position: '', firstName: '', lastName: '' });
    const [inProgress, setInProgress] = useState(false);
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [profileSuccess, setProfileSuccess] = useState(false);

    useEffect(() => {
        authStore.load()
            .then(u => {
                if (u) {
                    setProfileForm({
                        position: u.position,
                        firstName: u.firstName,
                        lastName: u.lastName
                    });
                }
            })
    }, [])

    const canChangePassword = useMemo(() => {
        return (!!passwordForm.newPw && passwordForm.newPw === passwordForm.confirmPw);
    }, [passwordForm])

    const handleLanguageChange = (lang: string) => {
        i18n.language = lang;
        window.location.reload();
    }

    const handlePasswordChange = async () => {
        if (canChangePassword) {
            setPasswordError(false);
            setInProgress(true);
            try {
                await changePassword(passwordForm.newPw);
                setPasswordForm({ newPw: '', confirmPw: '' });
                setPasswordSuccess(true);
            } catch (error) {
                console.error("Failed to change password", error);
                setPasswordError(true)
            }
            setInProgress(false);
        }
    }

    const handleProfileUpdate = async () => {
        setInProgress(true);
        try {
            await updateProfile(profileForm);
            setUser({
                ...user,
                ...profileForm
            });
            setProfileSuccess(true);
        } catch (error) {
            console.error("Failed to update profile", error);
        }
        setInProgress(false);
    }


    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={9} xs={12}>
                    <ContentTitle title={m.preferences.title} />
                </Grid>
            </Grid>
            <Box my={3} />
            {/*<ContentBody padding={3}>*/}
            {/*    <Typography fontSize="1.25rem">{m.preferences.changePassword}</Typography>*/}
            {/*    <Box my={2} />*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        <Grid item sm={6} xs={12}>*/}
            {/*            <TextField fullWidth type="password"*/}
            {/*                       InputLabelProps={{ shrink: true }}*/}
            {/*                       label={m.resetPassword.newPassword}*/}
            {/*                       value={passwordForm.newPw} onChange={v => setPasswordForm({ ...passwordForm, newPw: v.target.value })}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*        <Grid item sm={6} xs={12}>*/}
            {/*            <TextField fullWidth type="password"*/}
            {/*                       InputLabelProps={{ shrink: true }}*/}
            {/*                       label={m.resetPassword.confirmNewPassword}*/}
            {/*                       value={passwordForm.confirmPw} onChange={v => setPasswordForm({ ...passwordForm, confirmPw: v.target.value })}*/}
            {/*                       error={!!passwordForm.newPw && passwordForm.newPw !== passwordForm.confirmPw} helperText={!!passwordForm.newPw && passwordForm.newPw !== passwordForm.confirmPw ? m.resetPassword.newPasswordNotMatchError : undefined}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*    {passwordError && (*/}
            {/*        <Fragment>*/}
            {/*            <Box my={3} />*/}
            {/*            <Alert variant="filled" severity="error">{m.preferences.changePasswordError}</Alert>*/}
            {/*        </Fragment>*/}
            {/*    )}*/}
            {/*    <Box my={3} />*/}
            {/*    <Box style={{ textAlign: 'right' }}>*/}
            {/*        <AppButton label={m.actions.confirm} color="primary" onClick={handlePasswordChange} loading={inProgress} disabled={!canChangePassword} />*/}
            {/*    </Box>*/}
            {/*</ContentBody>*/}
            {/*<Box my={3} />*/}
            <Grid container spacing={3}>
                {/*<Grid item sm={8} xs={12}>*/}
                {/*    <ContentBody padding={3}>*/}
                {/*        <Typography fontSize="1.25rem">{m.preferences.updateProfile}</Typography>*/}
                {/*        <Box my={2} />*/}
                {/*        <Grid container spacing={3}>*/}
                {/*            <Grid item sm={4} xs={12}>*/}
                {/*                <TextField fullWidth*/}
                {/*                           InputLabelProps={{ shrink: true }}*/}
                {/*                           label={m.users.position} placeholder="eg. CTO"*/}
                {/*                           value={profileForm.position ?? ''} onChange={v => setProfileForm({ ...profileForm, position: v.target.value })}*/}
                {/*                />*/}
                {/*            </Grid>*/}
                {/*            <Grid item sm={4} xs={12}>*/}
                {/*                <TextField fullWidth*/}
                {/*                           InputLabelProps={{ shrink: true }}*/}
                {/*                           label={m.common.firstName} placeholder="eg. John"*/}
                {/*                           value={profileForm.firstName ?? ''} onChange={v => setProfileForm({ ...profileForm, firstName: v.target.value })}*/}
                {/*                />*/}
                {/*            </Grid>*/}
                {/*            <Grid item sm={4} xs={12}>*/}
                {/*                <TextField fullWidth*/}
                {/*                           InputLabelProps={{ shrink: true }}*/}
                {/*                           label={m.common.lastName} placeholder="eg. Smith"*/}
                {/*                           value={profileForm.lastName ?? ''} onChange={v => setProfileForm({ ...profileForm, lastName: v.target.value })}*/}
                {/*                />*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*        <Box my={3} />*/}
                {/*        <Box style={{ textAlign: 'right' }}>*/}
                {/*            <AppButton label={m.actions.confirm} color="primary" onClick={handleProfileUpdate} loading={inProgress} />*/}
                {/*        </Box>*/}
                {/*    </ContentBody>*/}
                {/*</Grid>*/}
                <Grid item sm={4} xs={12}>
                    <ContentBody padding={3}>
                        <Typography fontSize="1.25rem">{m.preferences.language}</Typography>
                        <Box my={2} />
                        <TextField fullWidth select
                                   InputLabelProps={{ shrink: true }}
                                   defaultValue={i18n.language} onChange={v => handleLanguageChange(v.target.value)}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="de">Deutsch</MenuItem>
                        </TextField>

                    </ContentBody>
                </Grid>
            </Grid>

            <Snackbar open={profileSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={4000} onClose={() => setProfileSuccess(false)}>
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                    {m.preferences.updateProfileSuccess}
                </Alert>
            </Snackbar>

            {/*<Snackbar open={passwordSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={4000} onClose={() => setPasswordSuccess(false)}>*/}
            {/*    <Alert variant="filled" severity="success" sx={{ width: '100%' }}>*/}
            {/*        {m.preferences.changePasswordSuccess}*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </Fragment>
    )
}