import {useNavigate} from "react-router";
import {useMessages} from "../../i18n";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {authStore, uiStore} from "../../store";
import React, {Fragment, useMemo, useState} from "react";
import {NoticeFilter} from "../../data/types";
import {noticePaging, PagingSettings} from "../../data/pagings";
import {ApiError} from "../../data/Api";
import {listNotices} from "../../data/notices";
import {
    Badge as MBadge,
    Box,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    TableCell,
    TableRow
} from "@mui/material";
import {AppBreadcrumbs, AppFab, ContentTitle, Crumb} from "../../component/ContentLayout";
import {SearchInput} from "../../component/SearchInput";

import {displayDate} from "../../utils";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {FilterIcon, PlusIcon} from "../../icons";
import {SelectInput} from "../../component/SelectInput";
import {useAsync} from "react-async-hook";
import {FacilitySelect} from "../../component/FacilitySelect";

export const NoticeList = () => {
    const navigate = useNavigate();
    const m = useMessages();

    const columns: ColumnType = {
        'facility': { name: m.facilities.singular },
        'title': { name: m.common.title },
        'createdAt': { name: m.common.createdAt },
        'publishedAt': { name: m.common.publishedAt },
    }
    if (!authStore.isRoot) {
        delete columns['facility'];
    }

    const [needle, setNeedle] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<NoticeFilter>(uiStore.getNoticeFilter() || {});
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(noticePaging.getSettings());
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {facilityUuid} = filter;
        return (!!facilityUuid && facilityUuid != 'ALL');
    }, [filter])

    const {result: page, execute: loadPage} = useAsync(() => {
        return listNotices(noticePaging, needle, filter);
    }, [needle, pageable]);

    async function handleNeedleChange(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        noticePaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
    }

    async function handlePagingChange(pageable: PagingSettings<any>) {
        noticePaging.updateSettings(pageable);
        setPageable(pageable);
    }

    async function handleFilterSubmit(f?: NoticeFilter) {
        let modified = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        noticePaging.updateSettings(adjusted);
        setPageable(adjusted);
        uiStore.setNoticeFilter(modified);
        setShowFilter(false);
        setFilter(modified);
        await loadPage();
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }



    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.notices.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.notices.plural} path="/notices" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                    >
                        <SearchInput onSubmit={handleNeedleChange} />
                        <IconButton onClick={() => setShowFilter(true)}>
                            <MBadge color="primary" variant="dot" invisible={!filterActive}>
                                <FilterIcon />
                            </MBadge>
                        </IconButton>
                    </Stack>

                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(n => (
                            <TableRow key={n.uuid} onClick={() => navigate('/notices/' + n.uuid)}>
                                {authStore.isRoot && <TableCell>{n.facility?.name ?? '-'}</TableCell>}
                                <TableCell>{n.title}</TableCell>
                                <TableCell>{displayDate(n.createdAt)}</TableCell>
                                <TableCell>{n.publishedAt ? displayDate(n.publishedAt) : '-'}</TableCell>
                            </TableRow>
                        ))}
                    </Fragment>
                )}
            />
            <AppFab onClick={() => navigate('/notices/create')} children={<PlusIcon />}/>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <SelectInput
                                label={m.common.status}
                                options={[
                                    { label: m.common.all, value: 'ALL' },
                                    { label: m.notices.onlyPublished, value: '1' },
                                    { label: m.notices.onlyUnpublished, value: '0' },
                                ]}
                                value={filter.published || 'ALL'}
                                onChange={published => setFilter({ ...filter, published })}
                            />
                        </Grid>
                        {authStore.isRoot && (
                            <Grid item sm={6} xs={12}>
                                <FacilitySelect
                                    value={filter.facilityUuid}
                                    onChange={f => setFilter({ ...filter, facilityUuid: f?.uuid })}
                                />
                            </Grid>
                        )}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}