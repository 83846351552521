import {enc, HmacSHA512} from "crypto-js";
import { DateTime } from "luxon";
import { i18n } from "./i18n";

export function luxonDate(date: any): DateTime {
    if(!date) {
        return DateTime.local();
    } else if(typeof date === "string") {
        return DateTime.fromISO(date);
    } else if(date instanceof DateTime) {
        return date;
    } else if(typeof date === "number") {
        return DateTime.fromMillis(date);
    }else if(date instanceof Date) {
        return DateTime.fromJSDate(date);
    }
    throw Error('Date was in a not supported type');
}

export function hashPassword(rawPassword: string) {
    return enc.Base64.stringify(HmacSHA512(rawPassword, rawPassword));
}

export function formatAmountDigit(value?: any) {
    if (!value) value = 0;
    if (typeof value === 'string') return value;
    if (i18n.language === 'de') {
        return value.toFixed(2)
            .replace('.', ',');
    }
    return value.toFixed(2);
}

export function formatAmount(value: number) {
    return value.toFixed(2);
}

export function getUrlParam(param: string, url?: string) {
    let href = url ? url : window.location.href;
    let reg = new RegExp( '[?&]' + param + '=([^&#]*)', 'i' );
    let string = reg.exec(href);
    return string ? string[1] : null;
}