import React from "react";
import {useMessages} from "../../i18n";
import {
    Box,
    Container,
    Grid,
    Stack,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {ContentBody, ContentTitle} from "../../component/ContentLayout";
import {Loading} from "../../component/Loading";
import {KpiItem} from "./KpiItem";
import {AccountIcon, DeviceIcon, GolfIcon, LoginIcon, LogoutIcon, ReceiptIcon, StoreIcon, TimerIcon} from "../../icons";
import {useAsync} from "react-async-hook";
import {getFacilitySpecificKpi} from "../../data/kpis";
import {formatAmountDigit} from "../../tools";
import {indigo, lime, teal} from "@mui/material/colors";
import {TransactionRow} from "../../component/TransactionRow";
import {listRecentTransactions} from "../../data/stats";
import {authStore} from "../../store";

export const AdminDashboard = () => {

    const {result: kpi, loading: kpiLoading} = useAsync(getFacilitySpecificKpi, []);
    const {result: transactions, loading: tLoading} = useAsync(listRecentTransactions, []);

    const m = useMessages();
    return (
        <Container>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.dashboard.title} />
                </Grid>
            </Grid>
            <Box my={4} />
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <ContentBody padding={2}>
                        <Typography sx={{ color: '#F44336' }} variant="h6"><strong>{authStore.facility?.name}</strong></Typography>
                        <Box my={1} />
                        {kpiLoading && <Loading type="circular" />}
                        {kpi && (
                            <Stack direction="column" spacing={1}>
                                <KpiItem
                                    label={m.accounts.plural}
                                    value={kpi.accountsCount}
                                    icon={AccountIcon}
                                />
                                <KpiItem
                                    label={m.devices.plural}
                                    value={kpi.devicesCount}
                                    icon={DeviceIcon}
                                />
                                <KpiItem
                                    label={m.kpis.accounts.facilityWalletsBalance}
                                    value={formatAmountDigit(kpi.totalFacilityWalletsBalance)}
                                    icon={StoreIcon}
                                    valuePrefix="€"
                                />
                            </Stack>
                        )}
                    </ContentBody>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <ContentBody padding={2}>
                        <Typography sx={{ color: '#00C853' }} variant="h6"><strong>{m.transactions.plural}</strong></Typography>
                        <Typography>{(m.common.months as any)[new Date().getMonth()]} {new Date().getFullYear()}</Typography>
                        <Box my={1} />
                        {kpi && (
                            <KpiItem
                                label={m.common.total}
                                value={kpi.transactionsCount}
                            />
                        )}
                    </ContentBody>
                    <Box my={2} />
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <ContentBody padding={2}>
                                <Stack direction="column" alignItems="center">
                                    <Tooltip title={m.transactions.purposes.Bvm} placement="top">
                                        <GolfIcon sx={{ color: indigo[700], fontSize: '3rem' }} />
                                    </Tooltip>
                                    {kpiLoading && <Loading type="circular" />}
                                    {kpi && <Typography align="center" variant="h4">{kpi.bvmTransactionsCount}</Typography>}
                                </Stack>
                            </ContentBody>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <ContentBody padding={2}>
                                <Stack direction="column" alignItems="center">
                                    <Tooltip title={m.transactions.purposes.Rangefee} placement="top">
                                        <TimerIcon sx={{ color: lime[700], fontSize: '3rem' }} />
                                    </Tooltip>
                                    {kpiLoading && <Loading type="circular" />}
                                    {kpi && <Typography align="center" variant="h4">{kpi.rangefeeTransactionsCount}</Typography>}
                                </Stack>
                            </ContentBody></Grid>
                        <Grid item sm={4} xs={12}>
                            <ContentBody padding={2}>
                                <Stack direction="column" alignItems="center">
                                    <Tooltip title={m.transactions.purposes.InAppPayment} placement="top">
                                        <ReceiptIcon sx={{ color: teal[600], fontSize: '3rem' }} />
                                    </Tooltip>
                                    {kpiLoading && <Loading type="circular" />}
                                    {kpi && <Typography align="center" variant="h4">{kpi.inAppPaymentTransactionsCount}</Typography>}
                                </Stack>
                            </ContentBody></Grid>
                    </Grid>
                    <Box my={2} />
                    <ContentBody padding={2}>
                        {kpi && (
                            <Stack direction="column" spacing={2}>
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookIn} (${m.common.club})`}
                                    value={formatAmountDigit(kpi.totalFacilityBookInAmount)}
                                    icon={LoginIcon}
                                    valuePrefix="€"
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookOut} (${m.common.club})`}
                                    value={formatAmountDigit(kpi.totalFacilityBookOutAmount)}
                                    icon={LogoutIcon}
                                    valuePrefix="€"
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookOut} (${m.common.international})`}
                                    value={formatAmountDigit(kpi.totalInternationBookOutAmount)}
                                    icon={LogoutIcon}
                                    valuePrefix="€"
                                />
                            </Stack>
                        )}
                    </ContentBody>
                </Grid>
            </Grid>
            <Box my={4} />
            <ContentBody>
                <Box p={2}>
                    <Typography variant="h6"><strong>{m.transactions.recent}</strong></Typography>
                </Box>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{m.common.timestamp}</TableCell>
                                <TableCell>{m.accounts.singular}</TableCell>
                                <TableCell>{m.wallets.singular}</TableCell>
                                <TableCell>{m.common.type}</TableCell>
                                <TableCell>{m.transactions.purposes.singular}</TableCell>
                                <TableCell>{m.devices.singular}</TableCell>
                                <TableCell align="right">{m.common.amount}</TableCell>
                            </TableRow>
                        </TableHead>
                        {transactions && (
                            <TableBody>
                                {transactions.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={8}>{m.emptyContent}</TableCell>
                                    </TableRow>
                                )}
                                {transactions.map((t, i) => <TransactionRow key={t.uuid} transaction={t} view="dashboard" />)}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </ContentBody>
        </Container>
    )
}