import React, {Fragment, useState} from "react";
import {ApiError} from "../../data/Api";
import {useMessages} from "../../i18n";

import {ErrorPrompt} from "../../component/ErrorPrompt";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {AccountCreateRequest} from "../../data/types";
import {createAccount} from "../../data/accounts";
import {AccountForm} from "./AccountForm";
import {useNavigate} from "react-router";
import {Box, Button} from "@mui/material";

export const AccountCreate = () => {
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState<ApiError>();

    const create = async (form: AccountCreateRequest) => {
        setInProgress(true);
        try {
            const account = await createAccount(form);
            navigate('/accounts/' + account.uuid);
            return;
        } catch (error) {
            setError(error as any);
        }
        setInProgress(false);
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.accounts.create.title}/>
            <AppBreadcrumbs>
                <Crumb title={m.accounts.plural} path="/accounts" />
                <Crumb title={m.actions.create} />
            </AppBreadcrumbs>
            <Box my={3} />
            <ContentBody padding={2}>
                <AccountForm inProgress={inProgress}
                          onCreate={create}
                          onCancel={() => navigate('/accounts')}
                />
            </ContentBody>
            {error && (
                <ErrorPrompt error={error}
                             title={error.code === 409 ? m.accounts.create.existed.title : undefined}
                             message={error.code === 409 ? m.accounts.create.existed.message : undefined}
                >
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}