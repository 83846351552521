import {
    Box, Checkbox,
    Chip,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import React, {useEffect, useState} from "react";

export type SelectOption = {
    label: string,
    value: any
}

type Props = {
    label: string,
    options: SelectOption[],
    value?: string[],
    onChange?: (value: string[]) => void,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,
    size?: 'small'|'medium',
}

export function MultiSelectInput({label, options, value: pValue, onChange, error, helperText, disabled, size}: Props) {
    const [value, setValue] = useState<string[]>([]);

    useEffect(() => {
        if (pValue) {
            const findValue = options.filter(o => pValue.indexOf(o.value) > -1).map(o => o.value);
            setValue(findValue);
        }
    }, [])

    function handleChange({target: {value: v}}: SelectChangeEvent<typeof value>) {
        const changeValue = typeof v === 'string' ? v.split(',') : v;
        setValue(changeValue);
        if (onChange) onChange(changeValue);
    }

    return (
        <FormControl fullWidth size={size} disabled={disabled}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.map(s => options.find(o => o.value === s)?.label ?? s).join(', ')}
            >
                {options.map((o, i) => (
                    <MenuItem
                        key={'option-' + i}
                        value={o.value}
                    >
                        <Checkbox checked={value.indexOf(o.value) > -1} />
                        <ListItemText primary={o.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
