import React, {useState} from "react";
import {Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {useMessages} from "../i18n";
import {theme} from "../theme";
import {formatAmountDigit} from "../tools";
import {TaxedAmount} from "../data/types";

type TaxedAmountEditorProps = {
    taxedAmount?: TaxedAmount;
    compact?: boolean;
    fromGross?: boolean;
    onChange: (amount: TaxedAmount) => void;
}

type TaxedAmountEditorError = {
    gross?: boolean,
    net?: boolean,
    tax?: boolean,
    taxRate?: boolean
}

export const TaxedAmountEditor = ({taxedAmount, compact, fromGross, onChange}: TaxedAmountEditorProps) => {
    const [amount, setAmount] = useState<TaxedAmount>(taxedAmount || {});
    const [errors, setErrors] = useState<TaxedAmountEditorError>({});

    const parseNumber = (value: string) => {
        let dots = 0, commas = 0;
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === '.') dots += 1;
            if (value.charAt(i) === ',') commas += 1;
        }
        if (dots > 1 || commas > 1 || (dots > 0 && commas > 0)) {
            throw new Error("Invalid number");
        }
        value = value.replace(',', '.');
        return parseFloat(parseFloat(value).toFixed(2));
    }

    const triggerRecomputing = (amount: TaxedAmount, changedValue: string) => {
        const {net, gross, tax, taxRate} = errors;
        if (!net && !gross && !tax && !taxRate) {
            const taxRate = parseNumber(String(amount.taxRate || 0));
            const net = parseNumber(String(amount.net || 0));
            const gross = parseNumber(String(amount.gross || 0));
            switch (changedValue) {
                case 'net':
                case 'taxRate':
                    amount.gross = ((taxRate + 100) / 100) * net;
                    break;
                case 'gross':
                    amount.net = gross / ((taxRate + 100) / 100);
                    break;
            }
            if (amount.gross && amount.net && amount.gross >= amount.net) {
                amount.taxAmount = amount.gross - amount.net;
            }
            setAmount(amount);
        }
    }

    const onInputChange = (event: any) => {
        let {name, value} = event.target;
        value = value ?? '';
        value = value.replace(/[^0-9.,]/, '');
        const newAmount = {
            ...amount,
            [name]: value
        };
        setAmount(newAmount);
        let parsedValue = value || undefined;
        if (parsedValue) {
            try {
                parsedValue = parseNumber(value);
                setErrors({
                    ...errors,
                    [name]: false
                });
                triggerRecomputing(newAmount, name);
            } catch (error) {
                setErrors({
                    ...errors,
                    [name]: true
                });
            }
        }
        onChange({
            ...newAmount,
            [name]: parsedValue
        });
    }

    const compactLabelSx: any = {
        fontSize: '0.65rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.secondary,
        lineHeight: '1.65em',
        textTransform: 'uppercase'
    };
    const amountTypeLabelSx = {
        display: 'inline-block',
        width: 80,
        fontWeight: theme.typography.fontWeightRegular,
    };
    const m = useMessages();
    return (
        <Grid container spacing={3}>
            <Grid item sm={3} xs={12}>
                <TextField fullWidth label={m.taxedAmount.taxRate}
                           name="taxRate" value={formatAmountDigit(amount.taxRate)}
                           variant="outlined"
                           onChange={onInputChange}
                           InputLabelProps={{ shrink: true }}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">%</InputAdornment>,
                           }}
                           error={errors.taxRate}
                />
            </Grid>
            {!fromGross && (
                <Grid item sm={3} xs={12}>
                    <TextField fullWidth label={m.taxedAmount.net}
                               name="net" value={formatAmountDigit(amount.net)}
                               variant="outlined"
                               onChange={onInputChange}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">€</InputAdornment>,
                               }}
                               error={errors.net}
                    />
                </Grid>
            )}
            {(fromGross || !compact) && (
                <Grid item sm={3} xs={12}>
                    <TextField fullWidth label={m.taxedAmount.gross}
                               name="gross" value={formatAmountDigit(amount.gross)}
                               variant="outlined"
                               onChange={onInputChange}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">€</InputAdornment>,
                               }}
                               error={errors.gross}
                    />
                </Grid>
            )}
            {!compact && (
                <Grid item sm={3} xs={12}>
                    <TextField fullWidth label={m.taxedAmount.tax}
                               name="tax" value={formatAmountDigit(amount.taxAmount)}
                               variant="outlined"
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">€</InputAdornment>,
                               }}
                    />
                </Grid>
            )}
            {compact && (
                <Grid item sm={6} xs={12}>
                    <Typography sx={compactLabelSx}>
                        <span style={amountTypeLabelSx}>{m.taxedAmount.net}</span> € {formatAmountDigit(parseFloat(amount.net as any || 0))}
                    </Typography>
                    <Typography sx={compactLabelSx}>
                        <span style={amountTypeLabelSx}>{m.taxedAmount.gross}</span> € {formatAmountDigit(parseFloat(amount.gross as any || 0))}
                    </Typography>
                    <Typography sx={compactLabelSx}>
                        <span style={amountTypeLabelSx}>{m.taxedAmount.tax}</span> € {formatAmountDigit(parseFloat(amount.taxAmount as any || 0))}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}