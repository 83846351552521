import {ApiError} from "./Api";
import {DateTime} from "luxon";

export interface Page<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    number: number;
    numberOfElements: number;
    size: number;
    last: boolean;
    first: boolean;
    hasContent: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
}

export interface Pageable<K> {
    pageNumber: number;
    pageSize: number;
    sort?: K;
    direction?: 'asc' | 'desc';
}

export interface EditFormProps<T> {
    data: T,
    onSave: (data: T) => void,
    onError: (error?: ApiError) => void,
    onCancel: () => void
}

export interface Address {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    zipCode?: string;
    country?: string;
}

export interface TaxedAmount {
    net?: number,
    gross?: number,
    taxRate?: number,
    taxAmount?: number
}

export interface Timeframe {
    from?: DateTime;
    until?: DateTime;
}

export type LoginRequest = {
    email: string,
    password: string
}

export type AuthSession = {
    uuid: string,
    user: User,
    token: string,
    expiredAt: DateTime
}

export interface Facility {
    uuid: string;
    name: string;
    description?: string;
    address?: Address;
    contactPerson?: string;
    contactEmail?: string;
    contactPhone?: string;
    supportEmail?: string;
    supportPhone?: string;
    allowedIntlWallet: boolean;
    rangefee: TaxedAmount;
    lta: FacilityLTA;
    type: FacilityType;
    enabled: boolean;
    offlineSessionDuration?: number;
    maxScanDuration?: number;
    logoBase64Data?: string;
    pinpointServiceFee?: number;
    transactionBookmarks: FacilityTransactionBookmark[];
}

export enum FacilityType {
    TestClub = 'TestClub',
    GolfClub = 'GolfClub',
    GolfCentral = 'GolfCentral',
    GolfResort = 'GolfResort',
    GolfClubCleaning = 'GolfClubCleaning',
    SailingClub = 'SailingClub',
    BilliardClub = 'BilliardClub',
    TennisClub = 'TennisClub',
    ParkingHouse = 'ParkingHouse',
    ParkingZone = 'ParkingZone',
}

export interface FacilityLTA {
    land?: number;
    type?: number;
    clubNr?: number;
}

export interface FacilityTransactionBookmark {
    direction: AccountTransactionDirection.BookIn | AccountTransactionDirection.BookOut;
    type?: AccountActivityType;
    amount?: number;
    allowedCustomAmount: boolean;
    message?: string;
    allowedCustomMessage: boolean;
}

export interface FacilityFilter {
    type?: FacilityType;
    country?: string;
    status?: string;
}

export interface DeviceFilter {
    type?: DeviceType;
    facilityUuid?: string;
}

export interface NoticeFilter {
    facilityUuid?: string;
    published?: string;
}

export interface FacilityCreateRequest {
    name: string;
    address: Address;
    lta: FacilityLTA;
    description?: string;
    contactPerson?: string;
    contactEmail?: string;
    contactPhone?: string;
    supportEmail?: string;
    supportPhone?: string;
    allowedIntlWallet: boolean;
    rangefee: TaxedAmount;
    type?: FacilityType;
    offlineSessionDuration?: number;
    pinpointServiceFee?: number;
    maxScanDuration?: number;
}

export interface FacilityUpdateRequest extends FacilityCreateRequest {
}

export enum UserRole {
    Root = 'Root',
    Admin = 'Admin',
    User = 'User'
}

export interface User {
    uuid: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
    role: UserRole;
    position?: string;
    facility?: Facility;
}

export interface UserFilter {
    role?: UserRole;
    facilityUuid?: string;
}

export interface UserCreateRequest {
    title?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
    role: UserRole;
    position?: string;
    facilityUuid?: string;
}

export interface UserUpdateRequest extends Omit<UserCreateRequest, 'facilityUuid'> {
}

export enum DeviceType {
    Entrance = 'Entrance',
    BallVendingMachine = 'BallVendingMachine'
}

export interface Device {
    id: string;
    name?: string;
    description?: string;
    type: DeviceType;
    modelNumber: string;
    serialNumber: string;

    port0: DevicePortConfig;
    port1: DevicePortConfig;
    port2: DevicePortConfig;
    port3: DevicePortConfig;

    facility: Facility;
    rangefeeRequired: boolean;
    offlineServiceEnabled: boolean;
    automaticallyOpenedOnConnection: boolean;
}

export interface DevicePortConfig {
    name?: string;
    active: boolean;
    switchingTimeDuration?: number;
    switchingTimeUnit: DeviceSwitchingTimeUnit;
    switchDelayDuration?: number;
    switchDelayUnit: DeviceSwitchingTimeUnit;
    amount: TaxedAmount;
    bulkPurchasingEnabled: boolean;
    bulkPurchasingQuantity?: number;
}

export interface DeviceUpdateRequest {
    name?: string;
    description?: string;
    type: DeviceType;
    rangefeeRequired: boolean;
    offlineServiceEnabled: boolean;
    automaticallyOpenedOnConnection: boolean;
}

export enum DeviceSwitchingTimeUnit {
    Millisecond = 'Millisecond',
    Second = 'Second',
    Minute = 'Minute',
    Hour = 'Hour',
}

export interface DevicePortRequest {
    name?: string;
    active: boolean;
    port: number;
    switchingTimeDuration?: number;
    switchingTimeUnit: DeviceSwitchingTimeUnit;
    switchDelayDuration?: number;
    switchDelayUnit: DeviceSwitchingTimeUnit;
    amount: TaxedAmount;
    bulkPurchasingEnabled: boolean;
    bulkPurchasingQuantity?: number;
}

export interface Account {
    uuid: string;
    firstName?: string;
    lastName?: string;
    address?: Address;
    email: string;
    emailConfirmedAt?: DateTime;
    phone?: string;
    phoneConfirmedAt?: DateTime;
    createdAt: DateTime;
    enabled: boolean;
}

export interface AccountFacilityOverview {
    account: Account;
    accountGroup?: AccountGroup;
    memberNumber?: string;
    associationNumber?: string;
    enabled: boolean;
    rangefeeEnabled: boolean;
    createdAt: DateTime;
    wallets: Wallet[];
    facilityUuid: string;
    facilityName: string;
}

export interface DevicePermission {
    device: Device;
    enabled: boolean;
}

export interface AccountGroup {
    uuid: string;
    name: string;
    facility: Facility;
    expirationDate?: string;
    startHour?: string;
    endHour?: string;
    defaultOnSignup: boolean;
    devicePermissions: DevicePermission[];
}

export interface AccountGroupRequest {
    name: string;
    expirationDate?: string;
    startHour?: string;
    endHour?: string;
    facilityUuid?: string;
    defaultOnSignup: boolean;
}

export interface AccountPermission {
    id: number;
    account: Account;
    device: Device;
    enabled: boolean;
}

export interface AccountDiscount {
    id: number,
    account: Account,
    device: Device,
    type: AccountDiscountType,
    port0Amount: number,
    port1Amount: number,
    port2Amount: number,
    port3Amount: number
}

export enum AccountTransactionType {
    PurchaseCredit = 'PurchaseCredit',
    ReceiveCredit = 'ReceiveCredit',
    SubtractCredit = 'SubtractCredit',
    ConsumeCredit = 'ConsumeCredit'
}

export enum AccountTransactionPurpose {
    Rangefee = 'Rangefee',
    InAppPayment = 'InAppPayment',
    Bvm = 'Bvm',
}

export interface AccountTransaction {
    uuid: string;
    transactionId?: string;
    wallet: Wallet;
    user?: User;
    stripeId?: string;
    timestamp: DateTime;
    type: AccountTransactionType;
    transactionPurpose?: AccountTransactionPurpose;
    device?: Device;
    transactionAmount: number;
    transactionPortName?: string;
    transactionValue?: number;
    note?: string;
    ballQuantity?: number;
    clearedAt?: DateTime;
    previousAmount: number;
    newAmount: number;
    billingFirstName?: string;
    billingLastName?: string;
    billingAddress?: Address;
    facility?: Facility;
}

export interface AccountFacility {
    id: number;
    account: Account;
    facility: Facility;
    memberNumber: string;
    associationNumber: string;
    enabled: boolean;
    rangefeeEnabled: boolean;
    createdAt: DateTime;
    group?: AccountGroup;
}

export interface AccountCreateRequest {
    firstName?: string;
    lastName?: string;
    address: Address;
    email: string;
    phone?: string;
    facilityUuid: string;
}

export interface AccountUpdateRequest extends Omit<AccountCreateRequest, 'facilityUuid'> {
}

export interface AccountRangefeeRequest {
    accountFacilityId: number;
    from: string;
    until: string;
}

export interface AccountPermissionRequest {
    deviceId?: string;
    enabled?: boolean;
    permissionGroupUuid?: string;
}

export enum AccountDiscountType {
    Percentage = 'Percentage',
    FixedAmount = 'FixedAmount',
}

export interface AccountDiscountRequest {
    deviceId: string,
    type: AccountDiscountType;
    port0Amount: number,
    port1Amount: number,
    port2Amount: number,
    port3Amount: number
}

export interface AccountFilter {
    enabled?: string;
    facilityUuid?: string;
}

export interface AccountGroupFilter {
    facilityUuid?: string;
}

export enum WalletType {
    International = 'International',
    Facility = 'Facility'
}

export enum AccountTransactionDirection {
    BookIn = 'BookIn',
    BookOut = 'BookOut'
}

export interface TransactionFilter {
    direction?: AccountTransactionDirection;
    timeframe?: Timeframe;
}

export interface Wallet {
    uuid: string;
    account: Account;
    facility?: Facility;
    type: WalletType;
    amount: number;
    createdAt: DateTime;
}

export interface WalletAmountModificationRequest {
    amount: number;
    note: string;
    direction: Omit<AccountTransactionDirection, 'All'>;
}

export interface WalletFilter {
    facilityUuid?: string;
    active?: string;
}

export type WalletTotalBalance = {
    totalBalance: number,
}

export type FacilityTransactionsStats = {
    totalBookOutClubTransaction: number,
    totalBookOutIntlTransaction: number,
    totalBookInClubTransaction: number,
    totalBookInIntlTransaction: number,
    totalBallQuantity: number,
    totalPayout: number,
    transactions: AccountTransaction[],
    timeframe: Timeframe
}

export type FacilityActivitiesStats = {
    activities: AccountActivity[],
    timeframe: Timeframe
}

export type TransactionProps = {
    totalBookOut: number,
    totalBookOutClub: number,
    totalBookOutIntl: number,
    totalBookIn: number,
    totalBookInClub: number,
    totalBookInIntl: number,
    totalBallQuantity: number,
    serviceFee: number,
    payout: number
}

export enum AccountActivityType {
    SignedUp = 'SignedUp',
    Login = 'Login',
    Logout = 'Logout',
    FacilityRegistration = 'FacilityRegistration',
    OpenEntrance = 'OpenEntrance',
    BvmTransaction = 'BvmTransaction',
    RangefeeTransaction = 'RangefeeTransaction',
    InAppPaymentTransaction = 'InAppPaymentTransaction',
}

export type AccountActivity = {
    id: number,
    account: Account,
    facility?: Facility,
    timestamp: DateTime,
    type: AccountActivityType,
    deviceId: string,
    deviceName: string,
}

export enum ActivityFilter {
    Entrance = 'Entrance',
    BallVendingMachine = 'BallVendingMachine',
    Rangefee = 'Rangefee',
    InAppPayment = 'InAppPayment',
    System = 'System',
}

export type Notice = {
    uuid: string,
    createdAt: DateTime,
    publishedAt?: DateTime,
    title: string,
    content: string,
    facility?: Facility
}

export type NoticeRequest = {
    title: string,
    content: string,
    facilityUuid?: string,
}

export type PaymentFilter = {
    facilityUuid?: string;
    from?: DateTime;
    until?: DateTime;
    purposes?: AccountTransactionPurpose[]
}

export interface FacilityKpi {
    facilitiesCount: number;
    facilityRegistrationsCount: number;
}

export interface AccountKpi {
    accountsCount: number;
    totalFacilityWalletsBalance: number;
    totalInternationalWalletsBalance: number;
}

export interface TransactionKpi {
    transactionsCount: number;
    totalFacilityBookInAmount: number;
    totalInternationBookInAmount: number;
    totalFacilityBookOutAmount: number;
    totalInternationBookOutAmount: number;
}

export interface FacilitySpecificKpi {
    accountsCount: number;
    devicesCount: number;
    totalFacilityWalletsBalance: number;

    transactionsCount: number;
    bvmTransactionsCount: number;
    rangefeeTransactionsCount: number;
    inAppPaymentTransactionsCount: number;

    totalFacilityBookInAmount: number;
    totalFacilityBookOutAmount: number;

    totalInternationBookOutAmount: number;
}
