import React, {Fragment, useState} from "react"
import {useParams} from "react-router";
import {Alert, AlertTitle, Box, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {Logo} from "../../graphic";
import {useMessages} from "../../i18n";
import {confirmResetPassword} from "../../data/auth";
import {hashPassword} from "../../tools";
import {theme} from "../../theme";
import {ActionButton, LinkButton} from "../../component/buttons";
import {TextInput} from "../../component/TextInput";

export function UserAcceptance() {
    const {token} = useParams<{ token: string }>();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    async function processAcceptance() {
        setError(false);
        try {
            setInProgress(true);
            if (!!token && !!password && !!confirmPassword && password === confirmPassword) {
                const result = await confirmResetPassword(token, hashPassword(password));
                if (!result) setError(true);
                else setSuccess(true);
            }
        } catch (err) {
            console.error('failed to confirm reset password', err);
            setError(true);
        } finally {
            setInProgress(false);
        }
    }

    const m = useMessages();
    return (
        <Dialog open={true} fullWidth maxWidth="xs">
            <DialogTitle component="div" style={{ background: '#283035', textAlign: 'center', paddingTop: theme.spacing(3) }}>
                <img src={Logo} alt="PINPOINT Center" width="150" style={{ display: 'inline-block' }} />
            </DialogTitle>
            <DialogContent>
                <Box my={3} />
                <Typography variant="h5" align="center">{m.users.invitation.title}</Typography>
                <Box my={4} />
                {!success && (
                    <Fragment>
                        <Typography textAlign="center">{m.users.invitation.message}</Typography>
                        <Box my={2} />
                        <TextInput
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off" type="password"
                            label={m.resetPassword.newPassword} placeholder="•••••"
                            value={password} onChange={setPassword}
                        />
                        <Box my={2} />
                        <TextField
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off" type="password"
                            label={m.resetPassword.confirmNewPassword} placeholder="•••••"
                            value={confirmPassword} onChange={ev => setConfirmPassword(ev.target.value)}
                            error={!!confirmPassword && confirmPassword !== password}
                            helperText={!!confirmPassword && confirmPassword !== password ? m.resetPassword.newPasswordNotMatchError : undefined}
                        />
                        <Box my={2} />
                        {error && (
                            <Alert severity="error" variant="filled">
                                <AlertTitle>{m.error.generic.title}</AlertTitle>
                                <Typography>{m.resetPassword.newPasswordError}</Typography>
                            </Alert>
                        )}
                        <Box my={2} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <ActionButton
                                color="primary"
                                loading={inProgress}
                                onClick={processAcceptance}
                            >
                                {m.resetPassword.btn}
                            </ActionButton>
                        </Box>
                    </Fragment>
                )}
                {success && (
                    <Fragment>
                        <Alert severity="success" variant="filled">
                            <AlertTitle>Success</AlertTitle>
                            <Typography>{m.users.invitation.success}</Typography>
                        </Alert>
                        <Box my={2} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <LinkButton
                                to={'/login'}
                            >
                                {m.login.btn}
                            </LinkButton>
                        </Box>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    )
}