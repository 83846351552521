import {useNavigate} from "react-router";
import React, {Fragment, useState} from "react";
import {ApiError} from "../../data/Api";
import {NoticeRequest} from "../../data/types";
import {createNotice} from "../../data/notices";
import {useMessages} from "../../i18n";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {Box, Button} from "@mui/material";

import {NoticeForm} from "./NoticeForm";
import {ErrorPrompt} from "../../component/ErrorPrompt";

export const NoticeCreate = () => {
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState<ApiError>();

    async function create(form: NoticeRequest) {
        setInProgress(true);
        try {
            const notice = await createNotice(form);
            navigate('/notices/' + notice.uuid);
            return;
        } catch (error) {
            setError(error as any);
        }
        setInProgress(false);
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.notices.create.title} />
            <AppBreadcrumbs>
                <Crumb title={m.notices.plural} path="/notices" />
                <Crumb title={m.actions.create} />
            </AppBreadcrumbs>
            <Box my={3} />
            <ContentBody padding={2}>
                <NoticeForm
                    inProgress={inProgress}
                    onCreate={create}
                    onCancel={() => navigate('/notices')}
                />
            </ContentBody>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}