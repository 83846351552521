import {SvgIconComponent} from "@mui/icons-material";
import React, {Fragment} from "react";
import {Box, Typography, useTheme} from "@mui/material";

interface Props {
    label: string;
    value: string|number;
    valuePrefix?: string;
    icon?: SvgIconComponent;
}

export function KpiItem({label, value, valuePrefix, icon: Icon}: Props) {
    const theme = useTheme();

    return (
        <Box>
            <Typography
                variant="overline"
                sx={{
                    mt: '0 !important',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: theme.palette.text.secondary
                }}
            >
                {Icon && (
                    <Icon
                        sx={{
                            mr: 1,
                            fontSize: '1.2rem'
                        }}
                    />
                )}
                {label}
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    mt: '0 !important',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                }}
            >
                {valuePrefix && (
                    <Box
                        component="span"
                        sx={{
                            fontSize: '1.3rem',
                            lineHeight: '1em',
                            mr: 1,
                            position: 'relative',
                            top: '8px'
                        }}
                    >
                        {valuePrefix}
                    </Box>
                )}
                {value}
            </Typography>
        </Box>
    )
}