import React, {useEffect} from "react";
import {useParams} from "react-router";
import {Box, Paper, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import {confirmProfileChange} from "../../data/public";
import {CheckIcon} from "../../icons";

import Logo from "../../assets/logo_icon.svg";
import {theme} from "../../theme";
import {useMessages} from "../../i18n";

export const AccountProfileConfirmation = () => {
    const {type, token} = useParams<{ type: string, token: string }>();

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.background = '#283035';

        switch (type) {
            case 'e':
                confirmProfileChange(token).then();
                break;
            case 'p':
                confirmProfileChange(undefined, token).then();
                break;
        }
    }, []);

    const m = useMessages();
    return (
        <Box my={3} width="100%" maxWidth={600} mx="auto">
            <Box component="div" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img style={{
                    width: 50,
                    position: 'relative',
                    marginRight: theme.spacing(2)
                }} src={Logo} alt="PINPOINT Smart App"/>
                <Typography align="center" fontSize="1.5rem" color='#fff' textAlign="left">
                    <strong>PINPOINT</strong>
                    <br/>
                    Smart App
                </Typography>
            </Box>
            <Box my={3} />
            <Paper>
                <Box p={2}>
                    <Typography align="center" fontSize="3rem" style={{ color: green[500] }}><CheckIcon fontSize="inherit" /></Typography>
                    <Typography align="center">{type === 'e' ? m.accounts.confirmation.email : m.accounts.confirmation.phone}</Typography>
                    <Typography align="center">{m.accounts.confirmation.close}</Typography>
                </Box>
            </Paper>
        </Box>
    )
}