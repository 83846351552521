import React, {Fragment} from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {useMessages} from "../../i18n";
import {GlobeIcon, StoreIcon} from "../../icons";
import {LinkButton} from "../../component/buttons";

export const WalletTypeSelector = () => {
    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <GlobeIcon style={{ fontSize: '3rem' }} />
                        <Typography variant="h5">{m.wallets.types.International}</Typography>
                        <Box my={2} />
                        <LinkButton to="/wallets/international">
                            {m.actions.open}
                        </LinkButton>
                    </Paper>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <StoreIcon style={{ fontSize: '3rem' }} />
                        <Typography variant="h5">{m.wallets.types.Facility}</Typography>
                        <Box my={2} />
                        <LinkButton to="/wallets/facility">
                            {m.actions.open}
                        </LinkButton>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    )
}