import React, {Fragment, useState} from "react";
import {
    Alert,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Link, Snackbar,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {
    Account,
    AccountDiscount,
    AccountDiscountRequest,
    AccountDiscountType,
    AccountFacility,
    AccountPermission,
    AccountUpdateRequest,
    Device,
    DeviceType,
    EditFormProps, Timeframe,
    Wallet
} from "../../data/types";
import {
    addAccountRangefee,
    deleteAccount,
    deleteAccountFacility,
    getAccount,
    getAccountFacility,
    listAccountDiscounts,
    listAccountFacilities,
    listAccountPermissions,
    listAccountWallets,
    updateAccount,
    updateAccountDiscounts,
    updateAccountFacilityData,
    updateAccountFacilityStatus,
    updateAccountPermissions
} from "../../data/accounts";
import {listDevices} from "../../data/devices";

import {useMessages} from "../../i18n";
import {authStore} from "../../store";
import {EditIcon} from "../../icons";
import {
    AppBreadcrumbs,
    ContentBody,
    ContentTitle,
    Crumb,
    DeleteButton,
    DeletePrompt
} from "../../component/ContentLayout";
import {Badge} from "../../component/Badge";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {LabeledText} from "../../component/LabeledText";
import {LabeledBoolean} from "../../component/LabeledBoolean";
import {AddressDisplay} from "../../component/AddressDisplay";
import {AccountForm} from "./AccountForm";
import {useNavigate, useParams} from "react-router";
import {formatAmount, formatAmountDigit} from "../../tools";
import {SwitchInput} from "../../component/SwitchInput";
import {DeviceTypeBadge} from "../../component/DeviceTypeBadge";
import {TextInput} from "../../component/TextInput";
import useAsyncEffect from "use-async-effect";
import {AccountAvatar} from "./AccountAvatar";
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import {DateSelector} from "../../component/DateSelector";
import {DateTime} from "luxon";

export const AccountDetails = () => {
    const navigate = useNavigate();
    const {uuid = ''} = useParams();
    const [account, setAccount] = useState<Account>();
    const [accountFacilities, setAccountFacilities] = useState<AccountFacility[]>();
    const [permissions, setPermissions] = useState<AccountPermission[]>();
    const [discounts, setDiscounts] = useState<AccountDiscount[]>();
    const [wallets, setWallets] = useState<Wallet[]>();
    const [devices, setDevices] = useState<Device[]>([]);
    const [discountForm, setDiscountForm] = useState<AccountDiscountRequest>();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState<ApiError>();

    useAsyncEffect(async () => {
        try {
            const account = await getAccount(uuid);
            setAccount(account);
        } catch (err) {
            console.error('failed to get account', err);
            setError(err as any);
        }

        try {
            const wallets = await listAccountWallets(uuid);
            setWallets(wallets);
        } catch (err) {
            console.error('failed to list account wallets', err);
            setError(err as any);
        }

        try {
            const permissions = await listAccountPermissions(uuid);
            setPermissions(permissions);
        } catch (err) {
            console.error('failed to list account permissions', err);
            setError(err as any);
        }

        try {
            const discounts = await listAccountDiscounts(uuid);
            setDiscounts(discounts);
        } catch (err) {
            console.error('failed to list account discounts', err);
            setError(err as any);
        }

        if (authStore.isRoot) {
            let facilities;
            try {
                facilities = await listAccountFacilities(uuid);
                setAccountFacilities(facilities);
            } catch (err) {
                console.error('failed to list account facilities', err);
                setError(err as any);
            }

            if (facilities) {
                const devices: Device[] = [];
                // const permissionGroups: FacilityPermissionGroup[] = [];
                for (let af of facilities) {
                    try {
                        const list = await listDevices(undefined, undefined, { facilityUuid: af.facility.uuid });
                        devices.push(...list.content);
                    } catch (err) {
                        console.error(`failed to list devices from facility ${af.facility.uuid}`, err);
                        setError(err as any);
                    }

                    // try {
                    //     const list = await listFacilityPermissionGroups(af.facility.uuid);
                    //     permissionGroups.push(...list);
                    // } catch (err) {
                    //     console.error(`failed to list permission groups from facility ${af.facility.uuid}`, err);
                    //     setError(err as any);
                    // }
                }
                setDevices(devices);
                // setPermissionGroups(permissionGroups);
            }
        } else {
            try {
                const facility = await getAccountFacility(uuid);
                setAccountFacilities([facility]);
            } catch (err) {
                console.error('failed to get account facility', err);
                setError(err as any);
            }

            try {
                const devices = await listDevices();
                setDevices(devices.content);
            } catch (err) {
                console.error('failed to list devices', err);
                setError(err as any);
            }

            // try {
            //     const permissionGroups = await listCurrentFacilityPermissionGroups();
            //     setPermissionGroups(permissionGroups);
            // } catch (err) {
            //     console.error('failed to list permission groups', err);
            //     setError(err as any);
            // }
        }
    }, [uuid])

    const isCheck = (deviceId: string) => {
        if (permissions) {
            const permission = permissions.find(p => p.device.id === deviceId);
            if (permission) return permission.enabled;
        }
        return false;
    }

    const handleDeviceToggle = async (deviceId: string) => {
        if (account && permissions) {
            const permission = permissions.find(p => p.device.id === deviceId);
            let request;
            if (!permission) {
                request = { deviceId, enabled: true };
            } else {
                request = { deviceId, enabled: !permission.enabled };
            }
            try {
                const updated = await updateAccountPermissions(account.uuid, request);
                setPermissions(updated);
            } catch (error) {
                setError(error as any);
            }
        }
    }

    const handleOnSave = (a: Account) => {
        setEditMode(false);
        setAccount(a);
    }

    async function handleOnDeleteAccountFacility(id: number) {
        try {
            await deleteAccountFacility(id);
            if (accountFacilities?.length === 1) {
                navigate('/accounts');
            } else {
                window.location.reload();
            }
        } catch (err) {
            console.log('failed to delete account facility', err);
            setError(err as any);
        }
    }

    async function handleOnDeleteAccount() {
        try {
            await deleteAccount(uuid);
            navigate('/accounts');
        } catch (err) {
            console.error('failed to delete account', err);
            setError(err as any);
        }
    }

    const handleDiscountToggle = (deviceId: string) => {
        if (account && discounts) {
            const current = discounts.find(d => d.device.id === deviceId);
            setDiscountForm({
                deviceId,
                type: current?.type ?? AccountDiscountType.Percentage,
                port0Amount: current ? current.port0Amount : 0,
                port1Amount: current ? current.port1Amount : 0,
                port2Amount: current ? current.port2Amount : 0,
                port3Amount: current ? current.port3Amount : 0
            });
        }
    }

    const handleDiscountSubmit = async () => {
        if (account && !!discountForm) {
            try {
                const updated = await updateAccountDiscounts(account.uuid, discountForm);
                setDiscounts(updated);
                setDiscountForm(undefined);
            } catch (error) {
                setError(error as any);
            }
        }
    }

    const device = devices.find(d => d.id === discountForm?.deviceId);

    const m = useMessages();
    return (
        <Container>
            <ContentTitle title={m.accounts.singular} />
            <AppBreadcrumbs>
                <Crumb title={m.accounts.plural} path="/accounts" />
                <Crumb title={m.actions.view} />
            </AppBreadcrumbs>
            <Box my={3} />
            {account && (
                <Fragment>
                    {!editMode && (
                        <Fragment>
                            {accountFacilities && accountFacilities.map(af => (
                                <Fragment key={'facility-' + af.id}>
                                    <FacilityInfo accountFacility={af} />
                                    <Box my={3} />
                                </Fragment>
                            ))}
                            <ContentBody padding={2}>
                                <Details account={account} onEdit={() => setEditMode(true)} />
                            </ContentBody>
                            <Box my={3} />
                            <ContentBody>
                                <Box p={2}>
                                    <Typography variant="subtitle1">{m.wallets.plural}</Typography>
                                </Box>
                                <Divider />
                                <Table size="small" sx={{
                                    '& tbody > tr:last-child > td': {
                                        borderBottom: 'none'
                                    }
                                }}>
                                    <TableBody>
                                        {wallets && wallets.map(w => (
                                            <TableRow key={w.uuid}>
                                                {authStore.isRoot && <TableCell style={{ whiteSpace: 'nowrap' }}>{w.facility ? w.facility.name : 'PINPOINT'}</TableCell>}
                                                <TableCell style={{ width: '99%' }}>€ {formatAmountDigit(w.amount)}</TableCell>
                                                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
                                                    <Button size="small" onClick={() => navigate(`/wallets/${w.uuid}`)}>{m.actions.view}</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ContentBody>
                            <Box my={3} />
                            {accountFacilities && accountFacilities.map(af => (
                                <Fragment key={'facility-permission-' + af.id}>
                                    <ContentBody>
                                        <Box
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="subtitle1" style={{ flexGrow: 1 }}>{m.accounts.permissions}</Typography>
                                                {authStore.isRoot && <Typography><strong>{af.facility.name}</strong></Typography>}
                                                {af.group && (
                                                    <LabeledText label={m.accounts.groups}>
                                                        {af.group.name}
                                                    </LabeledText>
                                                )}
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Table size="small" sx={{
                                            '& tbody > tr:last-child > td': {
                                                borderBottom: 'none'
                                            }
                                        }}>
                                            <TableBody>
                                                {devices.filter(d =>  d.facility.uuid === af.facility.uuid).map(d => (
                                                    <DeviceRow
                                                        key={d.id}
                                                        device={d}
                                                        enabled={isCheck(d.id)}
                                                        onDiscountClick={() => handleDiscountToggle(d.id)}
                                                        onDeviceToggle={() => handleDeviceToggle(d.id)}
                                                        discount={discounts?.find(item => item.device.id === d.id)}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </ContentBody>
                                    <Box my={2} />
                                </Fragment>
                            ))}


                            <Dialog open={!!discountForm} fullWidth maxWidth="sm">
                                <DialogTitle>Skonto</DialogTitle>
                                <DialogContent>
                                    <Box my={1} />
                                    {discountForm && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={discountForm.type === AccountDiscountType.FixedAmount}
                                                            onChange={(ev, checked) => {
                                                                setDiscountForm({
                                                                    ...discountForm,
                                                                    type: checked ? AccountDiscountType.FixedAmount : AccountDiscountType.Percentage
                                                                })
                                                            }}
                                                        />}
                                                        label={m.accounts.discount.fixedAmount}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                {device && (
                                                    <Fragment>
                                                        <Typography variant="overline" sx={{ width: '100%' }}>{m.devices.basePrice}</Typography>
                                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>€ {formatAmount(device.port0?.amount?.gross ?? 0)}</Typography>
                                                    </Fragment>
                                                )}
                                                <TextField label="Port 0"
                                                           value={discountForm.port0Amount}
                                                           onChange={ev => setDiscountForm({ ...discountForm, port0Amount: ev.target.value as any })}
                                                           InputProps={{
                                                               endAdornment: <InputAdornment position="end">{discountForm.type === AccountDiscountType.Percentage ? '%' : '€'}</InputAdornment>
                                                           }}
                                                />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                {device && (
                                                    <Fragment>
                                                        <Typography variant="overline" sx={{ width: '100%' }}>{m.devices.basePrice}</Typography>
                                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>€ {formatAmount(device.port1?.amount?.gross ?? 0)}</Typography>
                                                    </Fragment>
                                                )}
                                                <TextField label="Port 1"
                                                           value={discountForm.port1Amount}
                                                           onChange={ev => setDiscountForm({ ...discountForm, port1Amount: ev.target.value as any })}
                                                           InputProps={{
                                                               endAdornment: <InputAdornment position="end">{discountForm.type === AccountDiscountType.Percentage ? '%' : '€'}</InputAdornment>
                                                           }}
                                                />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                {device && (
                                                    <Fragment>
                                                        <Typography variant="overline" sx={{ width: '100%' }}>{m.devices.basePrice}</Typography>
                                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>€ {formatAmount(device.port2?.amount?.gross ?? 0)}</Typography>
                                                    </Fragment>
                                                )}
                                                <TextField label="Port 2"
                                                           value={discountForm.port2Amount}
                                                           onChange={ev => setDiscountForm({ ...discountForm, port2Amount: ev.target.value as any })}
                                                           InputProps={{
                                                               endAdornment: <InputAdornment position="end">{discountForm.type === AccountDiscountType.Percentage ? '%' : '€'}</InputAdornment>
                                                           }}
                                                />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                {device && (
                                                    <Fragment>
                                                        <Typography variant="overline" sx={{ width: '100%' }}>{m.devices.basePrice}</Typography>
                                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>€ {formatAmount(device.port3?.amount?.gross ?? 0)}</Typography>
                                                    </Fragment>
                                                )}
                                                <TextField label="Port 3"
                                                           value={discountForm.port3Amount}
                                                           onChange={ev => setDiscountForm({ ...discountForm, port3Amount: ev.target.value as any })}
                                                           InputProps={{
                                                               endAdornment: <InputAdornment position="end">{discountForm.type === AccountDiscountType.Percentage ? '%' : '€'}</InputAdornment>
                                                           }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="contained" disableElevation
                                            onClick={handleDiscountSubmit}
                                    >
                                        {m.actions.save}
                                    </Button>
                                    <Button onClick={() => setDiscountForm(undefined)}>{m.actions.cancel}</Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    )}
                    {editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Edit data={account}
                                      onCancel={() => setEditMode(false)}
                                      onSave={handleOnSave}
                                      onError={setError}
                                />
                            </ContentBody>
                            <Box my={3} />
                            {(authStore.isAdmin && accountFacilities) && (
                                <DeletePrompt title={m.accounts.deleteFromFacility.title}
                                              message={m.accounts.deleteFromFacility.message}
                                              onDelete={() => handleOnDeleteAccountFacility(accountFacilities[0].id)}
                                />
                            )}
                            {authStore.isRoot && (
                                <DeletePrompt title={m.accounts.delete.title}
                                              message={m.accounts.delete.message}
                                              onDelete={handleOnDeleteAccount}
                                />
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
            {error && (
                <ErrorPrompt error={error}
                             title={error.code === 409 ? m.accounts.create.existed.title : undefined}
                             message={error.code === 409 ? m.accounts.create.existed.message : undefined}
                >
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Container>
    )
}

const FacilityInfo = ({accountFacility: pAf}: { accountFacility: AccountFacility }) => {
    const [editMode, setEditMode] = useState(false);
    const [rangefeeMode, setRangefeeMode] = useState(false);
    const [af, setAf] = useState(pAf);
    const [memberNumber, setMemberNumber] = useState(pAf.memberNumber);
    const [associationNumber, setAssociationNumber] = useState(pAf.associationNumber);
    const [timeframe, setTimeframe] = useState<Timeframe>({ from: DateTime.now(), until: DateTime.now().plus({ day: 1 }) });
    const [rangefeeAdded, setRangefeeAdded] = useState(false);

    const handleFacilityStatusToggle = async (af: AccountFacility) => {
        await processAccountFacilityStatus(af.account.uuid, af.id, { enabled: !af.enabled, rangefeeEnabled: af.rangefeeEnabled });
    }

    const handleFacilityRangefeeToggle = async (af: AccountFacility) => {
        await processAccountFacilityStatus(af.account.uuid, af.id, { enabled: af.enabled, rangefeeEnabled: !af.rangefeeEnabled });
    }

    const processAccountFacilityStatus = async (accountUuid: string, accountFacilityId: number, {enabled, rangefeeEnabled}: { enabled: boolean, rangefeeEnabled: boolean }) => {
        try {
            const updated = await updateAccountFacilityStatus(accountUuid, accountFacilityId, enabled, rangefeeEnabled);
            setAf(updated)
        } catch (error) {
            console.error('failed to update account facility', error);
        }
    }

    async function processAccountFacilityData() {
        try {
            const updated = await updateAccountFacilityData(af.account.uuid, af.id, memberNumber, associationNumber);
            setAf(updated);
            setEditMode(false);
        } catch (error) {
            console.error('failed to update account facility', error);
        }
    }

    async function processAddRangefee() {
        try  {
            if (timeframe.from && timeframe.until) {
                await addAccountRangefee(af.account.uuid, {
                    accountFacilityId: af.id,
                    from: timeframe.from.toJSDate().toISOString(),
                    until: timeframe.until.toJSDate().toISOString(),
                });
                setRangefeeMode(false);
                setRangefeeAdded(true);
            }
        } catch (err) {
            console.error('failed to add rangefee', err);
        }
    }

    async function handleOnDeleteAccountFacility() {
        try {
            await deleteAccountFacility(pAf.id);
            window.location.reload();
        } catch (err) {
            console.log('failed to delete account facility', err);
        }
    }

    const m = useMessages();
    return (
        <ContentBody padding={2}>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item md={6} sm={8} xs={12}>
                    <Box display="flex" flexDirection="row">
                        {authStore.isRoot && (
                            <Fragment>
                                <LabeledText label={m.facilities.singular} align="right">
                                    {af.facility.name}
                                </LabeledText>
                                <Box mx={2} />
                            </Fragment>
                        )}
                        <LabeledText label={m.accounts.memberNumber}>
                            {af.memberNumber || '-'}
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.accounts.associationNumber}>
                            {af.associationNumber || '-'}
                        </LabeledText>
                    </Box>
                </Grid>
                <Grid item md={6} sm={4} xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        <LabeledText label={m.common.status} align="right">
                            <SwitchInput checked={af.enabled} onChange={() => handleFacilityStatusToggle(af)} />
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.devices.enableRangefee} align="right">
                            <SwitchInput checked={af.rangefeeEnabled} onChange={() => handleFacilityRangefeeToggle(af)} />
                        </LabeledText>
                        <Box mx={2} />
                        <Badge color={af.enabled ? 'success' : undefined} label={(af.enabled ? m.common.active : m.common.inactive).toUpperCase()} />
                    </Box>
                </Grid>
            </Grid>
            <Box my={2} />
            <Box display="flex" justifyContent="flex-end">
                {af.rangefeeEnabled && (
                    <Fragment>
                        <Button
                            variant="outlined" color="secondary"
                            startIcon={<ConfirmationNumberRoundedIcon/>}
                            onClick={() => setRangefeeMode(true)}
                        >
                            {m.accounts.rangefee.btn}
                        </Button>
                        <Box mx={1} />
                    </Fragment>
                )}
                <Button
                    variant="outlined" color="primary"
                    startIcon={<EditIcon/>}
                    onClick={() => setEditMode(true)}
                >
                    {m.actions.edit}
                </Button>
                {authStore.isRoot && (
                    <Fragment>
                        <Box mx={1} />
                        <DeleteButton
                            title={m.accounts.deleteFromFacility.title}
                            message={m.accounts.deleteFromFacility.message}
                            onDelete={handleOnDeleteAccountFacility}
                            variant="outlined"
                            color="error"
                        />
                    </Fragment>
                )}
            </Box>
            <Dialog open={editMode} fullWidth maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextInput
                                label={m.accounts.memberNumber}
                                value={memberNumber} onChange={setMemberNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label={m.accounts.associationNumber}
                                value={associationNumber} onChange={setAssociationNumber}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditMode(false)}>{m.actions.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={processAccountFacilityData}>{m.actions.save}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={rangefeeMode} fullWidth maxWidth="sm">
                <DialogTitle>{m.accounts.rangefee.btn}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <DateSelector
                                label={m.common.timeframe.from}
                                date={timeframe.from}
                                onChange={d => setTimeframe({ ...timeframe, from: d || undefined })}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <DateSelector
                                label={m.common.timeframe.until}
                                date={timeframe.until}
                                onChange={d => setTimeframe({ ...timeframe, until: d || undefined })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRangefeeMode(false)}>{m.actions.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={processAddRangefee}>{m.actions.add}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={rangefeeAdded} onClose={() => setRangefeeAdded(false)} autoHideDuration={5000}>
                <Alert severity="error">{m.accounts.rangefee.added}</Alert>
            </Snackbar>
            {/*<DeletePrompt title={m.accounts.deleteFromFacility.title}*/}
            {/*              message={m.accounts.deleteFromFacility.message}*/}
            {/*              onDelete={handleOnDeleteAccountFacility}*/}
            {/*/>*/}
        </ContentBody>
    )
}

type DeviceRowProps = {
    device: Device,
    enabled: boolean,
    onDeviceToggle: () => void,
    onDiscountClick: () => void,
    discount?: AccountDiscount,
}

const DeviceRow = ({device, enabled, onDeviceToggle, onDiscountClick, discount}: DeviceRowProps) => {
    const eligibleForFreeGood = discount && (
        discount.port0Amount === 100 ||
        discount.port1Amount === 100 ||
        discount.port2Amount === 100 ||
        discount.port3Amount === 100
    );
    return (
        <TableRow>
            <TableCell><DeviceTypeBadge type={device.type} /></TableCell>
            <TableCell>{device.name}</TableCell>
            <TableCell>{device.description}</TableCell>
            <TableCell align="right">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    {(DeviceType.BallVendingMachine == device.type) && (
                        <Button size="small" variant="outlined" onClick={onDiscountClick}>Skonto</Button>
                    )}
                    {eligibleForFreeGood && (
                        <Fragment>
                            <Box mx={1} />
                            <Badge label="100% Skonti" color="success" />
                        </Fragment>
                    )}
                </Box>
            </TableCell>
            <TableCell align="right">
                <SwitchInput checked={enabled} onChange={onDeviceToggle} />
            </TableCell>
        </TableRow>
    )
}

const Details = ({account, onEdit}: { account: Account, onEdit: () => void }) => {
    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item lg={6} md={12} sm={12}>
                    <Stack direction="row" alignItems="center">
                        <AccountAvatar account={account} />
                        <Box mx={2} />
                        <LabeledText label={m.common.firstName}>
                            {account.firstName ?? '-'}
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.common.lastName}>
                            {account.lastName ?? '-'}
                        </LabeledText>
                    </Stack>
                    <Box my={2} />
                    <LabeledText label={m.common.address.title}>
                        {account.address ? (
                            <Fragment>
                                <AddressDisplay address={account.address} />
                                <br/>
                                {account.address.country ?? ''}
                            </Fragment>
                        ) : '-'}
                    </LabeledText>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                    <LabeledText label={m.common.email}>
                        {account.email ? (
                            <Fragment>
                                <LabeledBoolean label={''} value={account.emailConfirmedAt != undefined} />
                                <Link href={'mailto:' + account.email}>{account.email}</Link>
                            </Fragment>
                        ) : '-'}
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label={m.common.phone}>
                        {account.phone ? (
                            <Fragment>
                                <LabeledBoolean label={''} value={account.phoneConfirmedAt != undefined} />
                                <Link href={'tel:' + account.phone}>{account.phone}</Link>
                            </Fragment>
                        ) : '-'}
                    </LabeledText>
                </Grid>
            </Grid>
            <Box my={1} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary"
                        startIcon={<EditIcon/>}
                        onClick={onEdit}>{m.actions.edit}</Button>
            </Box>
        </Fragment>
    )
}

const Edit = ({data, onSave, onError, onCancel}: EditFormProps<Account>) => {
    const [inProgress, setInProgress] = useState(false);

    const update = async (form: AccountUpdateRequest) => {
        setInProgress(true);
        try {
            const result = await updateAccount(data.uuid, form);
            onSave(result);
            return;
        } catch (error) {
            onError(error as any);
        }
        setInProgress(false);
    }

    return (
        <AccountForm account={data}
                     inProgress={inProgress}
                     onCancel={onCancel}
                     onSave={update}
        />
    )
}
