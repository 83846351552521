import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {
    Badge as MBadge,
    Box,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";

import {useMessages} from "../../i18n";
import {ApiError} from "../../data/Api";
import {Device, DeviceFilter, DeviceType, Page} from "../../data/types";
import {listDevices} from "../../data/devices";
import {devicePaging, PagingSettings} from "../../data/pagings";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {AppBreadcrumbs, ContentTitle, Crumb} from "../../component/ContentLayout";
import {DeviceTypeBadge} from "../../component/DeviceTypeBadge";
import {SearchInput} from "../../component/SearchInput";
import {Badge} from "../../component/Badge";
import {authStore, uiStore} from "../../store";
import {FilterIcon} from "../../icons";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {listFacilities} from "../../data/facilities";

export const DeviceList = () => {
    const navigate = useNavigate();
    const m = useMessages();

    const columns: ColumnType = {
        'id': { name: 'ID' },
        'type': { name: m.common.type },
        'name': { name: m.common.name },
        'modelNumber': { name: m.devices.modelNumber },
        'serialNumber': { name: m.devices.serialNumber },
        'facility': { name: m.facilities.singular }
    }
    if (!authStore.isRoot) delete columns['facility'];

    const [page, setPage] = useState<Page<Device>>();
    const [needle, setNeedle] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<DeviceFilter>(uiStore.getDeviceFilter() || {});
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(devicePaging.getSettings());
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {type, facilityUuid} = filter;
        return (!!type && type.toString() != 'ALL') || (!!facilityUuid && facilityUuid != 'ALL');
    }, [filter])

    const fetch = useCallback((needle?: string, filter?: DeviceFilter) => {
        listDevices(devicePaging, needle, filter)
            .then(setPage)
            .catch(setError);
    }, [setPage, setError]);

    useEffect(() => {
        if (authStore.isRoot) {
            listFacilities()
                .then(p => setFacilities(p.content.map(f => ({ label: f.name, value: f.uuid }))));
        }
        fetch(needle, filter);
    }, []);

    const handlePagingChange = (pageable: PagingSettings<any>) => {
        devicePaging.updateSettings(pageable);
        setPageable(pageable);
        fetch(needle, filter);
    }

    const handleNeedleSubmit = (needle: string) => {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        devicePaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        fetch(needle, filter);
    }

    const handleFilterSubmit = (f?: DeviceFilter) => {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        devicePaging.updateSettings(adjusted)
        setPageable(adjusted);
        uiStore.setDeviceFilter(f);
        fetch(needle, f);
        setShowFilter(false);
        setFilter(f);
    }

    const resetFilter = () => {
        handleFilterSubmit({});
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.devices.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.devices.plural} path="/devices" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <SearchInput onSubmit={handleNeedleSubmit} />
                    <Box mx={1} />
                    <IconButton onClick={() => setShowFilter(true)}>
                        <MBadge color="primary" variant="dot" invisible={!filterActive}>
                            <FilterIcon />
                        </MBadge>
                    </IconButton>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(d => (
                            <TableRow key={d.id} onClick={() => navigate('/devices/' + d.id)}>
                                <TableCell><Badge label={d.id} /></TableCell>
                                <TableCell><DeviceTypeBadge type={d.type} /></TableCell>
                                <TableCell>{d.name}</TableCell>
                                <TableCell>{d.modelNumber}</TableCell>
                                <TableCell>{d.serialNumber}</TableCell>
                                {authStore.isRoot && <TableCell>{d.facility ? d.facility.name : ''}</TableCell>}
                            </TableRow>
                        ))}
                    </Fragment>
                )}
            />
            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item sm={authStore.isRoot ? 4 : 12} xs={12}>
                            <SelectInput
                                label={m.common.type}
                                options={[
                                    { label: m.common.all, value: 'ALL' },
                                    ...Object.values(DeviceType).map(t => ({
                                        label: m.devices.types[t], value: t
                                    }))
                                ]}
                                value={filter.type || 'ALL'}
                                onChange={type => setFilter({ ...filter, type })}
                            />
                        </Grid>
                        {authStore.isRoot && (
                            <Grid item sm={8} xs={12}>
                                <SelectInput
                                    label={m.facilities.singular}
                                    options={facilities}
                                    value={filter.facilityUuid ?? ''}
                                    onChange={facilityUuid => setFilter({ ...filter, facilityUuid })}
                                    searchable
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
};