import React, {Fragment, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {
    AccountActivityType,
    AccountTransactionDirection,
    EditFormProps,
    Facility,
    FacilityTransactionBookmark,
    FacilityUpdateRequest
} from "../../data/types";
import {
    deleteFacility,
    getCurrentFacility,
    getFacility,
    updateCurrentFacility,
    updateCurrentFacilityTransactionBookmarks,
    updateFacility,
    updateFacilityLogo,
    updateFacilityStatus,
    updateFacilityTransactionBookmarks
} from "../../data/facilities";
import {useMessages} from "../../i18n";
import {EditIcon} from "../../icons";
import {authStore} from "../../store";
import {LabeledText} from "../../component/LabeledText";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {AddressDisplay} from "../../component/AddressDisplay";
import {LabeledBoolean} from "../../component/LabeledBoolean";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {FacilityForm} from "./FacilityForm";
import {Badge} from "../../component/Badge";
import {FacilityTypeBadge} from "../../component/FacilityTypeBadge";
import {SwitchInput} from "../../component/SwitchInput";
import {FacilityLogo} from "../../component/FacilityLogo";
import {formatAmountDigit} from "../../tools";
import {ActionButton, LinkButton} from "../../component/buttons";
import {TextInput} from "../../component/TextInput";
import {SelectInput} from "../../component/SelectInput";
import {CheckboxInput} from "../../component/CheckboxInput";
import {useAsync} from "react-async-hook";
import {ConfirmDialog} from "../../component/ConfirmDialog";
import {AmountEditor} from "../../component/AmountEditor";

export function FacilityDetails() {
    const navigate = useNavigate();
    const {uuid = ''} = useParams();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState<ApiError>();

    const {result: facility, execute} = useAsync(async () => {
        if (authStore.isRoot) return getFacility(uuid);
        else if (authStore.isAdmin) return getCurrentFacility();
        return undefined;
    }, [uuid])

    async function handleOnSave() {
        setEditMode(false);
        await execute();
    }

    async function handleOnDelete() {
        try {
            await deleteFacility(uuid);
            navigate('/facilities');
        } catch (err) {
            console.log('failed to delete facility', err);
            setError(err as any);
        }
    }

    async function handleFacilityStatusToggle() {
        if (facility && authStore.isRoot) {
            try {
                await updateFacilityStatus(facility.uuid, !facility.enabled);
                navigate('/facilities');
            } catch (err) {
                console.log('failed to update facility status', err);
                setError(err as any);
            }
        }
    }

    async function handleFacilityLogoChange(data: string) {
        if (facility && authStore.isRoot) {
            try {
                await updateFacilityLogo(facility.uuid, data);
                navigate('/facilities');
            } catch (err) {
                console.log('failed to update facility logo', err);
                setError(err as any);
            }
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={facility?.name || m.facilities.singular} />
            <AppBreadcrumbs>
                {authStore.isRoot && <Crumb title={m.facilities.plural} path="/facilities" />}
                <Crumb title={m.actions.view} />
            </AppBreadcrumbs>
            <Box my={3} />
            {facility && (
                <Fragment>
                    {!editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                        <FacilityLogo facilityUuid={facility.uuid} allowEdit={authStore.isRoot} onSave={handleFacilityLogoChange} />
                                        <Box mx={2} />
                                        <LabeledText label="L/T/A">
                                            {facility.lta && <Badge label={facility.lta.land + '/' + facility.lta.type + '/' + facility.lta.clubNr} />}
                                            {!facility.lta && '-'}
                                        </LabeledText>
                                        <Box mx={2} />
                                        <LabeledText label={m.common.type}>
                                            <FacilityTypeBadge type={facility.type} />
                                        </LabeledText>
                                        <Box mx={2} />
                                        <LabeledText label={m.common.serviceFee}>
                                            {facility.pinpointServiceFee ?? '-'} %
                                        </LabeledText>
                                    </Grid>
                                    <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        {authStore.isRoot && (
                                            <Fragment>
                                                <SwitchInput label={m.common.status} checked={facility.enabled} onChange={handleFacilityStatusToggle} align="right" />
                                                <Box mx={2} />
                                            </Fragment>
                                        )}
                                        <Badge color={facility.enabled ? 'success' : undefined} label={(facility.enabled ? m.common.active : m.common.inactive).toUpperCase()} />
                                    </Grid>
                                </Grid>
                            </ContentBody>
                            <Box my={3} />
                        </Fragment>
                    )}
                    <ContentBody padding={2}>
                        {!editMode && <Details facility={facility} onEdit={() => setEditMode(true)} />}
                        {editMode && (
                            <Edit
                                data={facility}
                                onCancel={() => setEditMode(false)}
                                onSave={handleOnSave}
                                onError={setError}
                            />
                        )}
                    </ContentBody>
                    {!editMode && (
                        <Fragment>
                            <Box my={2} />
                            <TransactionBookmarks
                                facilityUuid={facility.uuid}
                                transactionBookmarks={facility.transactionBookmarks}
                            />
                        </Fragment>
                    )}
                    {/*<ConfirmDialog*/}
                    {/*    open={editMode && authStore.isRoot}*/}
                    {/*    title={m.facilities.delete.title}*/}
                    {/*    message={m.facilities.delete.message}*/}
                    {/*    onCancel={() => setEditMode(false)}*/}
                    {/*    onConfirm={handleOnDelete}*/}
                    {/*/>*/}
                </Fragment>
            )}
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}

function Details({facility, onEdit}: {facility: Facility, onEdit: () => void}) {
    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item lg={4} md={12} sm={12}>
                    <LabeledText label={m.common.name}>
                        {facility.name}
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label={m.common.description}>
                        {facility.description ?? '-'}
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label="Rangefee">
                        {facility.rangefee ? '€ ' + formatAmountDigit(facility.rangefee.gross) : '-'}
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label={m.facilities.offlineSessionDuration}>
                        {facility.offlineSessionDuration ?? '-'}
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label={m.facilities.maxScanDuration}>
                        {facility.maxScanDuration ?? '-'}
                    </LabeledText>
                </Grid>
                <Grid item lg={8} md={12} sm={12}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.common.address.title}>
                                {facility.address ? (
                                    <Fragment>
                                        <AddressDisplay address={facility.address} />
                                        <br/>
                                        {facility.address.country ?? ''}
                                    </Fragment>
                                ) : '-'}
                            </LabeledText>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.common.contactPerson}>
                                {facility.contactPerson ?? '-'}
                            </LabeledText>
                        </Grid>
                    </Grid>
                    <Box my={2} />
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.common.email}>
                                {facility.contactEmail ? (<Link href={'mailto:' + facility.contactEmail}>{facility.contactEmail}</Link>) : '-'}
                            </LabeledText>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.facilities.supportEmail}>
                                {facility.supportEmail ? (<Link href={'mailto:' + facility.supportEmail}>{facility.supportEmail}</Link>) : '-'}
                            </LabeledText>
                        </Grid>
                    </Grid>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.common.phone}>
                                {facility.contactPhone ? (<Link href={'tel:' + facility.contactPhone}>{facility.contactPhone}</Link>) : '-'}
                            </LabeledText>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LabeledText label={m.facilities.supportPhone}>
                                {facility.supportPhone ? (<Link href={'tel:' + facility.supportPhone}>{facility.supportPhone}</Link>) : '-'}
                            </LabeledText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="overline"><strong>{m.common.properties}</strong></Typography>
                    <LabeledBoolean label={m.facilities.allowedIntlWallet}
                                    value={facility.allowedIntlWallet}
                    />
                </Grid>
            </Grid>
            <Box my={1} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary"
                        startIcon={<EditIcon/>}
                        onClick={onEdit}>{m.actions.edit}</Button>
            </Box>
        </Fragment>
    )
}

// function PermissionGroups({facilityUuid}: {facilityUuid: string}) {
//     const navigate = useNavigate();
//     const m = useMessages();
//
//     const {result: groups, error} = useAsync(async () => {
//         if (authStore.isRoot) {
//             return listFacilityPermissionGroups(facilityUuid);
//         } else if (authStore.isAdmin) {
//             return listCurrentFacilityPermissionGroups();
//         }
//         return [];
//     }, []);
//
//     function openGroup(uuid: string) {
//         if (authStore.isRoot) {
//             navigate(`/facilities/${facilityUuid}/permissions/${uuid}`);
//             return;
//         } else if (authStore.isAdmin) {
//             navigate('/facility/permissions/' + uuid);
//             return;
//         }
//     }
//
//     return (
//         <ContentBody>
//             <Box p={2} pb={0}>
//                 <Typography variant="subtitle1"><strong>{m.facilities.permissionGroup.plural}</strong></Typography>
//             </Box>
//             {!groups && <Loading type="linear" />}
//             {groups && (
//                 <Table>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell>{m.common.name}</TableCell>
//                             <TableCell>{m.common.description}</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {groups.map(g => (
//                             <TableRow key={g.uuid} hover style={{ cursor: 'pointer' }} onClick={() => openGroup(g.uuid)}>
//                                 <TableCell>{g.name} {g.defaultOnSignUp && <Chip label={m.common.default} color="primary" size="small" />}</TableCell>
//                                 <TableCell>{g.description ?? '-'}</TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             )}
//             <Box p={2}>
//                 <LinkButton
//                     color="primary"
//                     size="small"
//                     to={authStore.isRoot ? `/facilities/${facilityUuid}/permissions/create` : '/facility/permissions/create'}
//                 >
//                     {m.actions.create}
//                 </LinkButton>
//             </Box>
//         </ContentBody>
//     )
// }

function TransactionBookmarks({facilityUuid, transactionBookmarks: pTransactionBookmarks}: {facilityUuid: string, transactionBookmarks: FacilityTransactionBookmark[]}) {
    const m = useMessages();

    const [bookmarks, setBookmarks] = useState<FacilityTransactionBookmark[]>(pTransactionBookmarks);
    const [activeIndex, setActiveIndex] = useState<number|null>(null);
    const [bookmark, setBookmark] = useState<FacilityTransactionBookmark>();
    const [deleteMode, setDeleteMode] = useState(false);
    const [error, setError] = useState(false);

    function openBookmark(i: number) {
        setBookmark(bookmarks[i]);
        setActiveIndex(i);
    }

    function closeBookmark() {
        setBookmark(undefined);
        setActiveIndex(null);
    }

    function addBookmark() {
        setBookmark({
            direction: AccountTransactionDirection.BookIn,
            allowedCustomAmount: false,
            allowedCustomMessage: false
        });
    }

    function handleChange(name: keyof FacilityTransactionBookmark, value: any) {
        if (bookmark) {
            setBookmark({
                ...bookmark,
                [name]: value
            });
        }
    }

    async function handleSave() {
        if (bookmark) {
            let modified;
            if (activeIndex === null) {
                modified = bookmarks.concat(bookmark);
            } else {
                modified = bookmarks.map((b, i) => ((i === activeIndex) ? bookmark : b));
            }
            await putBookmarks(modified);
            setBookmarks(modified);
            setBookmark(undefined);
            setActiveIndex(null);
        }
    }

    async function handleDelete() {
        if (activeIndex !== null) {
            const modified = bookmarks.filter((_b, i) => i !== activeIndex);
            await putBookmarks(modified);
            setBookmarks(modified);
            setBookmark(undefined);
            setActiveIndex(null);
            setDeleteMode(false);
        }
    }

    async function putBookmarks(bookmarks: FacilityTransactionBookmark[]) {
        try {
            let processed = bookmarks.map(b => {
                if (!b.type) delete b.type;
                return b;
            })
            if (authStore.isRoot) {
                await updateFacilityTransactionBookmarks(facilityUuid, processed);
            } else if (authStore.isAdmin) {
                await updateCurrentFacilityTransactionBookmarks(processed);
            }
        } catch (err) {
            console.error('could not save transaction bookmarks', err);
            setError(true);
        }
    }

    return (
        <Fragment>
            <ContentBody>
                <Box p={2} pb={0}>
                    <Typography variant="subtitle1"><strong>{m.facilities.transactionBookmarks.plural}</strong></Typography>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{m.common.direction}</TableCell>
                            <TableCell>{m.common.type}</TableCell>
                            <TableCell>{m.common.amount}</TableCell>
                            <TableCell>{m.facilities.transactionBookmarks.message}</TableCell>
                            <TableCell>{m.facilities.transactionBookmarks.allowedCustomAmount}</TableCell>
                            <TableCell>{m.facilities.transactionBookmarks.allowedCustomMessage}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookmarks.map((b, i) => (
                            <TableRow key={'transaction-bookmark-' + i} hover onClick={() => openBookmark(i)}>
                                <TableCell>{m.transactions.direction[b.direction]}</TableCell>
                                <TableCell>{b.type && m.accounts.activities[b.type]}</TableCell>
                                <TableCell>{b.amount ? '€ ' + formatAmountDigit(b.amount) : '-'}</TableCell>
                                <TableCell>{b.message ?? '-'}</TableCell>
                                <TableCell><LabeledBoolean value={b.allowedCustomAmount} /></TableCell>
                                <TableCell><LabeledBoolean value={b.allowedCustomMessage} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box p={2}>
                    <ActionButton
                        color="primary"
                        size="small"
                        onClick={addBookmark}
                    >
                        {m.actions.create}
                    </ActionButton>
                </Box>
            </ContentBody>
            {bookmark && (
                <Dialog open={true} fullWidth maxWidth="sm">
                    <DialogTitle>{m.facilities.transactionBookmarks.singular}</DialogTitle>
                    <DialogContent>
                        <Box my={1} />
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <SelectInput
                                    label={m.common.direction}
                                    value={bookmark.direction}
                                    options={[
                                        { label: m.transactions.direction.BookIn, value: AccountTransactionDirection.BookIn },
                                        { label: m.transactions.direction.BookOut, value: AccountTransactionDirection.BookOut },
                                    ]}
                                    onChange={v => handleChange('direction', v)}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectInput
                                    label={m.common.type}
                                    value={bookmark.type}
                                    options={[
                                        { label: m.common.none, value: '' },
                                        { label: m.accounts.activities.InAppPaymentTransaction, value: AccountActivityType.InAppPaymentTransaction },
                                    ]}
                                    onChange={v => handleChange('type', v)}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <AmountEditor
                                    amount={bookmark.amount}
                                    onChange={v => handleChange('amount', v)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label={m.facilities.transactionBookmarks.message}
                                    value={bookmark.message ?? ''}
                                    onChange={v => handleChange('message', v)}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CheckboxInput
                                    label={m.facilities.transactionBookmarks.allowedCustomAmount}
                                    secondaryText={m.facilities.transactionBookmarks.allowedCustomAmountHint}
                                    value={bookmark.allowedCustomAmount}
                                    onChange={v => handleChange('allowedCustomAmount', v)}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CheckboxInput
                                    label={m.facilities.transactionBookmarks.allowedCustomMessage}
                                    secondaryText={m.facilities.transactionBookmarks.allowedCustomMessageHint}
                                    value={bookmark.allowedCustomMessage}
                                    onChange={v => handleChange('allowedCustomMessage', v)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton color="error" onClick={() => setDeleteMode(true)}>{m.actions.delete}</ActionButton>
                        <Box sx={{ flex: 1 }} />
                        <ActionButton color="primary" onClick={handleSave}>{m.actions.save}</ActionButton>
                        <ActionButton variant="text" onClick={closeBookmark}>{m.actions.cancel}</ActionButton>
                    </DialogActions>
                </Dialog>
            )}
            <ConfirmDialog
                open={deleteMode}
                title={m.facilities.transactionBookmarks.delete.title}
                message={m.facilities.transactionBookmarks.delete.message}
                onCancel={() => setDeleteMode(false)}
                onConfirm={handleDelete}
            />
        </Fragment>
    )
}

const Edit = ({data, onSave, onError, onCancel}: EditFormProps<Facility>) => {
    const [inProgress, setInProgress] = useState(false);

    const update = async (form: FacilityUpdateRequest) => {
        setInProgress(true);
        try {
            if (authStore.isRoot) {
                const result = await updateFacility(data.uuid, form);
                onSave(result);
            } else if (authStore.isAdmin) {
                const result = await updateCurrentFacility(form);
                onSave(result);
            }
            return;
        } catch (error) {
            onError(error as any);
        }
        setInProgress(false);
    }

    return (
        <FacilityForm
            facility={data}
            inProgress={inProgress}
            onCancel={onCancel}
            onSave={update}
        />
    )
}
