import React, {Fragment, useEffect, useMemo, useState} from "react";
import {
    Badge as MBadge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormHelperText,
    Grid,
    IconButton,
    Popover,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {useMessages} from "../../i18n";
import {displayAccountNameLine, displayAddressLine, displayDate} from "../../utils";
import {ApiError} from "../../data/Api";
import {Account, AccountFacility, AccountFacilityOverview, AccountFilter, Facility, WalletType} from "../../data/types";
import {
    getAccountFacility,
    listAccountFacilities,
    listAccountFacilityOverviews,
    listAccounts,
    listAccountWallets
} from "../../data/accounts";
import {accountPaging, PagingImpl, PagingSettings} from "../../data/pagings";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {AppBreadcrumbs, ContentTitle, Crumb} from "../../component/ContentLayout";
import {SearchInput} from "../../component/SearchInput";
import {authStore, uiStore} from "../../store";
import {DownloadIcon, FilterIcon} from "../../icons";
import {SelectInput} from "../../component/SelectInput";
import {LabeledBoolean} from "../../component/LabeledBoolean";
import {LabeledText} from "../../component/LabeledText";
import {FacilitySelect} from "../../component/FacilitySelect";
import {useAsync} from "react-async-hook";
import {Loading} from "../../component/Loading";
import {AccountAvatar} from "./AccountAvatar";
import {formatAmountDigit} from "../../tools";
import {Link} from "react-router-dom";
import {ActionButton} from "../../component/buttons";
import {exportAccountExcel, exportAllFacilitiesAccountsExcel} from "../../tools/export";

type AccountFacilitiesMap = {
    uuid: string,
    facilities: AccountFacility[]
}

export function AccountList() {
    const m = useMessages();

    const columns: ColumnType = {
        'enabled': { name: m.common.active, align: 'center' },
        'firstName': { name: m.common.name },
        'createdAt': { name: m.common.createdAt },
        'balanceIntl': { name: m.wallets.balance + ' (PINPOINT)', unsortable: true },
        'balanceClub': { name: m.wallets.balance + ' (Club)', unsortable: true },
        'email': { name: m.common.email },
        'phone': { name: m.common.phone },
        'club': { name: m.facilities.plural, unsortable: true },
    };
    if (!authStore.isRoot) {
        delete columns['balanceIntl'];
        delete columns['club'];
    } else {
        delete columns['enabled'];
    }

    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<AccountFilter>(uiStore.getAccountFilter() || {});
    const [needle, setNeedle] = useState('');
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(accountPaging.getSettings());
    const [error, setError] = useState<ApiError>();

    const [popoverAf, setPopoverAf] = useState<AccountFacility>();
    const [popoverAnchor, setPopoverAnchor] = useState<any>(null);

    const {result: page, execute: loadPage} = useAsync(() => {
        return listAccounts(accountPaging, needle, filter);
    }, [needle, pageable]);
    const {result: afMap} = useAsync(async () => {
        if (authStore.isRoot && page) return Promise.all<AccountFacilitiesMap>(page.content.map(async ({uuid}) => ({
            uuid,
            facilities: await listAccountFacilities(uuid)
        })));
    }, [page]);

    const filterActive = useMemo(() => {
        const {enabled, facilityUuid} = filter;
        return (!!enabled && enabled !== 'ALL') || (authStore.isRoot && !!facilityUuid && facilityUuid !== 'ALL');
    }, [filter])

    async function handleNeedleChange(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        accountPaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
    }

    async function handlePagingChange(pageable: PagingSettings<any>) {
        accountPaging.updateSettings(pageable);
        setPageable(pageable);
    }

    async function handleFilterSubmit(f?: AccountFilter) {
        let modifiedFilter = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        accountPaging.updateSettings(adjusted);
        setPageable(adjusted);
        uiStore.setAccountFilter(modifiedFilter);
        setShowFilter(false);
        setFilter(modifiedFilter);
        await loadPage();
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }

    function handlePopOpen(ev: React.MouseEvent, af: AccountFacility) {
        setPopoverAf(af);
        setPopoverAnchor(ev.currentTarget);
    }

    function handlePopClose() {
        setPopoverAf(undefined);
        setPopoverAnchor(null);
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.accounts.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.accounts.plural} path="/accounts" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                    >
                        <SearchInput onSubmit={handleNeedleChange} />
                        <IconButton onClick={() => setShowFilter(true)}>
                            <MBadge color="primary" variant="dot" invisible={!filterActive}>
                                <FilterIcon />
                            </MBadge>
                        </IconButton>
                        <AccountExportButton />
                    </Stack>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(a => {
                            let af;
                            if (afMap) af = afMap.find(m => m.uuid === a.uuid) || null;
                            return (
                                <TableRow key={a.uuid} hover component={Link} to={'/accounts/' + a.uuid}>
                                    {!authStore.isRoot && <TableCell align="center"><AccountFacilityStatus account={a} /></TableCell>}
                                    <TableCell>
                                        <Stack direction="row" alignItems="center">
                                            <AccountAvatar account={a} />
                                            <Box sx={{ ml: 2 }}>
                                                <Typography>{displayAccountNameLine(a)}</Typography>
                                                <Typography>{a.address ? displayAddressLine(a.address) : '-'}</Typography>
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{displayDate(a.createdAt)}</TableCell>
                                    <AccountWallets account={a} />
                                    <TableCell>{a.email}</TableCell>
                                    <TableCell>{a.phone}</TableCell>
                                    {authStore.isRoot && (
                                        <TableCell>
                                            {!af && <Loading type="circular" CircularProgressProps={{ size: 15 }} />}
                                            {af && (
                                                <Fragment>
                                                    {af.facilities.map((f, i) => (
                                                        <Box
                                                            key={'a-' + a.uuid + '-f-' + f.facility.uuid}
                                                            mt={i > 0 ? 1 : 0}
                                                            onMouseEnter={ev => handlePopOpen(ev, f)}
                                                            onMouseLeave={handlePopClose}
                                                        >

                                                            <Typography
                                                                color="textSecondary"
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                            >
                                                                <small>{displayDate(f.createdAt)}</small>
                                                            </Typography>



                                                            <Typography
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    fontWeight: 'bold',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <LabeledBoolean label={''} value={f.enabled} />
                                                                {f.facility.name}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                    <Popover
                                                        sx={{
                                                            pointerEvents: 'none'
                                                        }}
                                                        elevation={0}
                                                        open={!!popoverAnchor}
                                                        anchorEl={popoverAnchor}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        onClose={handlePopClose}
                                                        disableRestoreFocus
                                                    >
                                                        <Box sx={{ border: '1px solid #ddd', p: 1 }}>
                                                            <LabeledText label={m.accounts.memberNumber}>
                                                                <small>{popoverAf?.memberNumber ?? '-'}</small>
                                                            </LabeledText>
                                                            <LabeledText label={m.accounts.associationNumber}>
                                                                <small>{popoverAf?.associationNumber ?? '-'}</small>
                                                            </LabeledText>
                                                        </Box>
                                                    </Popover>
                                                </Fragment>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                    </Fragment>
                )}
            />
            {/*<AppFab onClick={() => navigate('/accounts/create')} children={<PlusIcon />}/>*/}

            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={authStore.isRoot ? 4 : 12} xs={12}>
                            <SelectInput
                                label={m.common.status}
                                options={[
                                    { label: m.common.all, value: 'ALL' },
                                    { label: m.accounts.onlyEnabled, value: '1' },
                                    { label: m.accounts.onlyDisabled, value: '0' },
                                ]}
                                value={filter.enabled || 'ALL'}
                                onChange={enabled => setFilter({ ...filter, enabled })}
                            />
                        </Grid>
                        {authStore.isRoot && (
                            <Grid item sm={8} xs={12}>
                                <FacilitySelect
                                    value={filter.facilityUuid}
                                    onChange={f => setFilter({ ...filter, facilityUuid: f?.uuid })}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}

function AccountWallets({account}: {account: Account}) {
    const {result: wallets} = useAsync(() => {
        return listAccountWallets(account.uuid);
    }, [account]);

    const intlWallet = (wallets && wallets.find(w => w.type === WalletType.International));
    const clubWallets = (wallets && wallets.filter(w => w.type === WalletType.Facility));

    return (
        <Fragment>
            {authStore.isRoot && (
                <TableCell>
                    {intlWallet && (
                        <Fragment>
                            <strong>{intlWallet.facility?.name}</strong>
                            <br/>
                            € {formatAmountDigit(intlWallet.amount)}
                        </Fragment>
                    )}
                </TableCell>
            )}
            <TableCell>
                {clubWallets && clubWallets.map(w => (
                    <Typography key={w.uuid} sx={{ whiteSpace: 'nowrap', my: 0.5 }}>
                        {authStore.isRoot && (
                            <Fragment>
                                <strong>{w.facility && w.facility.name}</strong>
                                <br/>
                            </Fragment>
                        )}
                        € {formatAmountDigit(w.amount)}
                    </Typography>
                ))}
            </TableCell>
        </Fragment>
    )
}

function AccountFacilityStatus({account}: {account: Account}) {
    const {result: af} = useAsync(() => getAccountFacility(account.uuid), [account]);

    if (!af) return <Loading type="circular" CircularProgressProps={{ size: 15 }} />
    return (
        <LabeledBoolean value={af.enabled} centered />
    )
}

function AccountExportButton() {
    const m = useMessages();

    const [facility, setFacility] = useState<Facility>();
    const [enabled, setEnabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [lock, setLock] = useState(false);

    useEffect(() => {
        setFacility(authStore.facility as Facility);
    }, [])

    async function handleExport() {
        setLock(true);
        setProgress(0);

        const data: AccountFacilityOverview[] = [];
        const paging = new PagingImpl('export_accounts', true);
        paging.updateSettings({ ...paging.getSettings(), pageSize: 100 });

        let page;
        try {
            page = await listAccountFacilityOverviews(paging, facility?.uuid);
            data.push(...page.content);
        } catch (err) {
            console.error('failed to export accounts', err);
            setLock(false);
            return;
        }

        const maxPage = page.totalPages;
        setProgress(Math.round(100 / maxPage));
        for (let i = 1; i <= maxPage; i++) {
            paging.updateSettings({ ...paging.getSettings(), pageNumber: i });
            try {
                page = await listAccountFacilityOverviews(paging, facility?.uuid);
                data.push(...page.content);
                setProgress(Math.round(i * 100 / maxPage));
            } catch (err) {
                console.error('failed to export accounts', err);
                setLock(false);
                return;
            }
        }

        if (facility) {
            exportAccountExcel(facility, data);
        } else {
            exportAllFacilitiesAccountsExcel(data);
        }

        setEnabled(false);
        setLock(false);
    }

    return (
        <Fragment>
            <IconButton onClick={() => setEnabled(true)}>
                <DownloadIcon />
            </IconButton>
            <Dialog open={enabled} maxWidth="sm" fullWidth>
                <DialogTitle>{m.actions.export}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {authStore.isRoot && (
                                <Fragment>
                                    <FacilitySelect
                                        value={facility?.uuid}
                                        onChange={setFacility}
                                    />
                                    <Box pl={2}>
                                        <FormHelperText>{m.facilities.selectAllHint}</FormHelperText>
                                    </Box>
                                </Fragment>
                            )}
                            {!authStore.isRoot && (
                                <LabeledText label={m.facilities.singular}>
                                    {facility?.name}
                                </LabeledText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {!!progress && (
                                <Loading
                                    type="linear"
                                    LinearProgressProps={{
                                        variant: 'determinate',
                                        value: progress,
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton variant="text" onClick={() => setEnabled(false)}>{m.actions.cancel}</ActionButton>
                    <ActionButton onClick={handleExport}>{m.actions.export}</ActionButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}