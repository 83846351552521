import React, {useEffect, useState} from "react";
import {MobileTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useMessages} from "../i18n";
import {luxonDate} from "../tools";
import {DateTime} from "luxon";

export const TimeSelector = ({label, time, onChange}: { label?: string, time?: string, onChange: (time: string|null) => void}) => {
    const [value, setValue] = useState<DateTime|null>(null);

    useEffect(() => {
        if (time) {
            const [hour, minute] = time.split(':');
            setValue(luxonDate(new Date()).set({
                hour: parseInt(hour),
                minute: parseInt(minute)
            }));
        }
    }, [time])

    const m = useMessages();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
                label={label || m.common.time}
                format={m.formats.time}
                value={value?.toJSDate()}
                onChange={t => {
                    const value = luxonDate(t);
                    setValue(luxonDate(t));
                    onChange(`${('0' + value.hour).slice(-2)}:${('0' + value.minute).slice(-2)}`)
                }}
                ampm={false}
                closeOnSelect={true}
                sx={{ width: '100%' }}
            />
        </LocalizationProvider>
    )
}