import {Pageable} from "./types";

export interface PagingSettings<K> extends Pageable<K> {
}

export interface Paging {
    updateSettings: <K> (settings: PagingSettings<K>) => void;
    getSettings: <K> () => PagingSettings<K>;
    toQueryString: (prefix?: string) => void;
}

export const defaultPageable = { pageNumber: 0, pageSize: 25 };

export class PagingImpl implements Paging {

    private _stored?: string;

    constructor(private _storageKey: string|null, private _inMemory?: boolean) {
        if (!_inMemory && !!_storageKey) {
            const stored = localStorage.getItem(_storageKey);
            if (!stored) {
                localStorage.setItem(_storageKey, JSON.stringify(defaultPageable));
            }
        } else {
            this._stored = JSON.stringify(defaultPageable);
        }
    }

    updateSettings<K>(settings: PagingSettings<K>) {
        if (!this._inMemory && !!this._storageKey) {
            localStorage.setItem(this._storageKey, JSON.stringify(settings));
        } else {
            this._stored = JSON.stringify(settings);
        }

    }

    getSettings<K>(): PagingSettings<K> {
        if (!this._inMemory && !!this._storageKey) {
            const stored = localStorage.getItem(this._storageKey);
            if (stored) return JSON.parse(stored) as PagingSettings<K>;
        } else if (this._stored) return JSON.parse(this._stored) as PagingSettings<K>;
        return defaultPageable;
    }

    resetSettings() {
        if (!!this._storageKey) {
            localStorage.setItem(this._storageKey, JSON.stringify(defaultPageable));
        }
        this._stored = JSON.stringify(defaultPageable);
    }

    toQueryString(prefix?: string) {
        const {pageNumber, pageSize, sort, direction} = this.getSettings();
        let query = `page=${pageNumber}&size=${pageSize}`;
        if (sort) {
            if ((sort as string).indexOf('&') > -1) {
                for (let k of (sort as string).split('&')) {
                    query += `&sort=${k},${direction || 'desc'}`;
                }
            } else {
                query += `&sort=${sort},${direction || 'desc'}`;
            }

        }
        return `${prefix ? prefix + '&' : '?'}${query}`;
    }
}

export const facilityPaging = new PagingImpl('facility_paging');
export const userPaging = new PagingImpl('user_paging');
export const devicePaging = new PagingImpl('device_paging');
export const accountPaging = new PagingImpl('account_paging');
export const accountGroupPaging = new PagingImpl('account_group_paging');
export const walletPaging = new PagingImpl('wallet_paging');
export const walletIntlPaging = new PagingImpl('wallet_intl_paging');
export const noticePaging = new PagingImpl('notice_paging');
export const paymentPaging = new PagingImpl('payment_paging');