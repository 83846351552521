import React, {useMemo, useState} from "react";
import {AccountTransactionDirection, FacilityTransactionBookmark, Wallet} from "../../data/types";
import {useAsync} from "react-async-hook";
import {authStore} from "../../store";
import {listCurrentFacilityTransactionBookmarks, listFacilityTransactionBookmarks} from "../../data/facilities";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {ActionButton} from "../../component/buttons";
import {MinusIcon, PlusIcon} from "../../icons";
import {useMessages} from "../../i18n";
import {SelectInput} from "../../component/SelectInput";
import {AmountEditor} from "../../component/AmountEditor";
import {TextInput} from "../../component/TextInput";
import {modifyWalletAmount} from "../../data/wallets";
import {RadioInput} from "../../component/RadioInput";
import {formatAmountDigit} from "../../tools";

interface WalletAmountFormProps {
    open: boolean,
    wallet: Wallet;
    onModified: (wallet: Wallet) => void,
    onClose: () => void,
}
export function WalletAmountForm({open, wallet: { uuid, facility, amount: wAmount }, onModified, onClose}: WalletAmountFormProps) {
    const m = useMessages();

    const {result: bookmarks} = useAsync(async () => {
        if (!facility) return [];
        if (authStore.isRoot) return listFacilityTransactionBookmarks(facility.uuid);
        return listCurrentFacilityTransactionBookmarks();
    }, [facility]);

    const [direction, setDirection] = useState<Omit<AccountTransactionDirection, 'All'>>(AccountTransactionDirection.BookIn);
    const [amount, setAmount] = useState(0);
    const [note, setNote] = useState('');
    const [inProgress, setInProgress] = useState(false);

    const bookmarkOptions = useMemo(() => {
        if (!direction || !bookmarks) return [];
        return bookmarks
            .filter(b => b.direction === direction)
            .map(b => {
                let label = '';
                if (b.amount) label += '€ ' + formatAmountDigit(b.amount) + ' | ';
                label += b.message ?? '-';
                return { label, value: b };
            });
    }, [direction, bookmarks]);

    function onBookmarkSelected({message, amount}: FacilityTransactionBookmark) {
        setAmount(amount ?? 0);
        setNote(message ?? '');
    }

    async function confirmAmount() {
        try {
            setInProgress(true);
            if (amount && !!note && !!direction) {
                const modified = await modifyWalletAmount(uuid, { amount, note, direction });
                onModified(modified);
            }
        } catch (err) {
            console.error('Failed to modify wallet amount', err);
        } finally {
            setInProgress(false);
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>{m.transactions.direction.BookIn} / {m.transactions.direction.BookOut}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RadioInput
                            label={m.common.direction}
                            options={[
                                { label: m.transactions.direction.BookIn, value: AccountTransactionDirection.BookIn },
                                { label: m.transactions.direction.BookOut, value: AccountTransactionDirection.BookOut },
                            ]}
                            value={direction}
                            onChange={setDirection}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput
                            disabled={inProgress}
                            label={m.facilities.transactionBookmarks.singular}
                            options={bookmarkOptions}
                            onChange={onBookmarkSelected}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AmountEditor
                            required
                            disabled={inProgress}
                            amount={amount}
                            onChange={setAmount}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            required
                            disabled={inProgress}
                            label={m.common.reason}
                            value={note}
                            onChange={setNote}
                            error={!note}
                            helperText={!note ? m.error.requiredField : undefined}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {direction && (
                    <ActionButton
                        color="primary"
                        onClick={confirmAmount}
                        loading={inProgress}
                        disabled={inProgress}
                    >
                        {m.actions.confirm}
                    </ActionButton>
                )}
                <ActionButton
                    variant="outlined"
                    onClick={onClose}
                    disabled={inProgress}
                >
                    {m.actions.cancel}
                </ActionButton>
            </DialogActions>
        </Dialog>
    )
}