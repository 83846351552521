import {Api} from "./Api";
import {Account, AccountKpi, FacilityKpi, FacilitySpecificKpi, TransactionKpi} from "./types";

/**
 * Facility Access Only
 */
export function getFacilitySpecificKpi() {
    return Api.GET<FacilitySpecificKpi>('kpis');
}

/**
 * Root Access Only
 */
export function getFacilityKpi() {
    return Api.GET<FacilityKpi>('kpis/facility');
}
export function getAccountKpi() {
    return Api.GET<AccountKpi>('kpis/account');
}
export function getTransactionKpi() {
    return Api.GET<TransactionKpi>('kpis/transaction');
}