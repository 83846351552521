import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";

import {useMessages} from "../../i18n";
import {SaveIcon} from "../../icons";
import {listFacilities} from "../../data/facilities";
import {Account, AccountCreateRequest, AccountUpdateRequest} from "../../data/types";
import {TextInput} from "../../component/TextInput";
import {CountrySelector} from "../../component/CountrySelector";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {authStore} from "../../store";

type AccountFormProps = {
    inProgress: boolean,
    onCancel: () => void,
    account?: Account,
    onCreate?: (form: AccountCreateRequest) => void,
    onSave?: (form: AccountUpdateRequest) => void
}

export const AccountForm = ({inProgress, onCancel, account, onCreate, onSave}: AccountFormProps) => {
    const [createForm, setCreateForm] = useState<AccountCreateRequest>({ email: '', facilityUuid: '', address: {} });
    const [updateForm, setUpdateForm] = useState<AccountUpdateRequest|undefined>(account ? { ...account, address: account.address || {} } : undefined);
    const [facilities, setFacilities] = useState<SelectOption[]>([]);

    const getForm = useMemo(() => updateForm ? updateForm : createForm, [updateForm, createForm]);
    const setForm = useMemo(() => updateForm ? setUpdateForm : setCreateForm, [updateForm]);
    const canSubmit = useMemo(() => (!inProgress && (account || (!account && createForm.email && createForm.facilityUuid))) as boolean, [inProgress, getForm, account, createForm]);

    useEffect(() => {
        if (authStore.isRoot) {
            listFacilities()
                .then(p => setFacilities(p.content.map(f => ({ label: f.name, value: f.uuid }))));
        }
    }, []);

    const onFormChange = (name: string, value: any) => {
        setForm({
            ...getForm as any,
            [name]: value
        });
    }

    const onAddressChange = (name: string, value: string) => {
        setForm({
            ...getForm as any,
            address: {
                ...getForm.address,
                [name]: value
            }
        });
    }

    const submit = () => {
        if (account && updateForm) {
            if (onSave) onSave(updateForm);
        } else {
            if (onCreate) onCreate(createForm)
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <Typography variant="h6">{m.common.identity}</Typography>
            <Box my={2} />
            {!account && (
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <SelectInput label={m.facilities.singular}
                                     options={facilities}
                                     value={createForm.facilityUuid ?? ''}
                                     onChange={v => onFormChange('facilityUuid', v)}
                                     searchable
                                     error={!createForm.facilityUuid}
                                     helperText={!createForm.facilityUuid ? m.error.requiredField : undefined}
                        />
                    </Grid>
                </Grid>
            )}
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextInput label={m.common.firstName}
                               value={getForm.firstName ?? ''} onChange={v => onFormChange('firstName', v)}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInput label={m.common.lastName}
                               value={getForm.lastName ?? ''} onChange={v => onFormChange('lastName', v)}
                    />
                </Grid>
            </Grid>

            <Box my={3}><Divider/></Box>

            <Typography variant="h6">{m.common.address.title}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.address.addressLine1}
                               value={getForm.address.addressLine1 ?? ''}
                               onChange={v => onAddressChange('addressLine1', v)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.address.addressLine2}
                               value={getForm.address.addressLine2 ?? ''}
                               onChange={v => onAddressChange('addressLine2', v)}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextInput label={m.common.address.zipCode}
                               value={getForm.address.zipCode ?? ''}
                               onChange={v => onAddressChange('zipCode', v)}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextInput label={m.common.address.city}
                               value={getForm.address.city ?? ''}
                               onChange={v => onAddressChange('city', v)}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CountrySelector countryCode={getForm.address.country}
                                     onChange={cc => onAddressChange('country', cc)}
                    />
                </Grid>
            </Grid>

            <Box my={3}><Divider/></Box>

            <Typography variant="h6">{m.common.contactDetails}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.email}
                               value={getForm.email}
                               onChange={v => onFormChange('email', v)}
                               error={!getForm.email}
                               helperText={!getForm.email ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.phone}
                               value={getForm.phone ?? ''}
                               onChange={v => onFormChange('phone', v)}
                    />
                </Grid>
            </Grid>

            <Box my={3} />

            <Box display="flex" justifyContent="flex-end">
                <Button disableElevation variant="contained" color="primary" size="medium"
                        disabled={!canSubmit} startIcon={<SaveIcon/>}
                        onClick={submit}>{updateForm ? m.actions.save : m.actions.create}</Button>
                <Box mx={1}/>
                <Button size="medium" onClick={onCancel}>{m.actions.cancel}</Button>
            </Box>
        </Fragment>
    )
}