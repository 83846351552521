import {authStore} from "../store";
import {UserRole} from "./types";

const BaseHeaders = {
    'Content-Type': 'application/json',
};

export class ApiError extends Error {

    constructor(private _code: number, private _message: string|undefined, private _path: string) {
        super(_message);
    }

    get code() {
        return this._code;
    }

    get message() {
        return this._message || `An error occurred with code (${this.code}) on path (${this.path}).`;
    }

    get path() {
        return this._path;
    }
}

export class Api {

    private static buildHeaders() {
        if(authStore.authenticated) {
            return {
                ...BaseHeaders,
                'Authorization': `Bearer ${authStore.token}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }
        return BaseHeaders;
    }

    private static handle401s(response: Response) {
        if(response.status == 401) {
            this.redirectToLogin();
        }
    }

    private static redirectToLogin() {
        console.error('Unauthorized Request, trying to send user back to login.');
        window.location.replace('/login');
    }

    private static buildUrl(path: string) {
        const user = authStore.user;
        if (!user) {
            throw new Error('User not authorized.');
        }
        const servicePath = UserRole.Root === user.role ? '/root' : '/admin';
        const facilityPath = UserRole.Root === user.role ? '' : ('/f/' + authStore.facility?.uuid);
        if(!path.startsWith('/')) {
            path = '/' + path;
        }
        return `/api${servicePath}/v1${facilityPath}${path}`;
    }

    private static fetchData<T>(path: string, requestBody: RequestInit, blob?: boolean) {
        return new Promise(async (resolve: (value: T) => void, reject: (error: ApiError) => void) => {
            try {
                return fetch(path, requestBody)
                    .then(async (response) => {
                        // this.handle401s(response);
                        if (!response.ok) {
                            const message = await response.text();
                            console.error(`An error occurred, status code ${response.status} with message ${message} on path ${path}.`);
                            reject(new ApiError(response.status, message, path));
                            return;
                        }
                        try {
                            if (blob) {
                                resolve(await response.blob() as any);
                            } else {
                                resolve(await response.json() as T);
                            }
                        } catch (error) {
                            console.warn('Body is empty. Return nothing.');
                            resolve('' as any);
                        }
                    })
            } catch (e) {
                console.error("Failed to fetch. Please contact support.");
                reject(e as any);
            }
        });
    }

    static GET<T>(path: string, blob?: boolean) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'GET',
            headers: Api.buildHeaders()
        }, blob);
    }

    static PUT<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'PUT',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static POST<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'POST',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static DELETE<T>(path: string, object?: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'DELETE',
            headers: Api.buildHeaders(),
            body: object ? JSON.stringify(object) : undefined
        });
    }
}