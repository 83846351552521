import {useNavigate, useParams} from "react-router";
import React, {Fragment, useCallback, useState} from "react";
import {Account, AccountGroup, Device, Page} from "../../data/types";
import {PagingImpl, PagingSettings} from "../../data/pagings";
import {useMessages} from "../../i18n";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {
    assignAccountsToGroup, assignPermissionsToGroup,
    deleteAccountGroup,
    getAccountGroup,
    listAccounts,
    listGroupAccounts, removeAccountsFromGroup, updateAccountGroupDevicePermission
} from "../../data/accounts";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb, DeletePrompt} from "../../component/ContentLayout";
import {
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid, IconButton,
    List,
    ListItem, ListItemText, Paper, Table, TableBody,
    TableCell,
    TableRow, Typography
} from "@mui/material";

import {LabeledText} from "../../component/LabeledText";
import {authStore} from "../../store";
import {CancelIcon, EditIcon, GroupAddIcon, GroupRemoveIcon} from "../../icons";
import {AccountGroupForm} from "./AccountGroupForm";
import {displayAccountNameLine, displayAddressLine, displayDate} from "../../utils";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import useAsyncEffect from "use-async-effect";
import {SwitchInput} from "../../component/SwitchInput";
import {listDevices} from "../../data/devices";

const accountGroupAccountsPaging = new PagingImpl('account_group_accounts', true);

export const AccountGroupDetails = () => {
    const navigate = useNavigate();
    const m = useMessages();

    const columns: ColumnType = {
        'firstName': { name: m.common.name },
        'email': { name: m.common.email },
        'address': { name: m.common.address.title, unsortable: true },
    }

    const {uuid = ''} = useParams();
    const [group, setGroup] = useState<AccountGroup>();
    const [page, setPage] = useState<Page<Account>>();
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(accountGroupAccountsPaging.getSettings());
    const [accounts, setAccounts] = useState<SelectOption[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [selectAccount, setSelectAccount] = useState<string>();
    const [selectList, setSelectList] = useState<string[]>([]);
    const [accountAddDialog, setAccountAddDialog] = useState(false);
    const [accountRemoveDialog, setAccountRemoveDialog] = useState(false);
    const [devices, setDevices] = useState<Device[]>([]);

    const fetchGroupAccounts = useCallback(async () => {
        try {
            const page = await listGroupAccounts(uuid, accountGroupAccountsPaging);
            setPage(page);
        } catch (err) {
            console.error(`failed list accounts from group ${uuid}`, err);
        }
    }, [uuid])

    useAsyncEffect(async () => {
        try {
            const group = await getAccountGroup(uuid);
            setGroup(group);

            const accounts = await listAccounts(undefined, undefined, { facilityUuid: group.facility.uuid });
            setAccounts(accounts.content.map(a => ({ label: displayAccountNameLine(a), value: a.uuid })));

            if (authStore.isRoot) {
                listDevices(undefined, undefined, { facilityUuid: group.facility.uuid })
                    .then(p => setDevices(p.content))
                    .catch(console.error);
            } else {
                listDevices()
                    .then(p => setDevices(p.content))
                    .catch(console.error)
            }
        } catch (err) {
            console.error('failed to get account group', err);
        }

        await fetchGroupAccounts();
    }, [uuid])


    async function handlePagingChange(pageable: PagingSettings<any>) {
        accountGroupAccountsPaging.updateSettings(pageable);
        setPageable(pageable);
        await fetchGroupAccounts();
    }

    async function handleOnDelete() {
        if (group) {
            try {
                await deleteAccountGroup(group.uuid);
                navigate('/accounts/groups');
            } catch (err) {
                console.error('failed to delete group', err);
            }
        }
    }

    function handleAddToList() {
        if (selectAccount) {
            setSelectList(selectList.concat(selectAccount));
        }
    }

    async function handleAddAccount() {
        if (group && selectList.length > 0) {
            try {
                await assignAccountsToGroup(group.uuid, selectList);
                setAccountAddDialog(false);
                setAccountRemoveDialog(false);
                setSelectAccount(undefined);
                setSelectList([]);
                await fetchGroupAccounts();
            } catch (err) {
                console.error('failed to assign accounts to group', err);
            }
        }
    }

    async function handleRemoveAccount() {
        if (group && selectList.length > 0) {
            try {
                await removeAccountsFromGroup(group.uuid, selectList);
                setAccountAddDialog(false);
                setAccountRemoveDialog(false);
                setSelectAccount(undefined);
                setSelectList([]);
                await fetchGroupAccounts();
            } catch (err) {
                console.error('failed to remove accounts from group', err);
            }
        }
    }

    const isCheck = (deviceId: string) => {
        if (group) {
            const item = group.devicePermissions.find(i => i.device.id === deviceId);
            if (item) return item.enabled;
        }
        return false;
    }

    const handleDeviceToggle = async (deviceId: string, enabled: boolean) => {
        if (group) {
            await updateAccountGroupDevicePermission(group.uuid, deviceId, enabled);
            const newData = await getAccountGroup(group.uuid);
            setGroup(newData);
        }
    }

    return (
        <Fragment>
            <ContentTitle title={m.accounts.groups} />
            <AppBreadcrumbs>
                <Crumb title={m.accounts.plural} path="/accounts" />
                <Crumb title={m.accounts.groups} path="/accounts/groups" />
                <Crumb title={m.actions.view} />
            </AppBreadcrumbs>
            <Box my={3} />
            {group && (
                <Fragment>
                    {!editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={12} sm={12}>
                                        <LabeledText label={m.common.name}>
                                            {group.name}&nbsp;{group.defaultOnSignup && <Chip color="primary" size="small" label={m.common.default} />}
                                        </LabeledText>
                                    </Grid>
                                    {authStore.isRoot && (
                                        <Grid item lg={6} md={12} sm={12}>
                                            <LabeledText label={m.facilities.singular}>
                                                {group.facility.name}
                                            </LabeledText>
                                        </Grid>
                                    )}
                                </Grid>
                                <Box my={3} />
                                <Grid container spacing={3}>
                                    <Grid item md={6} sm={12}>
                                        <LabeledText label={m.accounts.expirationDate}>
                                            {group.expirationDate ? displayDate(group.expirationDate) : '-'}
                                        </LabeledText>
                                    </Grid>
                                    <Grid item md={3} sm={12}>
                                        <LabeledText label={m.accounts.startHour}>
                                            {group.startHour ?? '-'}
                                        </LabeledText>
                                    </Grid>
                                    <Grid item md={3} sm={12}>
                                        <LabeledText label={m.accounts.endHour}>
                                            {group.endHour ?? '-'}
                                        </LabeledText>
                                    </Grid>
                                </Grid>
                                <Box my={3} />
                                <Grid container spacing={1} sx={{ justifyContent: 'flex-end' }}>
                                    {/*<Grid item>*/}
                                    {/*    <Button variant="contained" color="primary"*/}
                                    {/*            disableElevation startIcon={<CheckListIcon />}*/}
                                    {/*            onClick={() => setPermissionDialog(true)}*/}
                                    {/*    >*/}
                                    {/*        {m.accounts.usePermissionGroup}*/}
                                    {/*    </Button>*/}
                                    {/*</Grid>*/}
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                                disableElevation startIcon={<GroupAddIcon />}
                                                onClick={() => setAccountAddDialog(true)}
                                        >
                                            {m.accounts.addAccounts}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                                disableElevation startIcon={<GroupRemoveIcon />}
                                                onClick={() => setAccountRemoveDialog(true)}
                                        >
                                            {m.accounts.removeAccounts}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" color="primary"
                                                startIcon={<EditIcon/>}
                                                onClick={() => setEditMode(true)}
                                        >
                                            {m.actions.edit}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ContentBody>
                            <Box my={3} />
                            <ContentBody>
                                <Box px={2} py={1}>
                                    <Typography variant="subtitle1">{m.devices.plural}</Typography>
                                </Box>
                                <Divider />
                                <Table size="small">
                                    <TableBody>
                                        {devices.map(d => (
                                            <TableRow key={d.id}>
                                                <TableCell>{d.name}</TableCell>
                                                <TableCell>{d.type}</TableCell>
                                                <TableCell>{d.description}</TableCell>
                                                {(authStore.isAdmin || authStore.isRoot) && (
                                                    <TableCell align="right">
                                                        <SwitchInput checked={isCheck(d.id)} onChange={() => handleDeviceToggle(d.id, !isCheck(d.id))} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ContentBody>
                            <Box my={3} />
                            <ContentTable page={page}
                                          pageable={pageable}
                                          columns={columns}
                                          onPageableChange={handlePagingChange}
                                          renderTableBody={(
                                              <Fragment>
                                                  {page && page.content.map(a => (
                                                      <TableRow key={a.uuid} onClick={() => navigate('/accounts/' + a.uuid)}>
                                                          <TableCell>{displayAccountNameLine(a)}</TableCell>
                                                          <TableCell>{a.email}</TableCell>
                                                          <TableCell>{a.address ? displayAddressLine(a.address) : ''}</TableCell>
                                                      </TableRow>
                                                  ))}
                                              </Fragment>
                                          )}
                            />
                        </Fragment>
                    )}
                    {editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <AccountGroupForm accountGroup={group}
                                                  onSaved={g => {
                                                      setGroup(g);
                                                      setEditMode(false);
                                                  }}
                                                  onCancel={() => setEditMode(false)}
                                />
                            </ContentBody>
                            <Box my={3} />
                            {(authStore.isAdmin || authStore.isRoot) && (
                                <DeletePrompt title={m.accounts.deleteGroup.title}
                                              message={m.accounts.deleteGroup.message}
                                              onDelete={handleOnDelete}
                                />
                            )}
                        </Fragment>
                    )}
                    <Dialog open={accountAddDialog || accountRemoveDialog} fullWidth maxWidth="md">
                        <DialogTitle>{accountAddDialog ? m.accounts.addAccounts : m.accounts.removeAccounts}</DialogTitle>
                        <DialogContent>
                            <Box my={1} />
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <SelectInput label={m.accounts.plural}
                                                 options={accounts.filter(a => selectList.indexOf(a.value) === -1)}
                                                 value={selectAccount}
                                                 onChange={setSelectAccount}
                                                 searchable
                                    />
                                    <Box my={1} />
                                    <Button size="small" variant="outlined" onClick={handleAddToList}>{m.actions.select}</Button>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Paper>
                                        <List disablePadding>
                                            {selectList.map(a => {
                                                const account = accounts.find(acc => acc.value === a)?.label || a;
                                                return (
                                                    <ListItem key={a}
                                                              secondaryAction={<IconButton size="small" edge="end" onClick={() => setSelectList(selectList.filter(i => i !== a))}><CancelIcon /></IconButton>}
                                                    >
                                                        <ListItemText primary={account} />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" disableElevation disabled={selectList.length === 0} onClick={accountAddDialog ? handleAddAccount : handleRemoveAccount}>{m.actions.save}</Button>
                            <Button onClick={() => {
                                setAccountAddDialog(false);
                                setAccountRemoveDialog(false);
                            }}>{m.actions.cancel}</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
        </Fragment>
    )
}
