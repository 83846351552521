import XLSX, {WorkSheet} from "xlsx";
import {AccountFacilityOverview, Facility, WalletType} from "../../data/types";
import {authStore} from "../../store";
import {displayAccountNameLine, displayAddressLine, displayDateTime} from "../../utils";
import {formatAmount} from "../../tools";
import {computeColumnLengths, formatCellAsNumber} from "./excels";

interface FacilityOverview {
    uuid: string;
    name: string;
    accounts: AccountFacilityOverview[];
}

export function exportAllFacilitiesAccountsExcel(accounts: AccountFacilityOverview[]) {
    const overviews: Map<string, FacilityOverview> = new Map();
    for (let account of accounts) {
        if (!overviews.has(account.facilityUuid)) {
            overviews.set(account.facilityUuid, { uuid: account.facilityUuid, name: account.facilityName, accounts: [account] });
        } else {
            overviews.get(account.facilityUuid)?.accounts.push(account);
        }
    }

    const workbook = XLSX.utils.book_new();
    for (let [uuid, { name, accounts}] of overviews.entries()) {
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.book_append_sheet(workbook, worksheet, name.substring(0, 30));

        XLSX.utils.sheet_add_aoa(worksheet, [['Konten']], { origin: 'A1' });
        XLSX.utils.sheet_add_aoa(worksheet, [['Club: ' + name]], { origin: 'A2' });

        makeRow(worksheet, uuid, name, accounts);
    }

    XLSX.writeFile(workbook, `Konten Alle.xlsx`, { compression: true });
}

export function exportAccountExcel(facility: Facility, accounts: AccountFacilityOverview[]) {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheet, '');

    makeRow(worksheet, facility.uuid, facility.name, accounts);

    XLSX.writeFile(workbook, `Konten - ${facility.name}.xlsx`, { compression: true });
}

function makeRow(worksheet: WorkSheet, facilityUuid: string, facilityName: string, accounts: AccountFacilityOverview[]) {
    XLSX.utils.sheet_add_aoa(worksheet, [['Konten']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(worksheet, [['Club: ' + facilityName]], { origin: 'A2' });

    const headers = ['#', 'Name', 'Registriert am', 'Aktiv', 'Adresse', 'E-Mail', 'Telefonnummer', 'Kontostand (Club)', 'Kontostand (PINPOINT)'];
    let rows = accounts.map((af, i) => {
        const clubWallet = af.wallets.find(w => facilityUuid === w.facility?.uuid);
        const intlWallet = af.wallets.find(w => w.type === WalletType.International);
        return {
            'no': i + 1,
            'name': displayAccountNameLine(af.account),
            'registeredAt': displayDateTime(af.createdAt),
            'active': af.enabled ? 'Ja' : 'Nein',
            'address': af.account.address ? displayAddressLine(af.account.address) : '-',
            'email': af.account.email,
            'phone': af.account.phone ?? '-',
            'clubBalance': clubWallet ? formatAmount(clubWallet.amount) : '',
            'intlBalance': intlWallet ? formatAmount(intlWallet.amount) : '',
        };
    });

    if (!authStore.isRoot) {
        headers.pop();
        rows = rows.map(r => {
            delete (r as any).intlBalance;
            return r;
        });
    }

    XLSX.utils.sheet_add_json(worksheet, rows, { origin: 'A5' });
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A5" });

    formatCellAsNumber(worksheet, 5, (!authStore.isRoot) ? 6 : 7, rows.length, '0.00');
    formatCellAsNumber(worksheet, 5, (!authStore.isRoot) ? 7 : 8, rows.length, '0.00');

    worksheet["!cols"] = computeColumnLengths(rows);
}