import {useAsync} from "react-async-hook";
import {getFacility, listFacilities} from "../data/facilities";
import {PagingImpl} from "../data/pagings";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Autocomplete, Chip, TextField} from "@mui/material";
import {Facility, Page} from "../data/types";
import _ from "lodash";
import {TextInput} from "./TextInput";
import {Loading} from "./Loading";
import {authStore} from "../store";
import {useMessages} from "../i18n";

const paging = new PagingImpl(null, true);

interface FacilitySelectProps {
    value?: string;
    onChange: (facility?: Facility) => void;
    disabled?: boolean;
}

export function FacilitySelect({value, onChange, disabled}: FacilitySelectProps) {
    const m = useMessages();


    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [needle, setNeedle] = useState("");

    const {loading, error, result} = useAsync(async () => {
        if (authStore.isRoot) return listFacilities(paging, needle);
    }, [needle]);

    const {result: facility, set} = useAsync(async () => {
        if (authStore.isRoot && !!value) return getFacility(value);
    }, [])

    useEffect(() => {
        if (result) {
            setFacilities(result.content);
        }
    }, [result]);

    const setSearchDebounced = _.debounce((needle: string) => setNeedle(needle), 400);

    const handleChange = useCallback((event, facility) => {
        onChange(facility)
        set({
            status: 'success',
            loading: false,
            error: undefined,
            result: facility ?? null
        });
    }, []);

    return (
        <Autocomplete
            multiple={false}
            disabled={disabled}
            value={facility ?? null}
            onChange={handleChange}
            filterOptions={x => x}
            options={facilities}
            loading={loading}
            autoHighlight
            isOptionEqualToValue={o => o.uuid === value}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li key={'facility-' + option.uuid} {...props}>
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextInput
                    {...params}
                    label={m.facilities.singular}
                    onChange={setSearchDebounced}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <Loading type="circular" CircularProgressProps={{ size: 20 }} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}