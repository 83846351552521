import React, {Fragment, useMemo, useState} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import {DateTime} from "luxon";
import {useAsync} from "react-async-hook";
import {AccountTransaction, AccountTransactionDirection, TransactionFilter, Wallet, WalletType} from "../../data/types";
import {TransactionRow} from "../../component/TransactionRow";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {listWalletTransactions} from "../../data/wallets";
import {PagingImpl, PagingSettings} from "../../data/pagings";
import {useMessages} from "../../i18n";
import {SelectInput} from "../../component/SelectInput";
import {DateSelector} from "../../component/DateSelector";
import {ActionButton} from "../../component/buttons";
import {DownloadIcon} from "../../icons";
import {ContentBody} from "../../component/ContentLayout";
import {Loading} from "../../component/Loading";
import {exportWalletTransactionExcel} from "../../tools/export";


const defaultFilter: TransactionFilter = {
    timeframe: {
        from: DateTime.now().startOf('month'),
        until: DateTime.now().endOf('month')
    }
};
const transactionPaging = new PagingImpl('', true);

export function WalletTransactions({wallet}: { wallet: Wallet }) {
    const m = useMessages();

    const [filter, setFilter] = useState<TransactionFilter>(defaultFilter);
    const [exportMode, setExportMode] = useState(false);
    const [exportProgress, setExportProgress] = useState<{ total: number, fetched: number }>();
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(transactionPaging.getSettings());

    const {result: transactions, execute} = useAsync(async () => listWalletTransactions(wallet.uuid, transactionPaging, filter), [wallet])

    const columns = useMemo(() => {
        const cols: ColumnType = {
            'timestamp': { name: m.common.timestamp },
            'clearedAt': { name: m.wallets.clearedAt },
            'type': { name: m.common.type },
            'device.name': { name: m.devices.singular },
            'user.firstName&user.lastName': { name: m.wallets.clerk },
            'transactionAmount': { name: m.common.amount, align: 'right' },
            'newAmount': { name: m.wallets.newAmount, align: 'right' }
        };
        if (wallet && wallet.type === WalletType.International) delete cols['user.firstName&user.lastName'];
        return cols;
    }, [wallet]);
    const exportPercent = useMemo(() => {
        if (!exportProgress) return 0;
        else if (exportProgress.total === 0) return 0;
        return exportProgress.fetched * 100 / exportProgress.total;
    }, [exportProgress]);

    async function handlePagingChange(pageable: PagingSettings<any>) {
        transactionPaging.updateSettings(pageable);
        setPageable(pageable);
        await execute();
    }

    async function handleFilterSubmit() {
        transactionPaging.resetSettings();
        await execute();
    }

    async function handleClearFilter() {
        transactionPaging.resetSettings();
        setFilter({ timeframe: {} });
        await execute();
    }

    async function handleExport() {
        const {timeframe} = filter;
        if (wallet && timeframe) {
            // setError(undefined);
            setExportMode(true);

            const data: AccountTransaction[] = [];

            const paging = new PagingImpl('export_wallet_transactions', true);
            paging.updateSettings({ ...paging.getSettings(), pageSize: 100 })

            let page;
            try {
                page = await listWalletTransactions(wallet.uuid, paging, filter);
                data.push(...page.content);
            } catch (err) {
                console.error('failed to export transaction', err);
                // setError(err as any);
                return;
            }

            const maxPage = page.totalPages;
            setExportProgress({ total: maxPage, fetched: 1 });
            for (let i = 1; i <= maxPage; i++) {
                paging.updateSettings({ ...paging.getSettings(), pageNumber: i });
                try {
                    page = await listWalletTransactions(wallet.uuid, paging, filter);
                    data.push(...page.content);
                    setExportProgress({ total: maxPage, fetched: i });
                } catch (err) {
                    console.error('failed to export transaction', err);
                    // setError(err as any);
                    return;
                }
            }

            exportWalletTransactionExcel(wallet, data);
            setExportMode(false);
            setExportProgress(undefined);
        }
    }

    return (
        <Fragment>
            <ContentBody padding={2}>
                <Grid container spacing={3}>
                    <Grid item sm={4} xs={12}>
                        <SelectInput
                            label={m.common.direction}
                            options={[
                                { label: m.common.all, value: '-' },
                                { label: m.transactions.direction.BookIn, value: AccountTransactionDirection.BookIn },
                                { label: m.transactions.direction.BookOut, value: AccountTransactionDirection.BookOut }
                            ]}
                            value={filter.direction || '-'}
                            onChange={v => setFilter({ ...filter, direction: (v === '-' ? undefined : v) })}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <DateSelector
                            label={m.common.timeframe.from}
                            date={filter.timeframe?.from}
                            onChange={d => setFilter({ ...filter, timeframe: { ...filter.timeframe, from: d || undefined }})}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <DateSelector
                            label={m.common.timeframe.until}
                            date={filter.timeframe?.until}
                            onChange={d => setFilter({ ...filter, timeframe: { ...filter.timeframe, until: d || undefined }})}
                        />
                    </Grid>
                </Grid>
                <Box my={3} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <ActionButton color="primary" onClick={handleFilterSubmit}>{m.actions.view}</ActionButton>
                    <Box mx={1} />
                    <ActionButton onClick={handleClearFilter}>{m.actions.clearFilter}</ActionButton>
                    <Box mx={1} />
                    <ActionButton
                        color="secondary"
                        disabled={!filter.timeframe?.until || !filter.timeframe.from}
                        startIcon={<DownloadIcon />}
                        onClick={handleExport}
                    >
                        {m.actions.export}</ActionButton>
                </Box>
            </ContentBody>
            <Box my={3} />
            <ContentTable
                page={transactions}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {transactions && transactions.content.map(t => <TransactionRow key={t.uuid} transaction={t} />)}
                    </Fragment>
                )}
            />
            <Dialog open={exportMode} fullWidth maxWidth="sm">
                <DialogTitle>Export</DialogTitle>
                <DialogContent>
                    {exportPercent && (
                        <Fragment>
                            <Typography>{m.common.wait}</Typography>
                            <Box my={1} />
                            <Loading
                                type="linear"
                                LinearProgressProps={{
                                    variant: 'determinate',
                                    value: exportPercent
                                }}
                            />
                        </Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        variant="text"
                        onClick={() => setExportMode(false)}
                    >
                        {m.actions.cancel}
                    </ActionButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}