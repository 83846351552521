import {useNavigate} from "react-router";
import React, {useEffect} from "react";

export function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/dashboard', { replace: true });
    }, []);

    return <div />;
}