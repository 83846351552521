import XLSX, {CellObject, WorkSheet} from "xlsx";
import {AccountTransactionType} from "../../data/types";

export function computeColumnLengths(rows: any) {
    let objectMaxLength: number[] = [];
    for (let i = 0; i < rows.length; i++) {
        let value = <any>Object.values(rows[i]);
        for (let j = 0; j < value.length; j++) {
            if (!!value[j]) {
                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                }
            }
        }
    }
    return objectMaxLength.map(width => ({ wch: width + 5 }));
}

export function getTypeName(type: AccountTransactionType) {
    switch (type) {
        case AccountTransactionType.PurchaseCredit:
            return 'Aufladen';
        case AccountTransactionType.ReceiveCredit:
            return 'Eingehend';
        case AccountTransactionType.ConsumeCredit:
            return 'Abzug';
        case AccountTransactionType.SubtractCredit:
            return 'Ausgaben';
    }
    return type;
}

export function formatCellAsNumber(worksheet: WorkSheet, row: number, col: number, rowIncrement: number, format?: string) {
    for (let i = 0; i < rowIncrement; i++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row + i, c: col })] as CellObject|null;
        if (cell) {
            console.log('cell v', cell.v);
            cell.t = "n";
            if (format) cell.z = format;
        }
    }
}
