import React, {CSSProperties, ReactElement} from "react";
import {theme} from "../theme";
import {green} from "@mui/material/colors";
import { Box } from "@mui/material";

type BadgeProps = {
    label: string,
    color?: 'success',
    icon?: ReactElement,
    size?: 'small',
    style?: CSSProperties
}

export const Badge = ({label, color, icon, size, style}: BadgeProps) => {
    let badgeSx: any = {
        display: 'flex',
        fontFamily: 'monospace',
        fontSize: '0.85rem',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '1em',
        color: '#fff',
        padding: '6px 10px',
        borderRadius: '5px',
        background: theme.palette.primary.main,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 30
    };
    if ('success' === color) badgeSx = { ...badgeSx, background: green[500] };
    if ('small' === size) {
        badgeSx = {
            ...badgeSx,
            ...{
                minHeight: 20,
                padding: '7px 15px',
                fontSize: '0.75rem'
            }
        };
    }

    return (
        <Box component="div" sx={badgeSx} style={style}>
            {icon && <Box component="div" sx={{
                fontSize: '1.2rem',
                marginRight: theme.spacing(1)
            }}>{icon}</Box>}
            {label}
        </Box>
    );
}