import React, {Fragment} from "react";
import {Address} from "../data/types";
import {displayAddressLine} from "../utils";

export const AddressDisplay = ({address, singleLine}: { address: Address, singleLine?: boolean }) => {
    if (singleLine) {
        return (<Fragment>{displayAddressLine(address)}</Fragment>);
    }
    return (
        <Fragment>
            {address.addressLine1 ?? ''}
            {address.addressLine2 && (
                <Fragment>
                    <br/>
                    {address.addressLine2}
                </Fragment>
            )}
            <br/>
            {address.zipCode ?? ''} {address.city ?? ''}
        </Fragment>
    )
}