import {Paging} from "./pagings";
import {Api} from "./Api";
import {Notice, NoticeFilter, NoticeRequest, Page} from "./types";

export function listNotices(paging: Paging, needle?: string, filter?: NoticeFilter) {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
        if (filter.published) queries.push('published=' + filter.published);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    return Api.GET<Page<Notice>>('/notices' + paging.toQueryString(prefix))
}

export function getNotice(uuid: string) {
    return Api.GET<Notice>('/notices/' + uuid);
}

export function createNotice(request: NoticeRequest) {
    return Api.POST<Notice>('/notices', request);
}

export function updateNotice(uuid: string, request: NoticeRequest) {
    return Api.PUT<Notice>('/notices/' + uuid, request);
}

export function publishNotice(uuid: string) {
    return Api.PUT<Notice>('/notices/' + uuid + '/publish', {});
}

export async function deleteNotice(uuid: string) {
    await Api.DELETE('/notices/' + uuid);
}