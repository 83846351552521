import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Badge as MBadge
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {listFacilities} from "../../data/facilities";
import {facilityPaging, PagingSettings} from "../../data/pagings";
import {Facility, FacilityFilter, FacilityType, Page} from "../../data/types";

import {uiStore} from "../../store";
import {useMessages} from "../../i18n";
import {FilterIcon, PlusIcon} from "../../icons";
import {Badge} from "../../component/Badge";
import {SearchInput} from "../../component/SearchInput";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {SelectInput} from "../../component/SelectInput";
import {LabeledBoolean} from "../../component/LabeledBoolean";
import {CountrySelector} from "../../component/CountrySelector";
import {FacilityTypeBadge} from "../../component/FacilityTypeBadge";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {AppBreadcrumbs, AppFab, ContentTitle, Crumb} from "../../component/ContentLayout";
import {FacilityLogo} from "../../component/FacilityLogo";

export const FacilityList = () => {
    const navigate = useNavigate();
    const m = useMessages();

    const columns: ColumnType = {
        'lta.land&lta.type&lta.clubNr': { name: 'LTA' },
        "type": { name: m.common.type },
        'active': { name: m.common.status, align: 'center' },
        'name': { name: m.common.name },
        'contactPerson': { name: m.common.contactDetails },
        'contactPhone': { name: m.common.phone },
        'address.country': { name: m.common.address.country, align: 'right' },
    }

    const [page, setPage] = useState<Page<Facility>>();
    const [needle, setNeedle] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<FacilityFilter>(uiStore.getFacilityFilter() || {});
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(facilityPaging.getSettings());
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {status, country, type} = filter;
        return (!!status && status != 'ALL') || (!!type && type.toString() != 'ALL') || (!!country && country != 'ALL');
    }, [filter])

    const fetch = useCallback((needle?: string, filter?: FacilityFilter) => {
        listFacilities(facilityPaging, needle, filter)
            .then(setPage)
            .catch(setError);
    }, [setPage, setError]);

    useEffect(() => {
        fetch(needle, filter);
    }, []);

    const handleNeedleChange = (needle: string) => {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        facilityPaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        fetch(needle, filter);
    }

    const handlePagingChange = (pageable: PagingSettings<any>) => {
        facilityPaging.updateSettings(pageable);
        setPageable(pageable);
        fetch(needle, filter);
    }

    const handleFilterSubmit = (f?: FacilityFilter) => {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        facilityPaging.updateSettings(adjusted)
        setPageable(adjusted);
        uiStore.setFacilityFilter(f);
        fetch(needle, f);
        setShowFilter(false);
    }

    const resetFilter = () => {
        setFilter({});
        handleFilterSubmit({});
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.facilities.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.facilities.plural} path="/facilities" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <SearchInput onSubmit={handleNeedleChange} />
                    <Box mx={1} />
                    <IconButton onClick={() => setShowFilter(true)}>
                        <MBadge color="primary" variant="dot" invisible={!filterActive}>
                            <FilterIcon />
                        </MBadge>
                    </IconButton>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable page={page}
                          pageable={pageable}
                          columns={columns}
                          onPageableChange={handlePagingChange}
                          renderTableBody={(
                              <Fragment>
                                  {page && page.content.map(f => (
                                      <TableRow key={f.uuid} onClick={() => navigate('/facilities/' + f.uuid)}>
                                          <TableCell>
                                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                  <FacilityLogo facilityUuid={f.uuid} allowEdit={false} size="small" />
                                                  <Box mx={1} />
                                                  <Badge label={f.lta.land + '/' + f.lta.type + '/' + f.lta.clubNr} />
                                              </Box>
                                          </TableCell>
                                          <TableCell><FacilityTypeBadge type={f.type} /></TableCell>
                                          <TableCell><LabeledBoolean value={f.enabled} centered /></TableCell>
                                          <TableCell>{f.name}</TableCell>
                                          <TableCell>{f.contactPerson ?? '-'}</TableCell>
                                          <TableCell>{f.contactPhone ?? '-'}</TableCell>
                                          <TableCell align="right">{f.address?.country ?? '-'}</TableCell>
                                      </TableRow>
                                  ))}
                              </Fragment>
                          )}
            />
            <AppFab onClick={() => navigate('/facilities/create')} children={<PlusIcon />}/>
            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <SelectInput label={m.common.status}
                                         options={[
                                             { label: m.common.all, value: 'ALL' },
                                             { label: m.common.active, value: 'ACTIVE' },
                                             { label: m.common.inactive, value: 'INACTIVE' },
                                         ]}
                                         value={filter.status || 'ALL'}
                                         onChange={status => setFilter({ ...filter, status })}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <SelectInput label={m.common.type}
                                         options={[
                                             { label: m.common.all, value: 'ALL' },
                                             ...Object.values(FacilityType).map(t => ({
                                                 label: m.facilities.types[t], value: t
                                             }))
                                         ]}
                                         value={filter.type || 'ALL'}
                                         onChange={type => setFilter({ ...filter, type })}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <CountrySelector countryCode={filter.country || 'ALL'}
                                             onChange={cc => setFilter({ ...filter, country: cc })}
                                             allowAll
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
};