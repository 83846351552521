import {Api} from "./Api";
import {Paging} from "./pagings";
import {
    Facility,
    FacilityCreateRequest, FacilityFilter,
    FacilityTransactionBookmark,
    FacilityUpdateRequest,
    Page
} from "./types";

export async function listFacilities(paging?: Paging, needle?: string, filter?: FacilityFilter) {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.status) queries.push('status=' + filter.status);
        if (filter.type) queries.push('type=' + filter.type);
        if (filter.country) queries.push('country=' + filter.country);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    let p = await Api.GET<Page<Facility>>('/facilities' + (paging ? paging.toQueryString(prefix) : (prefix ? prefix + '&unpaged=true' : '?unpaged=true')));
    return ({
        ...(p),
        content: p.content.map(mapFacility)
    }) as Page<Facility>;
}

export async function getFacility(uuid: string) {
    let facility = await Api.GET<Facility>('/facilities/' + uuid);
    return mapFacility(facility);
}

export async function createFacility(request: FacilityCreateRequest) {
    let facility = await Api.POST<Facility>('/facilities', request);
    return mapFacility(facility);
}

export async function updateFacility(uuid: string, request: FacilityUpdateRequest) {
    let facility = await Api.PUT<Facility>(`/facilities/${uuid}`, request);
    return mapFacility(facility);
}

export async function updateFacilityStatus(uuid: string, enabled: boolean) {
    let facility = await Api.PUT<Facility>(`/facilities/${uuid}/status?enabled=` + (enabled ? 1 : 0), {});
    return mapFacility(facility);
}

export async function updateFacilityLogo(uuid: string, logoData: string) {
    let facility = await Api.PUT<Facility>(`/facilities/${uuid}/logo`, {logoData});
    return mapFacility(facility);
}

export async function deleteFacility(uuid: string) {
    await Api.DELETE(`/facilities/${uuid}`);
}

export async function updateFacilityTransactionBookmarks(facilityUuid: string, request: FacilityTransactionBookmark[]) {
    return Api.PUT<FacilityTransactionBookmark[]>(`/facilities/${facilityUuid}/transaction-bookmarks`, request);
}

export async function listFacilityTransactionBookmarks(facilityUuid: string) {
    return Api.GET<FacilityTransactionBookmark[]>(`/facilities/${facilityUuid}/transaction-bookmarks`);
}

// ADMIN API
export async function getCurrentFacility() {
    let facility = await Api.GET<Facility>('/facilities');
    return mapFacility(facility);
}

export async function updateCurrentFacility(request: FacilityUpdateRequest) {
    let facility = await Api.PUT<Facility>('/facilities', request);
    return mapFacility(facility);
}

export async function updateCurrentFacilityTransactionBookmarks(request: FacilityTransactionBookmark[]) {
    return Api.PUT<FacilityTransactionBookmark[]>(`/facilities/transaction-bookmarks`, request);
}

export async function listCurrentFacilityTransactionBookmarks() {
    return Api.GET<FacilityTransactionBookmark[]>('/facilities/transaction-bookmarks');
}

function mapFacility(facility: Facility): Facility {
    return {
        ...facility,
        logoBase64Data: facility.logoBase64Data ? window.atob(facility.logoBase64Data) : undefined
    }
}
