import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {useMessages} from "../../i18n";

import {listFacilities} from "../../data/facilities";
import {User, UserCreateRequest, UserRole, UserUpdateRequest} from "../../data/types";
import {SaveIcon} from "../../icons";
import {TextInput} from "../../component/TextInput";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {authStore} from "../../store";
import useAsyncEffect from "use-async-effect";

type UserFormProps = {
    inProgress: boolean,
    onCancel: () => void,
    user?: User,
    onCreate?: (form: UserCreateRequest) => void,
    onSave?: (form: UserUpdateRequest) => void
}

export const UserForm = ({inProgress, onCancel, user, onCreate, onSave}: UserFormProps) => {
    const [createForm, setCreateForm] = useState<UserCreateRequest>({ email: '', role: UserRole.User });
    const [updateForm, setUpdateForm] = useState<UserUpdateRequest|undefined>(user);
    const [facilities, setFacilities] = useState<SelectOption[]>([]);

    const getForm = useMemo(() => updateForm ? updateForm : createForm, [updateForm, createForm]);
    const setForm = useMemo(() => updateForm ? setUpdateForm : setCreateForm, [updateForm]);
    const canSubmit = useMemo(() => {
        if (inProgress) return false;
        const facilityOk = authStore.isRoot ? !!createForm.facilityUuid : true;
        return (user || (!user && !!createForm.email && facilityOk));
    }, [inProgress, user, createForm]);

    useAsyncEffect(async () => {
        if (authStore.isRoot) {
            try {
                const page = await listFacilities();
                setFacilities(page.content.map(f => ({ label: f.name, value: f.uuid })));
            } catch (err) {
                console.error('failed to list facilities', err);
            }
        }
    }, []);

    const onFormChange = (name: string, value: any) => {
        setForm({
            ...getForm as any,
            [name]: value
        });
    }

    const submit = () => {
        if (user && updateForm) {
            if (onSave) onSave(updateForm);
        } else {
            if (onCreate) onCreate(createForm)
        }
    }

    const userRoles = authStore.isRoot ?
        [
            { label: UserRole.User, value: UserRole.User },
            { label: UserRole.Admin, value: UserRole.Admin },
            { label: UserRole.Root + ' - VORSICHT!', value: UserRole.Root }
        ] :
        [
            { label: UserRole.User, value: UserRole.User },
            { label: UserRole.Admin, value: UserRole.Admin }
        ]

    const m = useMessages();
    return (
        <Fragment>
            <Typography variant="h6">{m.common.identity}</Typography>
            <Box my={2} />
            {!user && authStore.isRoot && (
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <SelectInput label={m.facilities.singular}
                                     options={facilities}
                                     value={createForm.facilityUuid ?? ''}
                                     onChange={v => onFormChange('facilityUuid', v)}
                                     searchable
                                     error={!createForm.facilityUuid}
                                     helperText={!createForm.facilityUuid ? m.error.requiredField : undefined}
                        />
                    </Grid>
                </Grid>
            )}
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                    <TextInput label={m.common.title}
                               value={getForm.title ?? ''} onChange={v => onFormChange('title', v)}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextInput label={m.common.firstName}
                               value={getForm.firstName ?? ''} onChange={v => onFormChange('firstName', v)}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextInput label={m.common.lastName}
                               value={getForm.lastName ?? ''} onChange={v => onFormChange('lastName', v)}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <SelectInput label={m.users.role}
                                 options={userRoles}
                                 value={getForm.role}
                                 onChange={v => onFormChange('role', v)}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextInput label={m.users.position}
                               value={getForm.position ?? ''} onChange={v => onFormChange('position', v)}
                    />
                </Grid>
            </Grid>

            <Box my={3}><Divider/></Box>

            <Typography variant="h6">{m.common.contactDetails}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.email}
                               value={getForm.email ?? ''}
                               onChange={v => onFormChange('email', v)}
                               error={!getForm.email}
                               helperText={!getForm.email ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput label={m.common.phone}
                               value={getForm.phone ?? ''}
                               onChange={v => onFormChange('phone', v)}
                    />
                </Grid>
            </Grid>

            <Box my={3} />

            <Box display="flex" justifyContent="flex-end">
                <Button disableElevation variant="contained" color="primary" size="medium"
                        disabled={!canSubmit} startIcon={<SaveIcon/>}
                        onClick={submit}>{updateForm ? m.actions.save : m.actions.create}</Button>
                <Box mx={1}/>
                <Button size="medium" onClick={onCancel}>{m.actions.cancel}</Button>
            </Box>
        </Fragment>
    )
}