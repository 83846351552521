import React from "react";
import {Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import {theme} from "../theme";
import {CheckIcon, UncheckIcon} from "../icons";

export const LabeledBoolean = ({label, value, centered}: { label?: string, value: boolean, centered?: boolean }) => {
    let contentSx: any = {
        display: 'flex',
        alignItems: 'center'
    }
    let iconSx: any = {
        marginRight: theme.spacing(1),
        color: theme.palette.grey[100]
    }
    if (centered) contentSx = { ...contentSx, justifyContent: 'center' };
    if (value) iconSx = { ...iconSx, color: green[500] };

    return (
        <Typography variant="body1" component="div" sx={contentSx}>
            {value ? <CheckIcon sx={iconSx} /> : <UncheckIcon sx={iconSx} />} {label ?? ''}
        </Typography>
    )
}