import React, {PropsWithChildren} from "react";
import {Typography} from "@mui/material";

export const LabeledText = ({label, align, children}: PropsWithChildren<{ label: string, align?: 'right' }>) => {
    return (
        <div>
            <Typography variant="overline"><strong>{label}</strong></Typography>
            <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: align === 'right' ? 'flex-end' : undefined }}>
                {children}
            </Typography>
        </div>
    )
}