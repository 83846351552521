import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, FormControlLabel,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {deleteDevice, getDevice, updateDevice, updateDevicePort} from "../../data/devices";
import {
    Device,
    DevicePortRequest,
    DeviceSwitchingTimeUnit,
    DeviceType,
    DeviceUpdateRequest,
    EditFormProps
} from "../../data/types";
import {EditIcon} from "../../icons";
import {theme} from "../../theme";
import {useMessages} from "../../i18n";
import {Badge} from "../../component/Badge";
import {TextInput} from "../../component/TextInput";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {LabeledText} from "../../component/LabeledText";
import {LabeledBoolean} from "../../component/LabeledBoolean";
import {DeviceTypeBadge} from "../../component/DeviceTypeBadge";
import {TaxedAmountEditor} from "../../component/TaxedAmountEditor";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {DeviceForm} from "./DeviceForm";
import {SwitchInput} from "../../component/SwitchInput";
import {SelectInput} from "../../component/SelectInput";

export const DeviceDetails = () => {
    const {id = ''} = useParams();
    const [device, setDevice] = useState<Device>();
    const [editMode, setEditMode] = useState(false);
    const [portForm, setPortForm] = useState<DevicePortRequest>();
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getDevice(id)
            .then(setDevice)
            .catch(setError)
    }, []);

    const handleOnSave = (d: Device) => {
        setEditMode(false);
        setDevice(d);
    }

    const togglePortEdit = (port: number) => {
        if (device) {
            const portConfig = getPortConfig(device, port);
            if (portConfig) {
                setPortForm({
                    ...portConfig,
                    port,
                })
            }
        }
    }

    const handlePortSave = () => {
        if (device && portForm) {
            updateDevicePort(device.id, portForm)
                .then(d => {
                    setDevice(d);
                    setPortForm(undefined);
                })
                .catch(setError);
        }
    }

    const handlePortToggle = (port: number) => {
        if (device) {
            const portConfig = getPortConfig(device, port);
            if (portConfig) {
                updateDevicePort(device.id, {
                    ...portConfig,
                    active: !portConfig.active,
                    port,
                })
                    .then(d => {
                        setDevice(d);
                        setPortForm(undefined);
                    })
                    .catch(setError);
            }

        }
    }

    function getPortConfig(device: Device, port: number) {
        let portConfig = null;
        if (port === 0) portConfig = device.port0;
        if (port === 1) portConfig = device.port1;
        if (port === 2) portConfig = device.port2;
        if (port === 3) portConfig = device.port3;
        return portConfig;
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.devices.singular} />
            <AppBreadcrumbs>
                <Crumb title={m.devices.plural} path="/devices" />
                <Crumb title={m.actions.view} />
            </AppBreadcrumbs>
            <Box my={3} />
            {device && (
                <Fragment>
                    {editMode && (
                        <ContentBody padding={2}>
                            <Edit
                                data={device}
                                onCancel={() => setEditMode(false)}
                                onSave={handleOnSave}
                                onError={setError}
                            />
                        </ContentBody>
                    )}
                    {!editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Details device={device} onEdit={() => setEditMode(true)} />
                            </ContentBody>
                            <Box my={3} />
                            <ContentBody padding={2}>
                                <Typography variant="subtitle1"><strong>{m.devices.ports.plural}</strong></Typography>
                                <Box my={2} />
                                <Grid container spacing={2}>
                                    {[0,1,2,3].map(p => {
                                        const portConfig = getPortConfig(device, p);
                                        if (!portConfig) return <div />;
                                        return (
                                            <Grid key={'port-' + p} item md={3} sm={6} xs={12}>
                                                <Paper variant="outlined">
                                                    <Box p={2}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
                                                            <Typography sx={{ flexGrow: 1, fontSize: '1.1rem' }}><strong>{m.devices.ports.singular} #{p}</strong></Typography>
                                                            <SwitchInput checked={portConfig.active} onChange={() => handlePortToggle(p)} />
                                                        </Box>
                                                        <LabeledText label={m.common.description}>
                                                            {portConfig.name ?? '-'}
                                                        </LabeledText>
                                                        <LabeledText label={m.devices.switchingTimeDuration}>
                                                            {portConfig.switchingTimeDuration ?? 0} {portConfig.switchingTimeUnit}
                                                        </LabeledText>
                                                        <Box my={2} />
                                                        <Button variant="outlined" color="primary" size="small" onClick={() => togglePortEdit(p)}>{m.actions.edit}</Button>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </ContentBody>
                        </Fragment>
                    )}
                    <Dialog open={!!portForm} fullWidth maxWidth="sm">
                        <DialogTitle>Port #{portForm?.port}</DialogTitle>
                        <DialogContent>
                            {portForm && (
                                <Fragment>
                                    <Box my={1} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextInput label={m.common.description} value={portForm.name ?? ''} onChange={v => setPortForm({...portForm, name: v})} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item sm={8} xs={12}>
                                                    <TextInput label={m.devices.switchingTimeDuration} value={portForm.switchingTimeDuration ?? ''} onChange={v => setPortForm({...portForm, switchingTimeDuration: v})} />
                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <SelectInput label={m.common.unit}
                                                                 value={portForm.switchingTimeUnit}
                                                                 options={Object.values(DeviceSwitchingTimeUnit).map(u => ({ label: u, value: u }))}
                                                                 onChange={v => setPortForm({...portForm, switchingTimeUnit: v})}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {DeviceType.BallVendingMachine === device.type && (
                                            <Fragment>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item sm={8} xs={12}>
                                                            <TextInput label={m.devices.switchDelayDuration} value={portForm.switchDelayDuration ?? ''} onChange={v => setPortForm({...portForm, switchDelayDuration: v})} />
                                                        </Grid>
                                                        <Grid item sm={4} xs={12}>
                                                            <SelectInput label={m.common.unit}
                                                                         value={portForm.switchDelayUnit}
                                                                         options={Object.values(DeviceSwitchingTimeUnit).map(u => ({ label: u, value: u }))}
                                                                         onChange={v => setPortForm({...portForm, switchDelayUnit: v})}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TaxedAmountEditor taxedAmount={portForm.amount} compact fromGross onChange={amount => setPortForm({...portForm, amount})} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel label={m.devices.bulkPurchasing}
                                                                      control={<SwitchInput checked={portForm.bulkPurchasingEnabled} onChange={v => setPortForm({ ...portForm, bulkPurchasingEnabled: v })} />}
                                                    />
                                                    <Typography color="textSecondary">{m.devices.bulkPurchasingHint}</Typography>
                                                </Grid>
                                                {portForm.bulkPurchasingEnabled && (
                                                    <Grid item xs={12}>
                                                        <TextInput label={m.devices.bulkQuantity} value={portForm.bulkPurchasingQuantity ?? ''} onChange={v => setPortForm({...portForm, bulkPurchasingQuantity: v})} />
                                                    </Grid>
                                                )}
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Fragment>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
                                <Button variant="contained" color="primary" disableElevation onClick={handlePortSave}>{m.actions.save}</Button>
                                <Box mx={1} />
                                <Button onClick={() => setPortForm(undefined)}>{m.actions.cancel}</Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}

const Details = ({device, onEdit}: { device: Device, onEdit: () => void }) => {
    const m = useMessages();
    return (
        <Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column'
                }
            }}>
                <LabeledText label="ID">
                    <Badge label={device.id} />
                </LabeledText>
                <Box mx={2} my={1} />
                <LabeledText label={m.common.type}>
                    <DeviceTypeBadge type={device.type} />
                </LabeledText>
                <Box mx={2} my={1} />
                <LabeledText label={m.facilities.singular}>
                    {device.facility ? <Link to={'/facilities/' + device.facility.uuid}>{device.facility.name}</Link> : ''}
                </LabeledText>
                <Box mx={2} my={1} />
                <LabeledText label={m.devices.modelNumber}>
                    {device.modelNumber}
                </LabeledText>
                <Box mx={2} my={1} />
                <LabeledText label={m.devices.serialNumber}>
                    {device.serialNumber}
                </LabeledText>
            </Box>
            <Box my={2}>
                <Divider />
            </Box>
            <LabeledText label={m.common.name}>
                {device.name}
            </LabeledText>
            <Box my={2} />
            <LabeledText label={m.common.description}>
                {device.description ?? '-'}
            </LabeledText>
            <Box my={3} />
            <LabeledBoolean label={m.devices.enableRangefee}
                            value={device.rangefeeRequired}
            />
            <Box my={2} />
            <LabeledBoolean label={m.devices.allowOfflineService}
                            value={device.offlineServiceEnabled}
            />
            <Box my={2} />
            <LabeledBoolean label={m.devices.automaticallyOpenedOnConnection}
                            value={device.automaticallyOpenedOnConnection}
            />
            <Box my={1} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary"
                        startIcon={<EditIcon/>}
                        onClick={onEdit}>{m.actions.edit}</Button>
            </Box>
        </Fragment>
    )
}

const Edit = ({data, onSave, onError, onCancel}: EditFormProps<Device>) => {
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);

    async function update(form: DeviceUpdateRequest) {
        setInProgress(true);
        try {
            const result = await updateDevice(data.id, form);
            onSave(result);
            return;
        } catch (error) {
            onError(error as any);
        }
        setInProgress(false);
    }

    async function handleDelete(device: Device) {
        try {
            setInProgress(true);
            await deleteDevice(device.id);
            navigate('/devices');
        } catch (err) {
            console.log('failed to delete device', err);
            onError(err as any);
        } finally {
            setInProgress(false);
        }
    }

    return (
        <DeviceForm device={data}
                    inProgress={inProgress}
                    onCancel={onCancel}
                    onSave={update}
                    onDelete={handleDelete}
        />
    )
}