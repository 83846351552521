import React, {Fragment, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useMessages} from "../i18n";
import {CancelIcon, SearchIcon} from "../icons";

type SearchInputProps = {
    label?: string;
    onSubmit: (needle: string) => void;
}

export const SearchInput = ({label, onSubmit}: SearchInputProps) => {
    const [needle, setNeedle] = useState('');

    const handleReset = () => {
        setNeedle('');
        onSubmit('');
    }

    const m = useMessages();
    return (
        <TextField variant="outlined" size="small"
                   label={label || m.search}
                   value={needle} onChange={e => setNeedle(e.target.value)}
                   InputProps={{
                       endAdornment: (
                           <Fragment>
                               {needle.length > 0 && (<InputAdornment position="end"><IconButton disableRipple sx={{ padding: 0 }} onClick={handleReset}><CancelIcon fontSize="inherit" /></IconButton></InputAdornment>)}
                               <InputAdornment position="end"><IconButton disableRipple sx={{ padding: 0 }} onClick={() => onSubmit(needle)}><SearchIcon fontSize="inherit" /></IconButton></InputAdornment>
                           </Fragment>
                       )
                   }}
                   onKeyUp={ev => {
                       if (ev.key === 'Enter') {
                           onSubmit(needle);
                       }
                   }}
        />
    )
}