import React, {Fragment, useEffect, useState} from "react";
import {Account, Device, DeviceType, FacilityActivitiesStats, Timeframe} from "../../data/types";
import {DateTime} from "luxon";
import {useMessages} from "../../i18n";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";

import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {authStore} from "../../store";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {DateSelector} from "../../component/DateSelector";
import {
    getCurrentFacility,
    getFacility,
    listFacilities
} from "../../data/facilities";
import {listAccounts} from "../../data/accounts";
import {listDevices} from "../../data/devices";
import {computeFacilityActivitiesStats} from "../../data/stats";
import {luxonDate} from "../../tools";
import {displayAccountNameLine} from "../../utils";
import {ActionButton} from "../../component/buttons";
import {exportFacilityActivityExcel} from "../../tools/export";

export const StatisticActivitiesView = () => {
    const [facilityUuid, setFacilityUuid] = useState('');
    const [timeframe, setTimeframe] = useState<Timeframe>({ from: DateTime.now().startOf('month'), until: DateTime.now().endOf('month') });
    const [accountUuid, setAccountUuid] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [accounts, setAccounts] = useState<SelectOption[]>([]);
    const [devices, setDevices] = useState<SelectOption[]>([]);
    const [stats, setStats] = useState<FacilityActivitiesStats>();

    useEffect(() => {
        if (authStore.isRoot) {
            listFacilities()
                .then(p => setFacilities(p.content.map(f => ({ label: f.name, value: f.uuid }))));
        } else if (authStore.facility?.uuid) {
            handleFacilityChange(authStore.facility.uuid)
                .then();
        }
    }, []);

    async function handleFacilityChange(value: string) {
        setFacilityUuid(value);
        setAccountUuid('');
        setDeviceId('');
        if (value) await fetchFacilityFilterOptions(value);
    }

    async function fetchFacilityFilterOptions(facilityUuid: string) {
        let data;
        try {
            if (authStore.isRoot) {
                data = await (Promise.all([
                    listAccounts(undefined, undefined, { facilityUuid }).then(p => p.content),
                    listDevices(undefined, undefined, { facilityUuid }).then(p => p.content)
                ]));
            } else {
                data = await (Promise.all([
                    listAccounts().then(p => p.content),
                    listDevices().then(p => p.content)
                ]))
            }
        } catch (err) {
            console.error('failed to fetch facility filter options', err);
            return;
        }

        let [accounts, devices] = data;

        setAccounts(accounts.map(mapAccount));
        setDevices(devices.filter(d => DeviceType.Entrance === d.type).map(mapDevice));
    }

    function mapAccount(a: Account) {
        return {
            label: (a.firstName || a.lastName ? [a.firstName, a.lastName].join(" ") : m.accounts.singular) + ' - ' + a.email,
            value: a.uuid
        }
    }

    function mapDevice(d: Device) {
        return {
            label: d.name || m.devices.singular,
            value: d.id
        }
    }

    async function fetchStats() {
        if (facilityUuid && timeframe.from && timeframe.until) {
            try {
                const stats = await computeFacilityActivitiesStats(facilityUuid, timeframe, accountUuid, deviceId);
                setStats({
                    ...stats,
                    activities: stats.activities
                        .sort((a, b) => b.timestamp.toString().localeCompare(a.timestamp.toString()))
                });
            } catch (err) {
                console.log('failed to compute facility stats', err);
            }
        }
    }

    async function handleExport() {
        if (facilityUuid && timeframe && stats) {
            let facility;
            try {
                facility = await ((authStore.isRoot) ? getFacility(facilityUuid) : getCurrentFacility());
            } catch (err) {
                console.error('failed to load facility', err);
                return;
            }

            exportFacilityActivityExcel(facility, timeframe, stats.activities);
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.statistics.title + ' - ' + m.statistics.activities} />
            <AppBreadcrumbs>
                <Crumb title={m.statistics.title} path="/statistics" />
                <Crumb title={m.statistics.activities} />
            </AppBreadcrumbs>
            <Box my={3} />
            <ContentBody padding={2}>
                <Grid container spacing={3}>
                    {authStore.isRoot && (
                        <Grid item sm={4} xs={12}>
                            <SelectInput label={m.facilities.singular}
                                         options={facilities}
                                         value={facilityUuid}
                                         onChange={handleFacilityChange}
                                         searchable
                            />
                        </Grid>
                    )}
                    <Grid item sm={4} xs={12}>
                        <DateSelector label={m.common.timeframe.from} date={timeframe.from} onChange={d => setTimeframe({ ...timeframe, from: d || undefined })} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <DateSelector label={m.common.timeframe.until} date={timeframe.until} onChange={d => setTimeframe({ ...timeframe, until: d || undefined })} />
                    </Grid>
                </Grid>
                <Box my={3} />
                <Grid container spacing={3}>
                    <Grid item sm={4} xs={12}>
                        <SelectInput label={m.accounts.singular}
                                     options={accounts}
                                     value={accountUuid}
                                     onChange={v => setAccountUuid(v)}
                                     searchable
                                     disabled={!facilityUuid}
                                     helperText={!facilityUuid ? m.statistics.selectFacility : undefined}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <SelectInput label={m.devices.singular}
                                     options={devices}
                                     value={deviceId}
                                     onChange={v => setDeviceId(v)}
                                     searchable
                                     disabled={!facilityUuid}
                                     helperText={!facilityUuid ? m.statistics.selectFacility : undefined}
                        />
                    </Grid>
                </Grid>
                <Box my={3} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <ActionButton color="primary" disabled={!facilityUuid || !timeframe.from || !timeframe.until} onClick={fetchStats}>{m.actions.view}</ActionButton>
                    <Box mx={1} />
                    <ActionButton disabled={!facilityUuid || !timeframe.from || !timeframe.until || !stats} onClick={handleExport}>{m.actions.export}</ActionButton>
                </Box>
            </ContentBody>
            <Box my={3} />
            {stats && (
                <Fragment>
                    <ContentBody>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{m.common.timestamp}</TableCell>
                                        <TableCell>{m.accounts.singular}</TableCell>
                                        <TableCell>{m.common.type}</TableCell>
                                        <TableCell>{m.devices.singular}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.activities.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={9}>{m.emptyContent}</TableCell>
                                        </TableRow>
                                    )}
                                    {stats.activities.map(a => (
                                        <TableRow key={'a-' + a.id}>
                                            <TableCell style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>{luxonDate(a.timestamp).toJSDate().toLocaleString()}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{displayAccountNameLine(a.account)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{m.accounts.activities[a.type]}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{(a.deviceName ? a.deviceName + ' - ' : '') + a.deviceId}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ContentBody>
                </Fragment>
            )}
        </Fragment>
    )
}