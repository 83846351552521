import {Paging} from "./pagings";
import {Api} from "./Api";
import {
    AccountTransaction,
    Page,
    TransactionFilter,
    Wallet,
    WalletAmountModificationRequest,
    WalletFilter,
    WalletTotalBalance,
    WalletType
} from "./types";
import {luxonDate} from "../tools";

export async function listWallets(paging: Paging, type: WalletType, needle?: string, filter?: WalletFilter) {
    const queries = [];
    queries.push('type=' + type);
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.active) queries.push('active=' + filter.active);
        if (type === WalletType.Facility && filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    }
    const prefix = '?' + queries.join('&');
    let p = await Api.GET<Page<Wallet>>('/wallets' + paging.toQueryString(prefix));
    return ({...(p), content: p.content.map(mapWallet)});
}

export async function getWallet(uuid: string) {
    let wallet = await Api.GET<Wallet>('/wallets/' + uuid);
    return mapWallet(wallet);
}

export async function listWalletTransactions(uuid: string, paging: Paging, filter?: TransactionFilter): Promise<Page<AccountTransaction>> {
    const queries = [];
    if (filter) {
        if (filter.direction) queries.push('direction=' + filter.direction);
        if (filter.timeframe) {
            if (filter.timeframe.from) queries.push('from=' + luxonDate(filter.timeframe.from).toJSDate().toISOString());
            if (filter.timeframe.until) queries.push('until=' + luxonDate(filter.timeframe.until).toJSDate().toISOString());
        }
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    let p = await Api.GET<Page<AccountTransaction>>(`/wallets/${uuid}/transactions${paging.toQueryString(prefix)}`);
    return ({...(p), content: p.content.map(t => ({...t, timestamp: luxonDate(t.timestamp)}))});
}

export async function modifyWalletAmount(uuid: string, request: WalletAmountModificationRequest) {
    return Api.PUT<Wallet>(`/wallets/${uuid}/amount`, request);
}

export function computeWalletTotalBalance(type: WalletType, facilityUUid?: string) {
    const queries = [];
    queries.push('type=' + type);
    if (type === WalletType.Facility && facilityUUid) queries.push('facilityUuid=' + facilityUUid);
    const prefix = '?' + queries.join('&');
    return Api.GET<WalletTotalBalance>(`/wallets/total-balance` + prefix);
}

function mapWallet(wallet: Wallet): Wallet {
    return {
        ...wallet,
        createdAt: luxonDate(wallet.createdAt)
    }
}