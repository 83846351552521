import React from "react";
import {UserRole} from "../../data/types";
import {Badge} from "../../component/Badge";
import {blue, purple, red} from "@mui/material/colors";

export const UserRoleBadge = ({role}: {role: UserRole}) => {
    switch (role) {
        case UserRole.Root:
            return (<Badge label={role} size="small" style={{ background: purple[400] }} />);
        case UserRole.Admin:
            return (<Badge label={role} size="small" style={{ background: red[400] }} />);
        case UserRole.User:
            return (<Badge label={role} size="small" style={{ background: blue[400] }} />);
        default:
            return (<Badge label={role} size="small" />);
    }
}