import React, {useEffect, useState} from "react"
import {Autocomplete, MenuItem, TextField} from "@mui/material";

export type SelectOption = {
    label: string,
    value: any
}

type SelectInputProps = {
    label: string,
    options: SelectOption[]
    name?: string,
    value?: string,
    onChange?: (value: any, name?: string) => void,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,
    searchable?: boolean,
    size?: 'small'|'medium',
}

export const SelectInput = (props: SelectInputProps) => {
    const {
        label, options,
        name, value: pValue, onChange,
        error, helperText,
        disabled,
        searchable,
        size
    } = props;

    const [value, setValue] = useState<SelectOption|null>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (pValue) {
            const findVal = options.find(o => o.value === pValue) || null;
            setValue(findVal);
        }
    }, [pValue, options])

    function handleChange(v: SelectOption|null) {
        setValue(v);
        if (onChange) onChange(v?.value ?? '', name);
    }

    function handleInputChange(v: string) {
        if (onChange) onChange(v, name)
    }

    return (
        searchable ?
            <Autocomplete
                size={size}
                options={options}
                getOptionLabel={o => o?.label ?? ''}
                isOptionEqualToValue={(o, v) => o.value == v}
                value={value}
                inputValue={inputValue}
                renderInput={p => (
                    <TextField
                        {...p} label={label} variant="outlined"
                        error={error}
                        helperText={helperText}
                        disabled={disabled}
                    />
                )}
                onChange={(e, v) => handleChange(v)}
                onInputChange={(e, v) => setInputValue(v)}
            /> :
            <TextField
                fullWidth select size={size} label={label}
                variant="outlined"
                name={name} value={pValue}
                onChange={e => handleInputChange(e.target.value)}
                error={error} helperText={helperText}
                disabled={disabled}
            >
                {options.map(({label, value}, i) => (
                    <MenuItem
                        key={'select-option-' + i + '-' + value}
                        value={value}
                    >
                        {label}
                    </MenuItem>
                ))}
            </TextField>
    )
}