import React from "react";
import {alpha, AppBar, Box, IconButton, Toolbar} from "@mui/material";
import {drawerWidth, theme} from "../theme";
import {MenuIcon} from "../icons";

type TopBarProps = {
    onOpen: () => void;
}

export const TopBar = ({onOpen}: TopBarProps) => {

    const handleDrawerToggle = () => {
        onOpen();
    };

    return (
        <AppBar position="fixed" sx={{
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4)
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0)
            },
            boxShadow: 'none',
            background: alpha(theme.palette.background.default, 0.8),
            backdropFilter: 'blur(6px)',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        marginRight: theme.spacing(2),
                        [theme.breakpoints.up('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Box display="flex" flexGrow={1}>&nbsp;</Box>
            </Toolbar>
        </AppBar>
    )
};