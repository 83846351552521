import React, {Fragment, useState} from "react";
import {useNavigate} from "react-router";
import {Box, Button} from "@mui/material";

import {useMessages} from "../../i18n";
import {ApiError} from "../../data/Api";
import {createFacility} from "../../data/facilities";
import {FacilityCreateRequest} from "../../data/types";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {FacilityForm} from "./FacilityForm";

export const FacilityCreate = () => {
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState<ApiError>();

    const create = async (form: FacilityCreateRequest) => {
        setInProgress(true);
        try {
            const facility = await createFacility(form);
            navigate('/facilities/' + facility.uuid);
            return;
        } catch (error) {
            setError(error as any);
        }
        setInProgress(false);
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.facilities.create.title}/>
            <AppBreadcrumbs>
                <Crumb title={m.facilities.plural} path="/facilities" />
                <Crumb title={m.actions.create} />
            </AppBreadcrumbs>
            <Box my={3}/>
            <ContentBody padding={2}>
                <FacilityForm inProgress={inProgress}
                              onCreate={create}
                              onCancel={() => navigate('/facilities')}
                />
            </ContentBody>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}