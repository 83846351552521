import {Api} from "./Api";
import {Paging} from "./pagings";
import {Device, DeviceFilter, DevicePortRequest, DeviceUpdateRequest, Page} from "./types";

export function listDevices(paging?: Paging, needle?: string, filter?: DeviceFilter) {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.type) queries.push('type=' + filter.type);
        if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    return Api.GET<Page<Device>>('/devices' + (paging ? paging.toQueryString(prefix) : (prefix ? prefix + '&unpaged=1' : '?unpaged=1')));
}

export function getDevice(id: string) {
    return Api.GET<Device>('/devices/' + id);
}

export function updateDevice(id: string, request: DeviceUpdateRequest) {
    return Api.PUT<Device>('/devices/' + id, request);
}

export function updateDevicePort(id: string, request: DevicePortRequest) {
    return Api.PUT<Device>('/devices/' + id + '/ports', request);
}

export async function deleteDevice(id: string) {
    await Api.DELETE('/devices/' + id);
}