import React from "react";
import {i18n, useMessages} from "../i18n";
import {SelectInput} from "./SelectInput";

type CountryData = { [key: string]: string };

class SupportedCountries {

    private static _en: CountryData = {
        'AT': 'Austria',
        'DE': 'Germany',
        'CH': 'Swiss',

        'IT': 'Italy',
        'FR': 'France',
        'ES': 'Spain',
        'GB': 'United Kingdom',
        'CZ': 'Czech Republic',
        'SE': 'Sweden',
        'FI': 'Finland',
        'NO': 'Norway',
        'PL': 'Poland',
        'RO': 'Romania',
        'BY': 'Belarus',
        'GR': 'Greece',
        'BG': 'Bulgaria',
        'IS': 'Iceland',
        'PT': 'Portugal',
        'DK': 'Denmark',
        'HU': 'Hungary',
        'RS': 'Serbia',
        'IE': 'Ireland',
        'LT': 'Lithuania',
        'LV': 'Latvia',
        'HR': 'Croatia',
        'BA': 'Bosnia and Herzegovina',
        'SK': 'Slovakia',
        'EE': 'Estonia',
        'NL': 'Netherlands',
        'MD': 'Moldova',
        'BE': 'Belgium',
        'AL': 'Albania',
        'MK': 'Macedonia',
        'SI': 'Slovenia',
        'ME': 'Montenegro',
        'CY': 'Cyprus',
        'LU': 'Luxembourg',
        'FO': 'Faroe Is.',
        'AD': 'Andorra',
        'MT': 'Malta',
        'LI': 'Liechtenstein',
        'GG': 'Guernsey',
        'SM': 'San Marino',
        'GI': 'Gibraltar',
        'MC': 'Monaco',
        'VA': 'Vatican City',
        'UA': 'Ukraine',
    };

    private static _de: CountryData = {
        'AT': 'Österreich',
        'DE': 'Deutschland',
        'CH': 'Schweiz',

        'IT': 'Italien',
        'FR': 'Frankreich',
        'ES': 'Spanien',
        'GB': 'Vereinigtes Königreich',
        'CZ': 'Tschechische Republik',
        'SE': 'Schweden',
        'FI': 'Finnland',
        'NO': 'Norwegen',
        'PL': 'Polen',
        'RO': 'Rumänien',
        'BY': 'Weißrussland',
        'GR': 'Griechenland',
        'BG': 'Bulgarien',
        'IS': 'Island',
        'PT': 'Portugal',
        'DK': 'Dänemark',
        'HU': 'Ungarn',
        'RS': 'Serbien',
        'IE': 'Irland',
        'LT': 'Litauen',
        'LV': 'Lettland',
        'HR': 'Kroatien',
        'BA': 'Bosnien und Herzegowina',
        'SK': 'Slowakei',
        'EE': 'Estland',
        'NL': 'Niederlande',
        'MD': 'Moldawien',
        'BE': 'Belgien',
        'AL': 'Albanien',
        'MK': 'Mazedonien',
        'SI': 'Slowenien',
        'ME': 'Montenegro',
        'CY': 'Zypern',
        'LU': 'Luxemburg',
        'FO': 'Färöer Inseln',
        'AD': 'Andorra',
        'MT': 'Malta',
        'LI': 'Liechtenstein',
        'GG': 'Guernsey',
        'SM': 'San Marino',
        'GI': 'Gibraltar',
        'MC': 'Monaco',
        'VA': 'Vatikanstadt',
        'UA': 'Ukraine',
    };

    public static getData() {
        switch (i18n.language) {
            case 'de':
                return this._de;
            default:
                return this._en;
        }
    }
}

type CountrySelectorProps = {
    onChange: (countryCode: string) => void,
    countryCode?: string,
    required?: boolean,
    allowAll?: boolean
}

export const CountrySelector = ({onChange, countryCode, required, allowAll}: CountrySelectorProps) => {
    const m = useMessages();

    const options = Object.entries(SupportedCountries.getData()).map(([k,v]) => ({ label: v, value: k}));
    if (allowAll) options.unshift({ label: m.common.all, value: 'ALL' });

    return (
        <SelectInput label={m.common.address.country}
                     options={options}
                     value={countryCode||''}
                     onChange={v => onChange(v)}
                     error={required && !countryCode}
                     helperText={required && !countryCode ? m.error.requiredField : undefined}
        />
    )
}