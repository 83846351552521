import React, {Fragment, useState} from "react";
import {useParams} from "react-router";
import {
    Box,
    Grid, IconButton,
    Typography
} from "@mui/material";
import {useMessages} from "../../i18n";
import {displayAccountNameLine} from "../../utils";
import {Wallet} from "../../data/types";
import {getWallet} from "../../data/wallets";
import {LabeledText} from "../../component/LabeledText";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {ActionButton} from "../../component/buttons";
import {WalletAmountForm} from "./WalletAmountForm";
import {WalletTransactions} from "./WalletTransactions";
import {useAsync} from "react-async-hook";
import {MinusIcon, PlusIcon, PlusMinusIcon} from "../../icons";

export const WalletDetails = () => {
    const {uuid = ''} = useParams<{ uuid: string }>();
    const m = useMessages();

    const [showAmountForm, setShowAmountForm] = useState(false);

    const {result: wallet, set} = useAsync(() => getWallet(uuid), [uuid])

    function handleWalletModified(w: Wallet) {
        set({ status: 'success', result: w, loading: false, error: undefined });
        setShowAmountForm(false);
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.wallets.singular}/>
                    <AppBreadcrumbs>
                        <Crumb title={m.wallets.plural} path="/wallets" />
                        <Crumb title={m.actions.view} />
                    </AppBreadcrumbs>
                </Grid>
            </Grid>
            <Box my={3} />
            {wallet && (
                <Fragment>
                    <ContentBody padding={2}>
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={12} sm={12}>
                                <Box display="flex" flexDirection="row">
                                    <LabeledText label={m.accounts.singular}>
                                        {displayAccountNameLine(wallet.account)}
                                    </LabeledText>
                                    {wallet.facility && (
                                        <Fragment>
                                            <Box mx={2} />
                                            <LabeledText label={m.facilities.singular}>
                                                {wallet.facility.name}
                                            </LabeledText>
                                        </Fragment>
                                    )}

                                    <Box mx={2} />
                                    <LabeledText label={m.common.type}>
                                        {m.wallets.types[wallet.type]}
                                    </LabeledText>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Typography variant="h4">€ {wallet.amount}</Typography>
                                <Box mx={1} />
                                <ActionButton
                                    color="primary"
                                    onClick={() => setShowAmountForm(true)}
                                    startIcon={<PlusMinusIcon />}
                                >
                                    {m.actions.edit}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </ContentBody>
                    <Box my={3} />
                    <WalletTransactions
                        wallet={wallet}
                    />
                    <WalletAmountForm
                        open={showAmountForm}
                        wallet={wallet}
                        onModified={handleWalletModified}
                        onClose={() => setShowAmountForm(false)}
                    />
                </Fragment>
            )}
            {/*{error && (*/}
            {/*    <ErrorPrompt error={error}>*/}
            {/*        <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>*/}
            {/*    </ErrorPrompt>*/}
            {/*)}*/}
        </Fragment>
    )
}