import {Paging} from "./pagings";
import {Api} from "./Api";
import {
    Account,
    AccountCreateRequest,
    AccountDiscount,
    AccountDiscountRequest,
    AccountFacility, AccountFacilityOverview,
    AccountFilter,
    AccountGroup, AccountGroupFilter,
    AccountGroupRequest,
    AccountPermission,
    AccountPermissionRequest, AccountRangefeeRequest,
    AccountUpdateRequest,
    Page,
    Wallet
} from "./types";
import {luxonDate} from "../tools";

export async function listAccounts(paging?: Paging, needle?: string, filter?: AccountFilter): Promise<Page<Account>> {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.enabled) queries.push('enabled=' + filter.enabled);
        if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    let p = await Api.GET<Page<Account>>('/accounts' + (paging ? paging.toQueryString(prefix) : (prefix ? prefix + '&unpaged=1' : '?unpaged=1')));
    return ({...(p), content: p.content.map(mapAccount)});
}

export function listAccountFacilityOverviews(paging: Paging, facilityUuid?: string) {
    const prefix = facilityUuid ? '?facilityUuid=' + facilityUuid : undefined;
    return Api.GET<Page<AccountFacilityOverview>>(`/accounts/facility-overviews` + paging.toQueryString(prefix));
}

export function listAccountGroups(paging: Paging, needle?: string, filter?: AccountGroupFilter) {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    return Api.GET<Page<AccountGroup>>('/accounts/groups' + paging.toQueryString(prefix));
}

export function getAccount(uuid: string) {
    return Api.GET<Account>('/accounts/' + uuid)
        .then(mapAccount);
}

export function getAccountGroup(uuid: string) {
    return Api.GET<AccountGroup>('/accounts/groups/' + uuid);
}

export function listGroupAccounts(uuid: string, paging: Paging) {
    return Api.GET<Page<Account>>(`/accounts/groups/${uuid}/accounts` + paging.toQueryString());
}

export function assignAccountsToGroup(uuid: string, accountUuids: string[]) {
    return Api.POST(`/accounts/groups/${uuid}/accounts`, accountUuids);
}

export function removeAccountsFromGroup(uuid: string, accountUuids: string[]) {
    return Api.DELETE(`/accounts/groups/${uuid}/accounts`, accountUuids);
}

export function assignPermissionsToGroup(uuid: string, permissionUuid: string) {
    return Api.POST(`/accounts/groups/${uuid}/permissions/${permissionUuid}`, {});
}

export function createAccount(request: AccountCreateRequest) {
    return Api.POST<Account>('/accounts', request)
        .then(mapAccount);
}

export function createAccountGroup(request: AccountGroupRequest) {
    return Api.POST<AccountGroup>('/accounts/groups', {
        ...request,
        expirationDate: request.expirationDate ?
            luxonDate(request.expirationDate).toFormat('yyyy-MM-dd')
            : undefined
    });
}

export function updateAccount(uuid: string, request: AccountUpdateRequest) {
    return Api.PUT<Account>('/accounts/' + uuid, request)
        .then(mapAccount);
}

export async function addAccountRangefee(uuid: string, request: AccountRangefeeRequest) {
    await Api.POST('/accounts/' + uuid + '/rangefee', request);
}

export function updateAccountGroup(uuid: string, request: AccountGroupRequest) {
    return Api.PUT<AccountGroup>(`/accounts/groups/${uuid}`, {
        ...request,
        expirationDate: request.expirationDate ?
            luxonDate(request.expirationDate).toFormat('yyyy-MM-dd')
            : undefined
    });
}

export function updateAccountGroupDevicePermission(uuid: string, deviceId: string, enabled: boolean) {
    return Api.PUT(`/accounts/groups/${uuid}/devices/${deviceId}?enabled=${enabled}`, {});
}

export function deleteAccount(uuid: string) {
    return Api.DELETE(`/accounts/${uuid}`);
}

export function deleteAccountFacility(accountFacilityId: number) {
    return Api.DELETE('/accounts/facilities/' + accountFacilityId);
}

export function deleteAccountGroup(uuid: string) {
    return Api.DELETE(`/accounts/groups/${uuid}`);
}

export function getAccountFacility(uuid: string) {
    return Api.GET<AccountFacility>('/accounts/' + uuid + '/facility');
}

export function updateAccountFacilityStatus(uuid: string, afId: number, enabled: boolean, rangefeeEnabled: boolean) {
    return Api.PUT<AccountFacility>(`/accounts/${uuid}/facility/${afId}/status`, { enabled, rangefeeEnabled });
}

export function updateAccountFacilityData(uuid: string, afId: number, memberNumber: string, associationNumber: string) {
    return Api.PUT<AccountFacility>(`/accounts/${uuid}/facility/${afId}/data`, { memberNumber, associationNumber });
}

export function listAccountPermissions(uuid: string) {
    return Api.GET<AccountPermission[]>('/accounts/' + uuid + '/permissions');
}

export function updateAccountPermissions(uuid: string, request: AccountPermissionRequest) {
    return Api.POST<AccountPermission[]>('/accounts/' + uuid + '/permissions', request);
}

export function listAccountDiscounts(uuid: string) {
    return Api.GET<AccountDiscount[]>('/accounts/' + uuid + '/discounts');
}

export function updateAccountDiscounts(uuid: string, request: AccountDiscountRequest) {
    return Api.POST<AccountDiscount[]>('/accounts/' + uuid + '/discounts', request);
}

export function listAccountFacilities(uuid: string) {
    return Api.GET<AccountFacility[]>(`/accounts/` + uuid + '/facilities');
}

export function listAccountWallets(uuid: string) {
    return Api.GET<Wallet[]>('/accounts/' + uuid + '/wallets');
}

function mapAccount(account: Account): Account {
    return {
        ...account,
        emailConfirmedAt: account.emailConfirmedAt ? luxonDate(account.emailConfirmedAt) : undefined,
        phoneConfirmedAt: account.phoneConfirmedAt ? luxonDate(account.phoneConfirmedAt) : undefined
    }
}
