import {AccountActivity, AccountActivityType, Facility, Timeframe} from "../../data/types";
import XLSX from "xlsx";
import {luxonDate} from "../../tools";
import {displayAccountNameLine, displayDateTime} from "../../utils";
import {computeColumnLengths} from "./excels";

export function exportFacilityActivityExcel(facility: Facility, timeframe: Timeframe, activities: AccountActivity[]) {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheet, "")

    XLSX.utils.sheet_add_aoa(worksheet, [['Statistik']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(worksheet, [['Club: ' + facility.name]], { origin: 'A2' });
    XLSX.utils.sheet_add_aoa(worksheet, [[`Zeittraum: ${luxonDate(timeframe.from).toFormat('dd.MM.yyyy')} - ${luxonDate(timeframe.until).toFormat('dd.MM.yyyy')}`]], { origin: 'A3' });

    const headers = ["Zeitstempel", "Account", "Typ", "Gerät"];
    let rows: {[key: string]: string}[] = activities.map(a => {
        let type = String(a.type);
        switch (a.type) {
            case AccountActivityType.FacilityRegistration:
                type = 'Club Registrierung';
                break;
            case AccountActivityType.OpenEntrance:
                type = 'Eingang öffnen';
                break;
        }

        return {
            timestamp: displayDateTime(a.timestamp),
            account: displayAccountNameLine(a.account),
            type,
            device: (a.deviceName ? a.deviceName + ' - ' : '') + a.deviceId
        }
    });

    XLSX.utils.sheet_add_json(worksheet, rows, { origin: "A5" });
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A5" });

    worksheet["!cols"] = computeColumnLengths(rows);
    worksheet["!merges"] = [
        {s: {r: 0, c: 0}, e: {r: 0, c: 11}},
        {s: {r: 1, c: 0}, e: {r: 1, c: 11}},
        {s: {r: 2, c: 0}, e: {r: 2, c: 11}},
    ];

    XLSX.writeFile(workbook, `Club Aktivität - ${facility.name} - ${luxonDate(timeframe.from).toFormat('dd.MM.yyyy')} - ${luxonDate(timeframe.until).toFormat('dd.MM.yyyy')}.xlsx`, { compression: true });
}