import React from "react";
import {amber, blueGrey, green, indigo, lightBlue, teal} from "@mui/material/colors";
import {useMessages} from "../i18n";
import {FacilityType} from "../data/types";
import {
    CircleIcon,
    GolfClubIcon,
    LocationEditIcon,
    ParkingIcon,
    SailingIcon,
    TennisIcon,
    UnknownLocationIcon
} from "../icons";
import {Badge} from "./Badge";

export const FacilityTypeBadge = ({type}: { type: FacilityType }) => {
    const m = useMessages();
    switch (type) {
        case FacilityType.GolfClub:
        case FacilityType.GolfCentral:
        case FacilityType.GolfResort:
        case FacilityType.GolfClubCleaning:
            return <Badge label={m.facilities.types[type]} style={{ background: green['500'] }} icon={<GolfClubIcon fontSize="inherit" />} />
        case FacilityType.ParkingHouse:
        case FacilityType.ParkingZone:
            return <Badge label={m.facilities.types[type]} style={{ background: indigo['800'] }} icon={<ParkingIcon fontSize="inherit" />} />
        case FacilityType.BilliardClub:
            return <Badge label={m.facilities.types[type]} style={{ background: amber['700'] }} icon={<CircleIcon fontSize="inherit" />} />;
        case FacilityType.SailingClub:
            return <Badge label={m.facilities.types[type]} style={{ background: lightBlue['A700'] }} icon={<SailingIcon fontSize="inherit" />} />;
        case FacilityType.TennisClub:
            return <Badge label={m.facilities.types[type]} style={{ background: teal['500'] }} icon={<TennisIcon fontSize="inherit" />} />;
        case FacilityType.TestClub:
            return <Badge label={m.facilities.types[type]} style={{ background: blueGrey['800'] }} icon={<LocationEditIcon fontSize="inherit" />} />;
        default:
            return <Badge label={type} style={{ background: blueGrey['300'] }} icon={<UnknownLocationIcon fontSize="inherit" />} />
    }
}