import {TextInput} from "./TextInput";
import React, {useEffect, useMemo, useState} from "react";
import {i18n, useMessages} from "../i18n";
import {Box} from "@mui/material";

interface AmountEditProps {
    required?: boolean,
    disabled?: boolean,
    amount?: number,
    currency?: string,
    onChange: (value: number) => void,
}

export function AmountEditor({required, disabled, amount: pAmount, currency, onChange}: AmountEditProps) {
    const m = useMessages();

    const [amount, setAmount] = useState('');

    useEffect(() => {
        if (pAmount) {
            let parsed = String(pAmount);
            if (i18n.language === 'de') {
                parsed = parsed.replace('.', ',');
            }
            setAmount(parsed);
        }
    }, [pAmount]);

    function verifyAmount(amount: string) {
        if (required && amount.length === 0) return false;
        if (i18n.language === 'de') return amount.split(',').length <= 2;
        else return amount.split('.').length <= 2;
    }

    function onAmountChanged(value: string) {
        let modified = value;
        if (i18n.language === 'de') {
            modified = modified.replace(/[^0-9,]/g, '');
        } else {
            modified = modified.replace(/[^0-9.]/g, '');
        }
        setAmount(modified);
        if (verifyAmount(modified)) onChange(parseFloat(modified.replace(',', '.')));
    }

    const validAmount = useMemo(() => verifyAmount(amount), [amount]);

    return (
        <TextInput
            disabled={disabled}
            label={m.common.amount}
            value={amount}
            onChange={onAmountChanged}
            error={!validAmount}
            helperText={!validAmount ? m.error.invalidValue : undefined}
            InputProps={{
                startAdornment: <Box sx={{ mr: 1 }}>{currency ?? '€'}</Box>
            }}
        />
    )
}