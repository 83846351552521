import React from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";

interface RadioInputProps {
    label: string;
    options: { label: string, value: string }[];
    value?: any;
    onChange?: (value: string) => void;
}
export function RadioInput({label, options, value, onChange}: RadioInputProps) {

    function handleChange(value: string) {
        if (onChange) onChange(value);
    }

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                row
                value={value}
                onChange={(ev, v) => handleChange(v)}
            >
                {options.map((o, i) => (
                    <FormControlLabel
                        key={'option-' + i}
                        value={o.value}
                        label={o.label}
                        control={<Radio />}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}