import {AccountGroup, AccountGroupRequest, UserCreateRequest, UserRole, UserUpdateRequest} from "../../data/types";
import {useEffect, useMemo, useState} from "react";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {authStore} from "../../store";
import {useMessages} from "../../i18n";
import React, {Fragment} from "react";
import {listFacilities} from "../../data/facilities";
import {Box, Button, FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import {TextInput} from "../../component/TextInput";

import {SaveIcon} from "../../icons";
import {createAccountGroup, updateAccountGroup} from "../../data/accounts";
import {DateSelector} from "../../component/DateSelector";
import {luxonDate} from "../../tools";
import {TimeSelector} from "../../component/TimeSelector";

type AccountGroupFormProps = {
    onCancel: () => void,
    accountGroup?: AccountGroup,
    onCreated?: (group: AccountGroup) => void,
    onSaved?: (group: AccountGroup) => void,
}

export const AccountGroupForm = ({onCancel, accountGroup, onCreated, onSaved}: AccountGroupFormProps) => {
    const [inProgress, setInProgress] = useState(false);
    const [createForm, setCreateForm] = useState({ name: '', expirationDate: '', startHour: '', endHour: '', facilityUuid: '', defaultOnSignup: false });
    const [updateForm, setUpdateForm] = useState<AccountGroupRequest|undefined>(accountGroup ? { ...accountGroup, facilityUuid: accountGroup.facility.uuid } : undefined);
    const [facilities, setFacilities] = useState<SelectOption[]>([]);

    const getForm = useMemo(() => updateForm ? updateForm : createForm, [updateForm, createForm]);
    const setForm = useMemo(() => updateForm ? setUpdateForm : setCreateForm, [updateForm]);
    const canSubmit = useMemo(() => {
        if (inProgress) return false;
        const facilityOk = (authStore.isRoot) ? !!getForm.facilityUuid : true;
        return (getForm.name && facilityOk);
    }, [inProgress, accountGroup, getForm, createForm]);

    useEffect(() => {
        if (authStore.isRoot) {
            listFacilities()
                .then(p => setFacilities(p.content.map(f => ({ label: f.name, value: f.uuid }))));
        }
    }, []);

    const onFormChange = (name: string, value: any) => {
        setForm({
            ...getForm as any,
            [name]: value
        });
    }

    const submit = async () => {
        setInProgress(true);
        try {
            if (accountGroup && updateForm) {
                const group = await updateAccountGroup(accountGroup.uuid, updateForm);
                if (onSaved) onSaved(group);
            } else {
                const group = await createAccountGroup(createForm);
                if (onCreated) onCreated(group);
            }
        } catch (err) {

        }
        setInProgress(false);
    }

    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={2}>
                {authStore.isRoot && (
                    <Grid item md={6} xs={12}>
                        <SelectInput label={m.facilities.singular}
                                     options={facilities}
                                     value={getForm.facilityUuid ?? ''}
                                     onChange={v => onFormChange('facilityUuid', v)}
                                     searchable
                                     error={!getForm.facilityUuid}
                                     helperText={!getForm.facilityUuid ? m.error.requiredField : undefined}
                        />
                    </Grid>
                )}

                <Grid item md={6} xs={12}>
                    <TextInput label={m.common.name}
                               value={getForm.name ?? ''} onChange={v => onFormChange('name', v)}
                    />
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <DateSelector label={m.accounts.expirationDate}
                                  date={getForm.expirationDate ? luxonDate(getForm.expirationDate) : undefined}
                                  onChange={v => onFormChange('expirationDate', v)}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TimeSelector label={m.accounts.startHour}
                                  time={getForm.startHour}
                                  onChange={v => onFormChange('startHour', v)}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TimeSelector label={m.accounts.endHour}
                                  time={getForm.endHour}
                                  onChange={v => onFormChange('endHour', v)}
                    />
                </Grid>
            </Grid>
            <Box my={3} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={getForm.defaultOnSignup} onChange={(ev, checked) => onFormChange('defaultOnSignup', checked)}  />} label={m.facilities.permissionGroup.defaultOnSignUp} />
                    </FormGroup>
                </Grid>
            </Grid>
            <Box my={3} />
            <Box display="flex" justifyContent="flex-end">
                <Button disableElevation variant="contained" color="primary" size="medium"
                        disabled={!canSubmit} startIcon={<SaveIcon/>}
                        onClick={submit}>{updateForm ? m.actions.save : m.actions.create}</Button>
                <Box mx={1}/>
                <Button size="medium" onClick={onCancel}>{m.actions.cancel}</Button>
            </Box>
        </Fragment>
    );
}
