import React, {Fragment, useState} from "react";
import {ApiError} from "../../data/Api";
import {useMessages} from "../../i18n";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {useNavigate} from "react-router";
import {Box} from "@mui/material";
import {AccountGroupForm} from "./AccountGroupForm";

export const AccountGroupCreate = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<ApiError>();

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.accounts.createGroup.title}/>
            <AppBreadcrumbs>
                <Crumb title={m.accounts.plural} path="/accounts" />
                <Crumb title={m.accounts.groups} path="/accounts/groups" />
                <Crumb title={m.actions.create} />
            </AppBreadcrumbs>
            <Box my={3} />
            <ContentBody padding={2}>
                <AccountGroupForm onCreated={group => navigate('/accounts/groups/' + group.uuid)}
                                  onCancel={() => navigate('/accounts/groups')}
                />
            </ContentBody>
        </Fragment>
    )
}