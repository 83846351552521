import React, {CSSProperties, Fragment, PropsWithChildren, useState} from "react";
import {
    Typography,
    Breadcrumbs as BC,
    Fab,
    Paper,
    Box,
    Grid,
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions, lighten, CircularProgress, alpha
} from "@mui/material";
import {red, yellow} from "@mui/material/colors";
import {Link} from "react-router-dom";
import {useMessages} from "../i18n";
import {borderColor, theme} from "../theme";
import {DeleteIcon, DotIcon} from "../icons";

export const ContentTitle = ({title}: { title: string|JSX.Element }) => {
    document.title = title + ' | pinpoint Center Panel';
    return (
        <Typography variant="h2" sx={{
            fontSize: theme.typography.fontSize * 1.8,
            fontWeight: theme.typography.fontWeightMedium
        }}>{title}</Typography>
    )
};

export const AppBreadcrumbs = ({children}: PropsWithChildren<{}>) => {
    return (
        <BC sx={{
            '& ol': {
                fontSize: theme.typography.fontSize * 0.5,
                color: theme.palette.grey['200'],
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                '& $crumb:last-child > *': {
                    color: theme.palette.grey['500']
                }
            },
            '& li': {
                '& > *': {
                    transition: 'all ease-out 300ms',
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.fontSize,
                    textDecoration: 'none'
                },
                '& > a:hover': {
                    color: theme.palette.primary.main
                }
            }
        }}
            separator={<DotIcon style={{
                fontSize: '0.5rem',
                lineHeight: '1em',
                top: -2,
                position: 'relative',
                opacity: 0.6
            }} />}>
            {children}
        </BC>
    )
}

type CrumbProps = {
    title: string;
    path?: string;
}

export const Crumb = ({title, path}: CrumbProps) => (
    path ?
        <Link to={path} style={{ lineHeight: '1em', padding: '0' }}>{title}</Link> :
        <span style={{ lineHeight: '1em', padding: '0' }}>{title}</span>
)

type AppFabProps = {
    onClick: () => void;
}

export const AppFab = ({onClick, children}: PropsWithChildren<AppFabProps>) => {
    return (
        <Fab sx={{
            position: 'fixed',
            bottom: theme.spacing(4),
            right: theme.spacing(7),
            boxShadow: `0 0 10px ${alpha(theme.palette.primary.main, 0.6)}`,
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(4),
                width: 46,
                height: 46
            }
        }} color="primary"
             onClick={onClick}
             children={children as any} />
    )
}

type ContentBodyProps = {
    padding?: number,
    type?: 'danger'|'warning',
    style?: CSSProperties,
}

export const ContentBody = ({padding, type, style, children}: PropsWithChildren<ContentBodyProps>) => {
    let paperSx: any = {
        width: '100%',
        border: `1px solid ${borderColor}`,
        borderRadius: '10px !important',
        boxShadow: `0 0 ${theme.spacing(3)} ${alpha(theme.palette.grey['100'], 0.5)}`
    };
    if ('danger' === type) paperSx = { ...paperSx, border: `1px solid ${red[600]}` };
    if ('warning' === type) paperSx = { ...paperSx, background: yellow[300] };
    return (
        <Paper elevation={0} sx={paperSx} style={style}>
            <Box p={padding || undefined} height="100%">
                {children}
            </Box>
        </Paper>
    )
}

export const ContentLoader = () => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
        <CircularProgress />
        <Box my={1}/>
        <Typography variant="subtitle1">{useMessages().loading}</Typography>
    </Box>
)

type AppChipProps = {
    label: string,
    color?: 'primary'|'secondary';
    customColor?: string;
}

export const AppChip = ({label, color, customColor}: AppChipProps) => {
    let backgroundColor = undefined;
    if (customColor) {
        backgroundColor = customColor;
    } else if (color === 'primary') {
        backgroundColor = theme.palette.primary.main;
    } else if (color === 'secondary') {
        backgroundColor = theme.palette.secondary.main;
    }
    return (
        <Box component="div" sx={{
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: 5,
            color: '#fff',
            background: theme.palette.grey['300'],
            fontWeight: theme.typography.fontWeightBold
        }} style={{ backgroundColor }}>{label}</Box>
    )
}

type DeletePromptProps = {
    title: string,
    message: string,
    onDelete: () => void
}

export const DeletePrompt = ({title, message, onDelete}: DeletePromptProps) => {
    const [active, setActive] = useState(false);

    const toggleActive = () => setActive(!active);

    const handleDelete = () => {
        onDelete();
        toggleActive();
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentBody type="danger" padding={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item style={{flex: 1}}>
                        <Typography variant="subtitle1" sx={{ color: red[600] }}><strong>{m.common.warning}</strong></Typography>
                        <Typography variant="body1" sx={{ color: red[600] }}>{m.common.irreversibleHint}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disableElevation
                                sx={{
                                    background: red[600],
                                    color: '#fff',
                                    '&:hover': {
                                        background: lighten(red[600], 0.2),
                                    }
                                }} onClick={toggleActive}
                                startIcon={<DeleteIcon />}
                        >
                            {title}
                        </Button>
                    </Grid>
                </Grid>
            </ContentBody>
            <DeleteDialog active={active}
                          title={title} message={message}
                          onApprove={handleDelete}
                          onCancel={toggleActive}
            />
        </Fragment>
    )
}

type DeleteButtonProps = {
    title: string,
    message: string,
    onDelete: () => void,
    submitLabel?: string,
    size?: 'small'|'medium'|'large',
    variant?: 'contained'|'outlined',
    color?: 'error'|'success',
}

export const DeleteButton = ({title, message, onDelete, submitLabel, size, variant, color}: DeleteButtonProps) => {
    const [active, setActive] = useState(false);

    const toggleActive = () => setActive(!active);

    const handleDelete = () => {
        onDelete();
        toggleActive();
    }

    return (
        <Fragment>
            <Button variant={variant} disableElevation
                    size={size}
                    color={color}
                    onClick={toggleActive}
                    startIcon={<DeleteIcon />}
            >
                {title}
            </Button>
            <DeleteDialog active={active}
                          title={title} message={message}
                          onApprove={handleDelete}
                          onCancel={toggleActive}
                          submitLabel={submitLabel}
            />
        </Fragment>
    )
}

type DeleteDialogProps = {
    active: boolean,
    title: string,
    message: string,
    onApprove: () => void,
    onCancel: () => void,
    submitLabel?: string
}

const DeleteDialog = ({active, title, message, onApprove, onCancel, submitLabel}: DeleteDialogProps) => {
    const m = useMessages();
    return (
        <Dialog open={active}>
            <DialogTitle sx={{ color: red[600] }}>{title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation
                        sx={{
                            background: red[600],
                            color: '#fff',
                            '&:hover': {
                                background: lighten(red[600], 0.2),
                            }
                        }} onClick={onApprove}
                >
                    {submitLabel || m.actions.delete}
                </Button>
                <Button onClick={onCancel}>{m.actions.cancel}</Button>
            </DialogActions>
        </Dialog>
    )
}