import React, {Fragment, useMemo, useState} from "react";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";

import {SaveIcon} from "../../icons";
import {authStore} from "../../store";
import {useMessages} from "../../i18n";
import {Device, DeviceType, DeviceUpdateRequest} from "../../data/types";
import {CheckboxInput} from "../../component/CheckboxInput";
import {TextInput} from "../../component/TextInput";
import {SelectInput} from "../../component/SelectInput";
import {ActionButton} from "../../component/buttons";
import {ConfirmDialog} from "../../component/ConfirmDialog";

type DeviceFormProps = {
    inProgress: boolean,
    onCancel: () => void,
    device: Device,
    onSave?: (form: DeviceUpdateRequest) => void,
    onDelete: (device: Device) => void,
}

export const DeviceForm = ({inProgress, onCancel, device, onSave, onDelete}: DeviceFormProps) => {
    const [updateForm, setUpdateForm] = useState<DeviceUpdateRequest>({ ...device });
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const canSubmit = useMemo(() => (!inProgress && updateForm && updateForm.type && updateForm.name), [inProgress, updateForm]);

    function onFormChange(name: string, value: any) {
        const form = {
            ...updateForm as any,
            [name]: value
        };
        if (name === 'type' && value === DeviceType.BallVendingMachine) form.offlineServiceEnabled = false;
        else if (name === 'type' && value !== DeviceType.Entrance) form.automaticallyOpenedOnConnection = false;
        setUpdateForm(form);
    }

    function submit() {
        if (device && updateForm) {
            if (onSave) onSave(updateForm);
        }
    }

    function deleteDevice() {
        if (device) onDelete(device);
    }

    const m = useMessages();
    return (
        <Fragment>
            <Typography variant="h6">{m.common.identity}</Typography>
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        label={m.common.name}
                        value={updateForm.name ?? ''} onChange={v => onFormChange('name', v)}
                        error={!updateForm.name}
                        helperText={!updateForm.name ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={m.common.description}
                        value={updateForm.description ?? ''} onChange={v => onFormChange('description', v)}
                        rows={3}
                    />
                </Grid>
                {authStore.isRoot && (
                    <Grid item md={6} xs={12}>
                        <SelectInput
                            label={m.common.type}
                            options={Object.values(DeviceType).map(t => ({ label: m.devices.types[t], value: t }))}
                            value={updateForm.type}
                            onChange={v => onFormChange('type', v)}
                            error={!updateForm.type}
                            helperText={!updateForm.type ? m.error.requiredField : undefined}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CheckboxInput
                        label={m.devices.enableRangefee}
                        value={updateForm.rangefeeRequired}
                        onChange={v => onFormChange('rangefeeRequired', v)}
                    />
                </Grid>
                {DeviceType.BallVendingMachine !== updateForm.type && (
                    <Grid item xs={12}>
                        <CheckboxInput
                            label={m.devices.allowOfflineService}
                            value={updateForm.offlineServiceEnabled}
                            onChange={v => onFormChange('offlineServiceEnabled', v)}
                        />
                    </Grid>
                )}
                {DeviceType.Entrance === updateForm.type && (
                    <Grid item xs={12}>
                        <CheckboxInput
                            label={m.devices.automaticallyOpenedOnConnection}
                            value={updateForm.automaticallyOpenedOnConnection}
                            onChange={v => onFormChange('automaticallyOpenedOnConnection', v)}
                        />
                    </Grid>
                )}
            </Grid>

            <Box my={3} />

            <Stack direction="row">
                {authStore.isRoot && (
                    <ActionButton
                        color="error"
                        onClick={() => setShowDeletePrompt(true)}
                    >
                        {m.actions.delete}
                    </ActionButton>
                )}
                <Box sx={{ flex: 1 }} />
                <ActionButton
                    color="primary"
                    disabled={!canSubmit}
                    startIcon={<SaveIcon/>}
                    onClick={submit}
                >
                    {m.actions.save}
                </ActionButton>
                <Box mx={1}/>
                <ActionButton
                    variant="text"
                    onClick={onCancel}
                >
                    {m.actions.cancel}
                </ActionButton>
            </Stack>
            <ConfirmDialog
                open={showDeletePrompt}
                title={m.devices.delete.title}
                message={m.devices.delete.message}
                onCancel={() => setShowDeletePrompt(false)}
                onConfirm={deleteDevice}
            />
        </Fragment>
    )
}