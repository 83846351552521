import {Notice, NoticeRequest} from "../../data/types";
import React, {Fragment, useMemo, useState} from "react";
import {useMessages} from "../../i18n";
import {Box, Button, Grid} from "@mui/material";
import {TextInput} from "../../component/TextInput";

import {SaveIcon} from "../../icons";
import {authStore} from "../../store";
import {FacilitySelect} from "../../component/FacilitySelect";

type NoticeFormProps = {
    inProgress: boolean,
    onCancel: () => void,
    notice?: Notice,
    onCreate?: (form: NoticeRequest) => void,
    onSave?: (form: NoticeRequest) => void
}

export const NoticeForm = ({inProgress, onCancel, notice, onCreate, onSave}: NoticeFormProps) => {
    const [createForm, setCreateForm] = useState<NoticeRequest>({ title: '', content: '' });
    const [updateForm, setUpdateForm] = useState<NoticeRequest|undefined>(notice ? { ...notice, content: decodeURIComponent(escape(atob(notice.content))), facilityUuid: notice.facility?.uuid } : undefined);

    const getForm = useMemo(() => updateForm ? updateForm : createForm, [updateForm, createForm]);
    const setForm = useMemo(() => updateForm ? setUpdateForm : setCreateForm, [updateForm]);
    const canSubmit = useMemo(() => (!inProgress && getForm.title && getForm.content) as boolean, [inProgress, getForm]);

    const onFormChange = (name: string, value: any) => {
        setForm({
            ...getForm,
            [name]: value
        });
    }

    const submit = () => {
        if (notice && updateForm) {
            if (onSave) onSave(getForm);
        } else {
            if (onCreate) onCreate(getForm);
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={2}>
                {authStore.isRoot && (
                    <Grid item xs={12}>
                        <FacilitySelect
                            disabled={!!notice}
                            value={getForm.facilityUuid}
                            onChange={f => onFormChange('facilityUuid', f?.uuid)}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextInput
                        label={m.common.title}
                        value={getForm.title} onChange={v => onFormChange('title', v)}
                        error={!getForm.title}
                        helperText={!getForm.title ? m.error.requiredField : undefined}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        multiline rows={5}
                        label={m.common.message}
                        value={getForm.content} onChange={v => onFormChange('content', v)}
                        error={!getForm.content}
                        helperText={!getForm.content ? m.error.requiredField : undefined}
                    />
                </Grid>
            </Grid>

            <Box my={3}/>

            <Box display="flex" justifyContent="flex-end">
                <Button disableElevation variant="contained" color="primary" size="medium"
                        disabled={!canSubmit} startIcon={<SaveIcon/>}
                        onClick={submit}>{updateForm ? m.actions.save : m.actions.create}</Button>
                <Box mx={1}/>
                <Button size="medium" onClick={onCancel}>{m.actions.cancel}</Button>
            </Box>
        </Fragment>
    );
}