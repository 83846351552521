import React, {
    Fragment,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import {useNavigate} from "react-router";
import {
    alpha,
    Avatar,
    Box, darken, Dialog, DialogActions, DialogContent,
    Drawer,
    Hidden,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Typography
} from "@mui/material";
import {useMessages} from "../i18n";
import {borderRadius, drawerWidth, theme} from "../theme";
import {
    AccountIcon,
    ChartIcon,
    ClubIcon, DashboardIcon,
    DeviceIcon, NoticeIcon,
    PayIcon,
    PowerIcon,
    SettingIcon,
    UserIcon,
    WalletIcon
} from "../icons";
import {authStore} from "../store";
import {UserContext} from "../data/context";
import {deepOrange} from "@mui/material/colors";
import {Logo} from "../graphic";
import {logout} from "../data/auth";
import {ActionButton} from "./buttons";

export const AppNav = ({open, onClose}: { open: boolean, onClose: () => void }) => {
    const [mobileOpen, setMobileOpen] = useState(open);

    useEffect(() => {
        setMobileOpen(open);
    }, [open])

    const handleDrawerToggle = () => {
        const result = !mobileOpen;
        setMobileOpen(result);
        if (!result) {
            onClose();
        }
    }

    const container = window !== undefined ? () => window.document.body : undefined;
    const drawerSx = {
        width: drawerWidth,
        border: 'none',
        background: '#283035',
        boxShadow: '0 10px 20px ' + alpha('#000', 0.4),
        '& *': {
            color: '#fff'
        }
    }

    return (
        <Box component="nav" sx={{
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        }}>
            <Hidden mdUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    PaperProps={{
                        sx: drawerSx
                    }}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <Nav />
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    PaperProps={{
                        sx: drawerSx
                    }}
                    variant="permanent"
                    open
                >
                    <Nav />
                </Drawer>
            </Hidden>
        </Box>
    )
}

const Nav = () => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const [activeMenu, setActiveMenu] = useState<string>('');
    const [logoutPrompt, setLogoutPrompt] = useState(false);

    const handleActiveMenu = useCallback(() => {
        const entity = window.location.pathname.split('/')[1];
        setActiveMenu(entity || '/');
    }, [setActiveMenu]);

    useEffect(() => {
        handleActiveMenu();
    }, []);

    const openMenu = (title: string, path: string) => {
        document.title = title + ' | pinpoint Center Panel';
        navigate(path);
        handleActiveMenu();
    };

    async function handleLogout() {
        try {
            await logout();
            localStorage.clear();
            navigate('/login');
        } catch (err) {
            console.error('cannot logout', err);
        }
    }

    const isActive = (entity: string) => (entity === activeMenu);

    const name = [user.firstName, user.lastName].filter(t => !!t).join(' ');
    const navChildItemSx = {
        fontSize: theme.typography.fontSize * 0.95,
        paddingLeft: theme.spacing(6),
        '&:hover': {
            background: darken('#283035', 0.3),
        }
    };
    const navGroupTitleSx: any = {
        color: alpha('#fff', 0.9),
        letterSpacing: 1,
        textTransform: 'uppercase',
        fontSize: theme.typography.fontSize * 0.85,
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(2),
        paddingBottom: 0
    };

    const m = useMessages();
    return (
        <Fragment>
            <Box p={2}>
                <img style={{
                    marginTop: theme.spacing(3),
                    width: 170
                }} src={Logo} alt="PINPOINT Center" />
            </Box>
            <Box p={2}>
                <div style={{
                    display: 'flex',
                    padding: theme.spacing(2),
                    border: '1px solid #FFF',
                    borderRadius: borderRadius
                }}>
                    <Avatar alt="Username" src={"https://avatars.dicebear.com/api/initials/" + (name || m.users.singular) + ".svg"} />
                    <Typography variant="body1" component="div">
                        <Box ml={2}>
                            <strong>{name || m.users.singular}</strong>
                            <br/>
                            <small>{user.position ?? '-'}</small>
                        </Box>
                    </Typography>
                </div>
            </Box>
            <Typography sx={navGroupTitleSx}>{m.nav.group.general}</Typography>
            <List>
                <NavItem isActive={isActive('dashboard')} path="/dashboard" title={m.dashboard.title} icon={DashboardIcon} handleClick={openMenu} />
                <NavItem isActive={isActive('statistics')} path="/statistics" title={m.statistics.title} icon={ChartIcon} handleClick={openMenu}>
                    {isActive('statistics') && (
                        <Fragment>
                            <ListItemButton sx={navChildItemSx} onClick={() => openMenu(m.statistics.title + ' - ' + m.statistics.activities, '/statistics/activities')}>{m.statistics.activities}</ListItemButton>
                            <ListItemButton sx={navChildItemSx} onClick={() => openMenu(m.statistics.title + ' - ' + m.statistics.transactions, '/statistics/transactions')}>{m.statistics.transactions}</ListItemButton>
                        </Fragment>
                    )}
                </NavItem>
            </List>
            <Typography sx={navGroupTitleSx}>{m.nav.group.management}</Typography>
            <List>
                {authStore.isRoot && (
                    <NavItem isActive={isActive('facilities')} path="/facilities" title={m.facilities.plural} icon={ClubIcon} handleClick={openMenu} />
                )}
                <NavItem isActive={isActive('devices')} path="/devices" title={m.devices.plural} icon={DeviceIcon} handleClick={openMenu} />
                <NavItem isActive={isActive('notices')} path="/notices" title={m.notices.plural} icon={NoticeIcon} handleClick={openMenu} />
                <NavItem isActive={isActive('accounts')} path="/accounts" title={m.accounts.plural} icon={AccountIcon} handleClick={openMenu}>
                    {isActive('accounts') && (
                        <Fragment>
                            <ListItemButton sx={navChildItemSx} onClick={() => openMenu(m.accounts.groups, '/accounts/groups')}>{m.accounts.groups}</ListItemButton>
                        </Fragment>
                    )}
                </NavItem>
                <NavItem isActive={isActive('wallets')} path="/wallets" title={m.wallets.plural} icon={WalletIcon} handleClick={openMenu}>
                    {authStore.isRoot && isActive('wallets') && (
                        <Fragment>
                            <ListItemButton sx={navChildItemSx} onClick={() => openMenu(m.common.international, '/wallets/international')}>{m.common.international + ' ' + m.wallets.plural}</ListItemButton>
                            <ListItemButton sx={navChildItemSx} onClick={() => openMenu(m.facilities.singular, '/wallets/facility')}>{m.facilities.singular + ' ' + m.wallets.plural}</ListItemButton>
                        </Fragment>
                    )}
                </NavItem>
                <NavItem isActive={isActive('payments')} path="/payments" title={m.payments.plural} icon={PayIcon} handleClick={openMenu} />
            </List>
            <Typography sx={navGroupTitleSx}>{m.nav.group.settings}</Typography>
            <List>
                {authStore.isRoot && (
                    <NavItem isActive={isActive('users')} path="/users" title={m.users.plural} icon={UserIcon} handleClick={openMenu} />
                )}
                {authStore.isAdmin && (
                    <Fragment>
                        <NavItem isActive={isActive('users')} path="/users" title={m.users.plural} icon={UserIcon} handleClick={openMenu} />
                        <NavItem isActive={isActive('facility')} path="/facility" title={m.facilities.singular} icon={ClubIcon} handleClick={openMenu} />
                    </Fragment>
                )}
                <NavItem isActive={isActive('preferences')} path="/preferences" title="Preferences" icon={SettingIcon} handleClick={openMenu} />
            </List>
            <Box sx={{ flexGrow: 1 }} />
            <List>
                <ListItemButton sx={{
                    '& *': {
                        color: deepOrange['A400']
                    }
                }} onClick={() => setLogoutPrompt(true)}>
                    <ListItemIcon sx={{
                        color: '#fff',
                        fontSize: theme.typography.fontSize * 1.2,
                        position: 'relative',
                        top: -2,
                        minWidth: 35
                    }}>{<PowerIcon fontSize="inherit"/>}</ListItemIcon>
                    <ListItemText sx={{
                        color: '#fff',
                        fontSize: theme.typography.fontSize
                    }} primary={m.logout.btn} />
                </ListItemButton>
                <ListItem>
                    <small style={{ color: alpha(theme.palette.grey['50'], 0.7) }}>
                        &copy; Pin Point GmbH {new Date().getFullYear()}
                        <br/>
                        v{process.env.VERSION}
                    </small>
                </ListItem>
            </List>

            <Dialog open={logoutPrompt} fullWidth maxWidth="sm" onClose={() => setLogoutPrompt(false)}>
                <DialogContent>
                    <Typography variant="h5">{m.common.confirmation}</Typography>
                    <Box my={2} />
                    <Typography>{m.logout.prompt}</Typography>
                </DialogContent>
                <DialogActions>
                    <ActionButton onClick={() => setLogoutPrompt(false)}>
                        {m.actions.cancel}
                    </ActionButton>
                    <Box m={1} sx={{ flexGrow: 1 }} />
                    <ActionButton color="error" onClick={handleLogout}>
                        {m.logout.btn}
                    </ActionButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

type NavItemProps = {
    isActive: boolean,
    title: string,
    path: string,
    icon: typeof SvgIcon,
    handleClick: (title: string, path: string) => void,
    children?: ReactNode
}

const NavItem = ({isActive, title, path, icon, handleClick, children}: NavItemProps) => {
    const Icon = icon;
    const navMenuActiveSx = isActive ? {
        background: darken('#283035', 0.5),
        '&:hover': {
            background: darken('#283035', 0.3),
        },
        '& $navMenuItem': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $navMenuIcon, & $navMenuItem': {
            color: '#fff'
        }
    } : undefined;
    const navMenuIconSx = {
        color: '#fff',
        fontSize: theme.typography.fontSize * 1.2,
        position: 'relative',
        top: -2,
        minWidth: 35
    };
    const navMenuItemSx = {
        color: '#fff',
        fontSize: theme.typography.fontSize
    };
    return (
        <Fragment>
            <ListItemButton sx={navMenuActiveSx} onClick={() => handleClick(title, path)}>
                <ListItemIcon sx={navMenuIconSx}>{<Icon fontSize="inherit"/>}</ListItemIcon>
                <ListItemText sx={navMenuItemSx} primary={title} />
            </ListItemButton>
            {children && (
                <List sx={{ background: darken('#283035', 0.5) }}>
                    {children}
                </List>
            )}
        </Fragment>
    )
}