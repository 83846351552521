import {SHA3} from "crypto-js";
import {
    Account,
    AccountTransaction,
    Address,
    User
} from "./data/types";
import {i18n} from "./i18n";
import {luxonDate} from "./tools";

export function displayAddressLine(address: Address) {
    const lines = [
        address.addressLine1,
        address.addressLine2,
        [address.zipCode, address.city].join(' ')
    ];
    return lines.filter(l => !!l).join(', ');
}

export function displayUserNameLine(user: User) {
    const lines = [
        user.title,
        user.firstName,
        user.lastName ? user.lastName.toUpperCase() : undefined
    ];
    return lines.filter(l => !!l).join(' ');
}

export function displayAccountNameLine(account: Account) {
    const lines = [
        account.firstName,
        account.lastName ? account.lastName.toUpperCase() : undefined
    ].filter(l => !!l);
    if (lines.length === 0) return account.email;
    return lines.join(' ');
}

export function displayTransactionBillingName(transaction: AccountTransaction) {
    const lines = [
        transaction.billingFirstName,
        transaction.billingLastName ? transaction.billingLastName.toUpperCase() : undefined
    ].filter(l => !!l);
    if (lines.length === 0) return '--';
    return lines.join(' ');
}

export function displayDate(date: any) {
    switch (i18n.language) {
        case 'de':
            return luxonDate(date).toFormat('dd.MM.yyyy');
        default:
            return luxonDate(date).toFormat('dd/MM/yyyy');
    }
}

export function displayDateTime(date: any) {
    switch (i18n.language) {
        case 'de':
            return luxonDate(date).toFormat('dd.MM.yyyy, HH:mm');
        default:
            return luxonDate(date).toFormat('dd/MM/yyyy, hh:mm a')
    }
}

class Sha3 {

    public hash(data: string) {
        return SHA3(data).toString();
    }
}

export const sha3 = new Sha3();