import {Paging} from "./pagings";
import {AccountTransaction, Page, PaymentFilter} from "./types";
import {luxonDate} from "../tools";
import {Api} from "./Api";

export async function listPayments(paging: Paging, filter: PaymentFilter, needle?: string): Promise<Page<AccountTransaction>> {
    const queries = [];
    if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    if (filter.from) queries.push('from=' + luxonDate(filter.from).toFormat('yyyy-MM-dd'));
    if (filter.until) queries.push('until=' + luxonDate(filter.until).toFormat('yyyy-MM-dd'));
    if (filter.purposes) queries.push(filter.purposes.map(f => 'purposes=' + f).join('&'));
    if (!!needle) queries.push('needle=' + needle);
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    let p = await Api.GET<Page<AccountTransaction>>('/payments' + (paging ? paging.toQueryString(prefix) : (prefix ? prefix + '&unpaged=1' : '?unpaged=1')));
    return ({...(p), content: p.content.map(a => ({...a, timestamp: luxonDate(a.timestamp)}))});
}