import XLSX from "xlsx";
import {AccountTransaction, Wallet} from "../../data/types";
import {displayAccountNameLine, displayDateTime, displayUserNameLine} from "../../utils";
import {formatAmount} from "../../tools";
import {computeColumnLengths, formatCellAsNumber, getTypeName} from "./excels";

export function exportWalletTransactionExcel(wallet: Wallet, transactions: AccountTransaction[]) {
    const walletOwner = displayAccountNameLine(wallet.account);
    const walletType = wallet.facility ? 'ANLAGE: ' + wallet.facility.name : 'INTERNATIONAL';

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transaktion");

    XLSX.utils.sheet_add_aoa(worksheet, [['Transaktion']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(worksheet, [[`Account: ${walletOwner}`]], { origin: 'A2' });
    XLSX.utils.sheet_add_aoa(worksheet, [[walletType]], { origin: 'A3' });
    worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 8 } },
    ];

    const headers = ["Zeitstempel", "Freigegeben am", "Typ", "Club", "Gerät", "Fach #", "Menge", "Sachbearbeiter", "Betrag", "Neue Guthaben", "Notiz"].filter(c => wallet.facility ? c !== 'Club' : true);
    let rows: Record<string, string>[] = transactions.map(t => ({
        timestamp: displayDateTime(t.timestamp),
        clearedAt: t.clearedAt ? displayDateTime(t.clearedAt) : '-',
        type: getTypeName(t.type),
        facility: t.device && t.device.facility ? t.device.facility.name : '-',
        device: t.device ? (t.device.name || t.device.id) : '-',
        port: t.transactionPortName ?? '-',
        value: String(t.transactionValue ?? '-'),
        clerk: t.user ? displayUserNameLine(t.user) : '-',
        amount: formatAmount(t.transactionAmount),
        newAmount: formatAmount(t.newAmount),
        note: t.note ?? '-'
    }));
    if (wallet.facility) {
        rows = rows.map(r => {
            delete r.facility;
            return r;
        });
    }

    XLSX.utils.sheet_add_json(worksheet, rows, { origin: "A5" });
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A5" });

    formatCellAsNumber(worksheet, 5, wallet.facility ? 7 : 8, rows.length, '0.00');
    formatCellAsNumber(worksheet, 5, wallet.facility ? 8 : 9, rows.length, '0.00');

    worksheet["!cols"] = computeColumnLengths(rows);

    XLSX.writeFile(workbook, `Geldboerse Transaktion - ${walletOwner} - ${wallet.facility ? 'Anlage ' + wallet.facility.name : 'International'}.xlsx`, { compression: true });
}