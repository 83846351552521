import React, {Fragment, useEffect} from "react";
import {useNavigate} from "react-router";
import {
    Box,
    Container,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ContentBody, ContentTitle} from "../../component/ContentLayout";
import {useMessages} from "../../i18n";
import {getAccountKpi, getFacilityKpi, getTransactionKpi} from "../../data/kpis";
import {useAsync} from "react-async-hook";
import {AccountIcon, ClubIcon, GlobeIcon, LoginIcon, LogoutIcon, RegistrationIcon, StoreIcon} from "../../icons";
import {KpiItem} from "./KpiItem";
import {formatAmountDigit} from "../../tools";
import {listRecentTransactions} from "../../data/stats";
import {Loading} from "../../component/Loading";
import {TransactionRow} from "../../component/TransactionRow";

export function RootDashboard() {
    const {result: facilityKpi, loading: fkpiLoading} = useAsync(getFacilityKpi, []);
    const {result: accountKpi, loading: akpiLoading} = useAsync(getAccountKpi, []);
    const {result: transactionKpi, loading: tkpiLoading} = useAsync(getTransactionKpi, []);
    const {result: recentTransactions} = useAsync(listRecentTransactions, []);

    const m = useMessages();
    return (
        <Container>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.dashboard.title} />
                </Grid>
            </Grid>
            <Box my={4} />
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <ContentBody padding={2}>
                        <Typography sx={{ color: '#F44336' }} variant="h6"><strong>{m.facilities.plural}</strong></Typography>
                        <Box my={1} />
                        {fkpiLoading && <Loading type="circular" />}
                        {facilityKpi && (
                            <Stack direction="column" spacing={1}>
                                <KpiItem
                                    label={m.common.total}
                                    value={facilityKpi.facilitiesCount}
                                />
                                <KpiItem
                                    label={m.kpis.facilities.registration}
                                    value={facilityKpi.facilityRegistrationsCount}
                                    icon={RegistrationIcon}
                                />
                            </Stack>
                        )}
                    </ContentBody>
                    <Box my={3} />
                    <ContentBody padding={2}>
                        <Typography sx={{ color: '#FBC02D' }} variant="h6"><strong>{m.accounts.plural}</strong></Typography>
                        <Box my={1} />
                        {akpiLoading && <Loading type="circular" />}
                        {accountKpi && (
                            <Stack direction="column" spacing={1}>
                                <KpiItem
                                    label={m.common.total}
                                    value={accountKpi.accountsCount}
                                />
                                <KpiItem
                                    label={m.kpis.accounts.facilityWalletsBalance}
                                    value={formatAmountDigit(accountKpi.totalFacilityWalletsBalance)}
                                    valuePrefix="€"
                                    icon={StoreIcon}
                                />
                                <KpiItem
                                    label={m.kpis.accounts.internationalWalletsBalance}
                                    value={formatAmountDigit(accountKpi.totalInternationalWalletsBalance)}
                                    valuePrefix="€"
                                    icon={GlobeIcon}
                                />
                            </Stack>
                        )}
                    </ContentBody>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <ContentBody padding={2} >
                        <Typography sx={{ color: '#00C853' }} variant="h6"><strong>{m.transactions.plural}</strong></Typography>
                        <Typography>{(m.common.months as any)[new Date().getMonth()]} {new Date().getFullYear()}</Typography>
                        <Box my={1} />
                        {tkpiLoading && <Loading type="circular" />}
                        {transactionKpi && (
                            <Stack direction="column" spacing={1}>
                                <KpiItem
                                    label={m.common.total}
                                    value={transactionKpi.transactionsCount}
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookIn} (${m.common.club})`}
                                    value={formatAmountDigit(transactionKpi.totalFacilityBookInAmount)}
                                    icon={LoginIcon}
                                    valuePrefix="€"
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookOut} (${m.common.club})`}
                                    value={formatAmountDigit(transactionKpi.totalFacilityBookOutAmount)}
                                    icon={LogoutIcon}
                                    valuePrefix="€"
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookIn} (${m.common.international})`}
                                    value={formatAmountDigit(transactionKpi.totalInternationBookInAmount)}
                                    icon={LoginIcon}
                                    valuePrefix="€"
                                />
                                <KpiItem
                                    label={`${m.common.sum} ${m.transactions.direction.BookOut} (${m.common.international})`}
                                    value={formatAmountDigit(transactionKpi.totalInternationBookOutAmount)}
                                    icon={LogoutIcon}
                                    valuePrefix="€"
                                />
                            </Stack>
                        )}
                    </ContentBody>
                </Grid>
            </Grid>
            <Box my={4} />
            <ContentBody>
                <Box p={2}>
                    <Typography variant="h6"><strong>{m.transactions.recent}</strong></Typography>
                </Box>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{m.common.timestamp}</TableCell>
                                <TableCell>{m.accounts.singular}</TableCell>
                                <TableCell>{m.wallets.singular}</TableCell>
                                <TableCell>{m.common.type}</TableCell>
                                <TableCell>{m.transactions.purposes.singular}</TableCell>
                                <TableCell>{m.devices.singular}</TableCell>
                                <TableCell align="right">{m.common.amount}</TableCell>
                            </TableRow>
                        </TableHead>
                        {recentTransactions && (
                            <TableBody>
                                {recentTransactions.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={8}>{m.emptyContent}</TableCell>
                                    </TableRow>
                                )}
                                {recentTransactions.map((t, i) => <TransactionRow key={t.uuid} transaction={t} view="dashboard" />)}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </ContentBody>
        </Container>
    )
}

