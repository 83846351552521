import {Api} from "./Api";
import {
    AccountTransactionDirection,
    FacilityTransactionsStats,
    FacilityActivitiesStats,
    Timeframe,
    AccountTransactionPurpose, AccountTransaction
} from "./types";
import {luxonDate} from "../tools";
import {authStore} from "../store";

export function computeFacilityTransactionsStats(
    facilityUuid: string,
    timeframe: Timeframe,
    accountUuid?: string,
    deviceId?: string,
    direction?: AccountTransactionDirection,
    purposes?: AccountTransactionPurpose[],
    notes?: string[]
) {
    let query = makeQuery(timeframe, accountUuid, deviceId);
    if (direction) query += '&direction=' + direction;
    if (purposes && purposes.length > 0) query += '&' + purposes.map(f => 'purposes=' + f).join('&');
    if (notes && notes.length > 0) query += '&' + notes.map(n => 'notes=' + n).join('&');
    const path = getStatsPath(facilityUuid);
    return Api.GET<FacilityTransactionsStats>(path + '/transactions' + query);
}

export function computeFacilityTransactionNotes(facilityUuid: string) {
    const path = getStatsPath(facilityUuid);
    return Api.GET<string[]>(path + '/transactions/notes');
}

export function computeFacilityActivitiesStats(facilityUuid: string, timeframe: Timeframe, accountUuid?: string, deviceId?: string) {
    let query = makeQuery(timeframe, accountUuid, deviceId);
    const path = getStatsPath(facilityUuid);
    return Api.GET<FacilityActivitiesStats>(path + '/activities' + query);
}

export function listRecentTransactions() {
    return Api.GET<AccountTransaction[]>('/stats/recent-transactions');
}

function getStatsPath(facilityUuid: string) {
    return authStore.isRoot ?
        `/stats/f/` + facilityUuid :
        '/stats';
}

function makeQuery(timeframe: Timeframe, accountUuid?: string, deviceId?: string) {
    let query = '?from=' + luxonDate(timeframe.from).toFormat('yyyy-MM-dd');
    query += '&until=' + luxonDate(timeframe.until).toFormat('yyyy-MM-dd');
    if (accountUuid) query += '&accountUuid=' + accountUuid;
    if (deviceId) query += '&deviceId=' + deviceId;
    return query;
}
