import React, {Fragment, useEffect, useMemo, useState} from "react";
import {
    Account, AccountActivityType,
    AccountTransactionDirection, AccountTransactionPurpose,
    Device,
    DeviceType,
    Facility,
    FacilityTransactionsStats,
    Timeframe
} from "../../data/types";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {getCurrentFacility, getFacility, listFacilities} from "../../data/facilities";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {DateSelector} from "../../component/DateSelector";
import {useMessages} from "../../i18n";
import {authStore} from "../../store";
import {DateTime} from "luxon";
import {listAccounts} from "../../data/accounts";
import {listDevices} from "../../data/devices";
import {computeFacilityTransactionNotes, computeFacilityTransactionsStats} from "../../data/stats";
import {TransactionRow} from "../../component/TransactionRow";
import {formatAmountDigit} from "../../tools";
import {MultiSelectInput} from "../../component/MultiSelectInput";
import {ActionButton} from "../../component/buttons";
import {exportFacilityTransactionExcel} from "../../tools/export";
import {CheckboxInput} from "../../component/CheckboxInput";
import {BallsIcon} from "../../icons";

export const StatisticTransactionsView = () => {
    const [facility, setFacility] = useState<Facility>();
    const [timeframe, setTimeframe] = useState<Timeframe>({ from: DateTime.now().startOf('month'), until: DateTime.now().endOf('month') });
    const [accountUuid, setAccountUuid] = useState<string>('');
    const [deviceId, setDeviceId] = useState<string>('');
    const [direction, setDirection] = useState<AccountTransactionDirection>();
    const [purposes, setPurposes] = useState<AccountTransactionPurpose[]>([]);
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [accounts, setAccounts] = useState<SelectOption[]>([]);
    const [devices, setDevices] = useState<SelectOption[]>([]);
    const [hideZeroAmountTransactions, setHideZeroAmountTransactions] = useState(false);
    const [hideIncompleteTransactions, setHideIncompleteTransactions] = useState(true);
    const [stats, setStats] = useState<FacilityTransactionsStats>();
    const [facilityNotes, setFacilityNotes] = useState<string[]>([]);
    const [notes, setNotes] = useState<string[]>([]);

    const {totalBookOut, totalBookOutClub, totalBookOutIntl, totalBookIn, totalBookInClub, totalBookInIntl, totalBallQuantity, serviceFee, payout} = useMemo(() => {
        let totalBookOut = 0;
        let totalBookOutClub = 0;
        let totalBookOutIntl = 0;
        let totalBookIn = 0;
        let totalBookInClub = 0;
        let totalBookInIntl = 0;
        let totalBallQuantity = 0;
        let payout = 0;
        let serviceFee = 0;
        if (stats) {
            totalBookOut = Math.round((stats.totalBookOutIntlTransaction + stats.totalBookOutClubTransaction) * 100) / 100;
            totalBookOutClub = Math.round((stats.totalBookOutClubTransaction) * 100) / 100;
            totalBookOutIntl = Math.round((stats.totalBookOutIntlTransaction) * 100) / 100;
            totalBookIn = Math.round((stats.totalBookInIntlTransaction + stats.totalBookInClubTransaction) * 100) / 100;
            totalBookInClub = Math.round((stats.totalBookInClubTransaction) * 100) / 100;
            totalBookInIntl = Math.round((stats.totalBookInIntlTransaction) * 100) / 100;
            totalBallQuantity = stats.totalBallQuantity;
            payout = Math.round((stats.totalPayout) * 100) / 100;
            serviceFee = Math.round((totalBookOutIntl - payout) * 100) / 100;
        }
        return {
            totalBookOut,
            totalBookOutClub,
            totalBookOutIntl,
            totalBookIn,
            totalBookInClub,
            totalBookInIntl,
            totalBallQuantity,
            payout,
            serviceFee
        }
    }, [stats])
    const transactions = useMemo(() => {
        if (!stats) return [];
        return stats.transactions
            .filter(t => {
                if (hideZeroAmountTransactions && t.transactionAmount === 0) return false;
                else if (hideIncompleteTransactions && !t.clearedAt) return false;
                return true;
            });
    }, [stats, hideZeroAmountTransactions, hideIncompleteTransactions])

    useEffect(() => {
        if (authStore.isRoot) {
            listFacilities()
                .then(p => setFacilities(p.content.map(f => ({ label: f.name, value: f.uuid }))));
        } else if (authStore.facility?.uuid) {
            handleFacilityChange(authStore.facility.uuid)
                .then();
        }
    }, []);



    async function handleFacilityChange(value: string) {
        try {
            const facility = authStore.isRoot ?  await getFacility(value) : await getCurrentFacility();
            setAccountUuid('');
            setDeviceId('');
            setFacility(facility);
            if (facility) {
                await fetchFacilityFilterOptions(facility.uuid);
            }
        } catch (err) {
            console.error('failed fetch facility', err);
        }
    }

    async function fetchFacilityFilterOptions(facilityUuid: string) {
        let data;
        try {
            if (authStore.isRoot && facilityUuid) {
                data = await (Promise.all([
                    listAccounts(undefined, undefined, { facilityUuid }).then(p => p.content),
                    listDevices(undefined, undefined, { facilityUuid }).then(p => p.content)
                ]));
            } else {
                data = await (Promise.all([
                    listAccounts().then(p => p.content),
                    listDevices().then(p => p.content)
                ]))
            }
        } catch (err) {
            console.error('failed to fetch facility filter options', err);
            return;
        }

        let [accounts, devices] = data;

        setAccounts(accounts.map(mapAccount));
        setDevices(devices.filter(d => DeviceType.BallVendingMachine === d.type).map(mapDevice));

        setFacilityNotes(await computeFacilityTransactionNotes(facilityUuid));
    }

    function mapAccount(a: Account) {
        return {
            label: (a.firstName || a.lastName ? [a.firstName, a.lastName].join(" ") : m.accounts.singular) + ' - ' + a.email,
            value: a.uuid
        }
    }

    function mapDevice(d: Device) {
        return {
            label: d.name || m.devices.singular,
            value: d.id
        }
    }

    async function fetchStats() {
        if (facility && timeframe.from && timeframe.until) {
            try {
                const stats = await computeFacilityTransactionsStats(facility.uuid, timeframe, accountUuid, deviceId, direction, purposes, notes);
                setStats({
                    ...stats,
                    transactions: stats.transactions
                        .sort((a, b) => b.timestamp.toString().localeCompare(a.timestamp.toString()))
                });
            } catch (err) {
                console.log('failed to compute facility stats', err);
            }
        }
    }

    async function handleExport() {
        if (facility && timeframe && stats) {
            exportFacilityTransactionExcel(facility, timeframe, transactions, {totalBookOut, totalBookOutClub, totalBookOutIntl, totalBookIn, totalBookInClub, totalBookInIntl, totalBallQuantity, serviceFee, payout});
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={m.statistics.title + ' - ' + m.statistics.transactions} />
            <AppBreadcrumbs>
                <Crumb title={m.statistics.title} path="/statistics" />
                <Crumb title={m.statistics.transactions} />
            </AppBreadcrumbs>
            <Box my={3} />
            <ContentBody padding={2}>
                <Grid container spacing={3}>
                    <Grid item sm={4} xs={12}>
                        <Typography fontWeight="bold">Anlage</Typography>
                        <Box my={2} />
                        {authStore.isRoot && (
                            <Fragment>
                                <SelectInput
                                    size="small"
                                    label={m.facilities.singular}
                                    options={facilities}
                                    value={facility?.uuid}
                                    onChange={handleFacilityChange}
                                    searchable
                                />
                                <Box my={3} />
                            </Fragment>
                        )}
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <DateSelector
                                    label={m.common.timeframe.from}
                                    date={timeframe.from}
                                    onChange={d => setTimeframe({ ...timeframe, from: d || undefined })}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <DateSelector
                                    label={m.common.timeframe.until}
                                    date={timeframe.until}
                                    onChange={d => setTimeframe({ ...timeframe, until: d || undefined })}
                                />
                            </Grid>
                        </Grid>
                        <Box my={3} />
                        <SelectInput
                            size="small"
                            label={m.accounts.singular}
                            options={accounts}
                            value={accountUuid}
                            onChange={setAccountUuid}
                            searchable
                            disabled={!facility}
                            helperText={!facility ? m.statistics.selectFacility : undefined}
                        />
                        <Box my={3} />
                        <SelectInput
                            size="small"
                            label={m.devices.singular}
                            options={devices}
                            value={deviceId}
                            onChange={setDeviceId}
                            searchable
                            disabled={!facility}
                            helperText={!facility ? m.statistics.selectFacility : undefined}
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography fontWeight="bold">Filter</Typography>
                        <Box my={2} />
                        <Grid container spacing={3}>
                            <Grid item sm={4} xs={12}>
                                <SelectInput
                                    size="small"
                                    label={m.common.type}
                                    options={[
                                        { label: m.common.all, value: 'All' },
                                        { label: m.transactions.direction.BookIn, value: 'BookIn' },
                                        { label: m.transactions.direction.BookOut, value: 'BookOut' }
                                    ]}
                                    value={direction}
                                    onChange={v => setDirection(v !== 'All' ? v : undefined)}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <MultiSelectInput
                                    size="small"
                                    label={m.transactions.purposes.plural}
                                    options={[
                                        { label: m.payments.types.Rangefee, value: AccountTransactionPurpose.Rangefee },
                                        { label: m.payments.types.BallVendingMachine, value: AccountTransactionPurpose.Bvm },
                                        { label: m.payments.types.InAppPayment, value: AccountTransactionPurpose.InAppPayment }
                                    ]}
                                    value={purposes}
                                    onChange={v => setPurposes(v as any)}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <MultiSelectInput
                                    disabled={!facility}
                                    size="small"
                                    label={m.common.note}
                                    options={facilityNotes.filter(n => !!n).map(n => ({ label: n, value: n }))}
                                    value={notes}
                                    onChange={v => setNotes(v as any)}
                                />
                            </Grid>
                        </Grid>
                        <Box my={3} />
                        <CheckboxInput
                            label={m.statistics.hideZeroAmount}
                            value={hideZeroAmountTransactions}
                            onChange={setHideZeroAmountTransactions}
                        />
                        <Box my={1} />
                        <CheckboxInput
                            label={m.statistics.hideIncomplete}
                            value={hideIncompleteTransactions}
                            onChange={setHideIncompleteTransactions}
                        />
                    </Grid>
                </Grid>
                <Box my={3} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <ActionButton color="primary" disabled={!facility || !timeframe.from || !timeframe.until} onClick={fetchStats}>{m.actions.view}</ActionButton>
                    <Box mx={1} />
                    <ActionButton disabled={!facility || !timeframe.from || !timeframe.until || !stats} onClick={handleExport}>{m.actions.export}</ActionButton>
                </Box>
            </ContentBody>
            <Box my={3} />
            <ContentBody>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{m.common.timestamp}</TableCell>
                                <TableCell>{m.wallets.clearedAt}</TableCell>
                                <TableCell>{m.accounts.singular}</TableCell>
                                <TableCell>{m.wallets.singular}</TableCell>
                                <TableCell>{m.common.type}</TableCell>
                                <TableCell>{m.transactions.purposes.singular}</TableCell>
                                <TableCell>{m.devices.singular}</TableCell>
                                <TableCell>{m.wallets.clerk}</TableCell>
                                <TableCell align="right">{m.common.amount} ({m.wallets.types.Facility})</TableCell>
                                <TableCell align="right">{m.common.amount} ({m.wallets.types.International})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={11}>{m.emptyContent}</TableCell>
                                </TableRow>
                            )}
                            {transactions.map((t, i) => <TransactionRow key={t.uuid} transaction={t} index={i+1} view="stats" />)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography fontSize="0.9rem"><strong>{m.common.total} {m.transactions.direction.BookIn}</strong></Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.1rem"><strong>€ {formatAmountDigit(totalBookIn)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography pl={3} fontSize="0.9rem">{m.common.total} {m.transactions.direction.BookIn} ({m.common.club})</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.0rem"><strong>€ {formatAmountDigit(totalBookInClub)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography pl={3} fontSize="0.9rem">{m.common.total} {m.transactions.direction.BookIn} ({m.common.international})</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.0rem"><strong>€ {formatAmountDigit(totalBookInIntl)}</strong></Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography fontSize="0.9rem"><strong>{m.common.total} {m.transactions.direction.BookOut}</strong></Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.1rem"><strong>€ {formatAmountDigit(totalBookOut)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography pl={3} fontSize="0.9rem">{m.common.total} {m.transactions.direction.BookOut} ({m.common.club})</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.0rem"><strong>€ {formatAmountDigit(totalBookOutClub)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography pl={3} fontSize="0.9rem">{m.common.total} {m.transactions.direction.BookOut} ({m.common.international})</Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.0rem"><strong>€ {formatAmountDigit(totalBookOutIntl)}</strong></Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography fontSize="0.9rem"><strong>{m.transactions.totalBallQuantity}</strong></Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.1rem"><strong>{totalBallQuantity}</strong> <BallsIcon sx={{ position: 'relative', top: '5px' }} /></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography fontSize="0.9rem"><strong>{m.common.serviceFee} ({facility?.pinpointServiceFee ?? '0'} %)</strong></Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.1rem"><strong>€ {formatAmountDigit(serviceFee)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography fontSize="0.9rem"><strong>{m.common.payout}</strong></Typography>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                <Typography fontSize="1.1rem"><strong>€ {formatAmountDigit(payout)}</strong></Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </ContentBody>
        </Fragment>
    )
}
