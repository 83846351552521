import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Alert, Box, Button, Paper, TextField, Typography} from "@mui/material";
import {theme} from "../../theme";
import Logo from "../../assets/logo_icon.svg";
import {useMessages} from "../../i18n";
import {resetPassword} from "../../data/public";
import {sha3} from "../../utils";

export const AccountResetPassword = () => {
    const {token} = useParams<{ token: string }>();
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false);

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.background = '#283035';
    }, []);

    const processReset = async () => {
        if (!!password && !!confirm && password === confirm && token) {
            try {
                await resetPassword(token, sha3.hash(password))
                setSuccess(true);
            } catch (error) {
                setFailure(true);
            }
        }
    }

    const m = useMessages();
    return (
        <Box my={3} width="100%" maxWidth={400} mx="auto">
            <Box component="div" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img style={{
                    width: 50,
                    position: 'relative',
                    marginRight: theme.spacing(2)
                }} src={Logo} alt="PINPOINT Smart App"/>
                <Typography align="center" fontSize="1.5rem" color='#fff' textAlign="left">
                    <strong>PINPOINT</strong>
                    <br/>
                    Smart App
                </Typography>
            </Box>
            <Box my={3} />
            <Paper>
                <Box p={2}>
                    <Typography align="center" fontSize="1.5rem"><strong>{m.resetPassword.title}</strong></Typography>
                    <Box my={3} />
                    {!success && (
                        <Fragment>
                            <TextField fullWidth variant="outlined" label={m.common.password} type="password" value={password} onChange={ev => setPassword(ev.target.value)} />
                            <Box my={2} />
                            <TextField fullWidth variant="outlined" label={m.common.confirmPassword} type="password" value={confirm} onChange={ev => setConfirm(ev.target.value)} />
                            <Box my={2} />
                        </Fragment>
                    )}
                    {success && <Alert severity="success" variant="filled">{m.resetPassword.success}</Alert>}
                    {failure && <Alert severity="error" variant="filled">{m.resetPassword.failed}</Alert>}
                    <Box my={2} />
                    {!success && (
                        <Button fullWidth variant="contained" onClick={processReset} disabled={!password || !confirm || password !== confirm || !token}>{m.actions.send}</Button>
                    )}
                </Box>
            </Paper>
        </Box>
    )
}