import {useNavigate} from "react-router";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Box, Dialog, DialogContent, Link, Typography, Alert, AlertTitle, DialogTitle} from "@mui/material";
import {login, resetPassword} from "../../data/auth";
import {AuthContext, UserContext} from "../../data/context";
import {ArrowLeftIcon} from "../../icons";
import {hashPassword} from "../../tools";
import {useMessages} from "../../i18n";
import {authStore} from "../../store";
import {Logo} from "../../graphic";
import {theme} from "../../theme";
import {ActionButton} from "../../component/buttons";
import {TextInput} from "../../component/TextInput";

export function Login() {
    const navigate = useNavigate();
    const {setUser} = useContext(UserContext);
    const {setAuthenticated} = useContext(AuthContext);
    const [resetMode, setResetMode] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    useEffect(() => {
        window.addEventListener('keyup', handleKeyboardEvent);
        return () => window.removeEventListener('keyup', handleKeyboardEvent);
    }, [resetMode, email, password])

    function switchMode() {
        setEmail('');
        setPassword('');
        setResetMode(!resetMode);
        setError(false);
        setResetSuccess(false);
    }

    async function handleKeyboardEvent(ev: KeyboardEvent) {
        if (ev.key === 'Enter') {
            if (resetMode) {
                await processResetPassword(email);
            } else {
                await processLogin(email, password);
            }
        }
    }

    async function processLogin(email: string, password: string) {
        setError(false);
        try {
            setInProgress(true);
            if (!!email && !!password) {
                const session = await login({ email, password: hashPassword(password) });
                if (!session) setError(true);
                else {
                    authStore.handleSession(session);
                    setUser(session.user);
                    setAuthenticated(true);
                    navigate('/dashboard', { replace: true });
                    return;
                }
            }
        } catch (err) {
            console.error('failed to login', err);
            setError(true);
        } finally {
            setInProgress(false);
        }
    }

    async function processResetPassword(email: string) {
        setError(false);
        try {
            setInProgress(true);
            if (!!email) {
                const result = await resetPassword(email);
                if (!result) setError(true);
                else setResetSuccess(true);
            }
        } catch (err) {
            console.error('failed to reset password', err);
            setError(true);
        } finally {
            setInProgress(false);
        }
    }

    const m = useMessages();
    return (
        <Dialog open={true} fullWidth maxWidth="xs">
            <DialogTitle component="div" style={{ background: '#283035', textAlign: 'center', paddingTop: theme.spacing(3) }}>
                <img src={Logo} alt="PINPOINT Center" width="150" style={{ display: 'inline-block' }} />
            </DialogTitle>
            <DialogContent>
                <Box my={3} />
                {!resetMode && (
                    <Fragment>
                        <Typography variant="h5" align="center">{m.login.title}</Typography>
                        <Box my={4} />
                        <TextInput
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off" type="email"
                            label={m.common.email} placeholder="name@..."
                            value={email} onChange={setEmail}
                        />
                        <Box my={2} />
                        <TextInput
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off" type="password"
                            label={m.common.password} placeholder="•••••"
                            value={password} onChange={setPassword}
                        />
                        <Box my={2} />
                        {error && (
                            <Alert severity="error" variant="filled">
                                <AlertTitle>{m.error.generic.title}</AlertTitle>
                                <Typography>{m.login.error}</Typography>
                            </Alert>
                        )}
                        <Box my={2} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Link onClick={switchMode}>{m.login.forgotPassword}</Link>
                            <Box sx={{ flexGrow: 1 }} />
                            <ActionButton
                                color="primary"
                                loading={inProgress}
                                onClick={() => processLogin(email, password)}
                            >
                                {m.login.btn}
                            </ActionButton>
                        </Box>
                    </Fragment>
                )}
                {resetMode && (
                    <Fragment>
                        <Typography variant="h5" align="center">{m.resetPassword.title}</Typography>
                        <Box my={4} />
                        <Typography>{m.resetPassword.hint}</Typography>
                        <Box my={2} />
                        <TextInput
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off" type="email"
                            label={m.common.email} placeholder="name@..."
                            value={email} onChange={setEmail}
                        />
                        <Box my={2} />
                        {error && (
                            <Alert severity="error" variant="filled">
                                <AlertTitle>Error</AlertTitle>
                                <Typography>{m.resetPassword.error}</Typography>
                            </Alert>
                        )}
                        {resetSuccess && (
                            <Alert severity="success" variant="filled">
                                <AlertTitle>Success</AlertTitle>
                                <Typography>{m.resetPassword.success}</Typography>
                            </Alert>
                        )}
                        <Box my={2} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Link onClick={switchMode} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <ArrowLeftIcon />
                                <Box mx={0.5} />
                                {m.resetPassword.backToLogin}
                            </Link>
                            <Box sx={{ flexGrow: 1 }} />
                            <ActionButton
                                color="primary"
                                loading={inProgress}
                                onClick={() => processResetPassword(email)}
                            >
                                {m.resetPassword.btn}
                            </ActionButton>
                        </Box>
                    </Fragment>
                )}
                <Box my={2} />
            </DialogContent>
        </Dialog>
    )
}