import {Paging} from "./pagings";
import {Api} from "./Api";
import {AuthSession, Page, User, UserCreateRequest, UserFilter, UserUpdateRequest} from "./types";

export const listUsers = (paging: Paging, needle?: string, filter?: UserFilter) => {
    const queries = [];
    if (needle) queries.push('needle=' + needle);
    if (filter) {
        if (filter.role) queries.push('role=' + filter.role);
        if (filter.facilityUuid) queries.push('facilityUuid=' + filter.facilityUuid);
    }
    const prefix = queries.length > 0 ? '?' + queries.join('&') : '';
    return Api.GET<Page<User>>('/users' + paging.toQueryString(prefix));
}

export const getUser = (uuid: string) => {
    return Api.GET<User>('/users/' + uuid);
}

export const createUser = (request: UserCreateRequest) => {
    return Api.POST<User>('/users', request);
}

export const updateUser = (uuid: string, request: UserUpdateRequest) => {
    return Api.PUT<User>('/users/' + uuid, request);
}

export const sendResetPassword = (uuid: string) => {
    return Api.POST('/users/' + uuid + '/reset-password', {});
}

export const deleteUser = (uuid: string) => {
    return Api.DELETE('/users/' + uuid);
}

export function userTakeOver(uuid: string) {
    return Api.POST<AuthSession>(`/users/${uuid}/takeover`, {});
}