import {useNavigate, useParams} from "react-router";
import {Fragment, useEffect, useState} from "react";
import {ApiError} from "../../data/Api";
import {deleteNotice, getNotice, publishNotice, updateNotice} from "../../data/notices";
import {EditFormProps, Notice, NoticeRequest} from "../../data/types";
import {useMessages} from "../../i18n";
import React from "react";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb, DeletePrompt} from "../../component/ContentLayout";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {LabeledText} from "../../component/LabeledText";
import {Link} from "react-router-dom";
import {CheckIcon, EditIcon} from "../../icons";
import {NoticeForm} from "./NoticeForm";
import {displayDate} from "../../utils";
import {ActionButton} from "../../component/buttons";
import {authStore} from "../../store";

export const NoticeDetails = () => {
    const navigate = useNavigate();
    const {uuid = ''} = useParams();
    const [notice, setNotice] = useState<Notice>();
    const [editMode, setEditMode] = useState(false);
    const [publishMode, setPublishMode] = useState(false);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getNotice(uuid)
            .then(setNotice)
            .catch(setError);
    }, [])

    function handleOnSave(n: Notice) {
        setEditMode(false);
        setNotice(n);
    }

    function handleOnDelete() {
        deleteNotice(uuid)
            .then(() => navigate('/notices'))
            .catch(setError);
    }

    function handlePublishNotice() {
        publishNotice(uuid)
            .then(setNotice)
            .catch(setError)
            .finally(() => setPublishMode(false))
    }

    const m = useMessages();
    return (
        <Fragment>
            <ContentTitle title={notice?.title || m.notices.singular} />
            <AppBreadcrumbs>
                <Crumb title={m.notices.plural} path="/notices" />
                <Crumb title={m.actions.view} />
            </AppBreadcrumbs>
            <Box my={3} />
            {notice && (
                <Fragment>
                    {editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Edit data={notice}
                                      onCancel={() => setEditMode(false)}
                                      onSave={handleOnSave}
                                      onError={setError}
                                />
                            </ContentBody>
                            <Box my={3} />
                            {(authStore.isAdmin || authStore.isRoot) && (
                                <DeletePrompt title={m.actions.delete}
                                              message={m.notices.delete.prompt}
                                              onDelete={handleOnDelete}
                                />
                            )}
                        </Fragment>
                    )}
                    {!editMode && (
                        <Fragment>
                            <ContentBody padding={2}>
                                <Details notice={notice}
                                         onEdit={() => setEditMode(true)}
                                         onPublish={() => setPublishMode(true)}
                                />
                            </ContentBody>
                            <Dialog open={publishMode} fullWidth maxWidth="sm">
                                <DialogTitle>{m.actions.publish}</DialogTitle>
                                <DialogContent>
                                    <Typography>{m.notices.publish.prompt}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <ActionButton color="primary" onClick={handlePublishNotice}>{m.actions.publish}</ActionButton>
                                    <ActionButton onClick={() => setPublishMode(false)}>{m.actions.cancel} </ActionButton>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

const Details = ({notice, onEdit, onPublish}: { notice: Notice, onEdit: () => void, onPublish: () => void }) => {
    const m = useMessages();
    return (
        <Fragment>
            <LabeledText label={m.facilities.singular}>
                {notice.facility ? <Link to={'/facilities/' + notice.facility.uuid}>{notice.facility.name}</Link> : '-'}
            </LabeledText>
            <Box mx={2} my={1} />
            <LabeledText label={m.common.createdAt}>
                {displayDate(notice.createdAt)}
            </LabeledText>
            <Box mx={2} my={1} />
            <LabeledText label={m.common.publishedAt}>
                {notice.publishedAt ? displayDate(notice.publishedAt) : '-'}
            </LabeledText>
            <Box mx={2} my={1} />
            <LabeledText label={m.common.title}>
                {notice.title}
            </LabeledText>
            <Box mx={2} my={1} />
            <LabeledText label={m.common.message}>
                <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(escape(atob(notice.content)))
                        .replace('\r\n', '<br>')
                        .replace('\n', '<br>')
                }} />
            </LabeledText>
            <Box my={1} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined"
                        startIcon={<CheckIcon />}
                        onClick={onPublish}
                        disabled={!!notice.publishedAt}
                >
                    {m.actions.publish}
                </Button>
                <Box mx={1} />
                <Button variant="outlined" color="primary"
                        startIcon={<EditIcon />}
                        onClick={onEdit}>{m.actions.edit}</Button>
            </Box>
        </Fragment>
    )
}

const Edit = ({data, onSave, onError, onCancel}: EditFormProps<Notice>) => {
    const [inProgress, setInProgress] = useState(false);

    const update = async (form: NoticeRequest) => {
        setInProgress(true);
        try {
            const result = await updateNotice(data.uuid, form);
            onSave(result);
            return;
        } catch (error) {
            onError(error as any);
        }
        setInProgress(false);
    }

    return (
        <NoticeForm notice={data}
                    inProgress={inProgress}
                    onCancel={onCancel}
                    onSave={update}
        />
    )
}