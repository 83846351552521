import React, {Fragment, useMemo, useState} from "react";
import {
    Badge as MBadge,
    Box,
    Button, Chip, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";
import {AppBreadcrumbs, AppFab, ContentTitle, Crumb} from "../../component/ContentLayout";
import {useMessages} from "../../i18n";

import {useNavigate} from "react-router";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {authStore, uiStore} from "../../store";
import {AccountFilter, AccountGroup, AccountGroupFilter, Page} from "../../data/types";
import {accountGroupPaging, PagingSettings} from "../../data/pagings";
import {ApiError} from "../../data/Api";
import {listAccountGroups} from "../../data/accounts";
import {FilterIcon, PlusIcon} from "../../icons";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import useAsyncEffect from "use-async-effect";
import {listFacilities} from "../../data/facilities";
import {SearchInput} from "../../component/SearchInput";
import {displayDate} from "../../utils";

export const AccountGroupList = () => {
    const navigate = useNavigate();
    const m = useMessages();
    const columns: ColumnType = {
        'name': { name: m.common.name },
        'facility': { name: m.facilities.singular },
        'expirationDate': { name: m.accounts.expirationDate },
        'startHour': { name: m.accounts.startHour },
        'endHour': { name: m.accounts.endHour }
    };
    if (!authStore.isRoot) delete columns['facility'];

    const [page, setPage] = useState<Page<AccountGroup>>();
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<AccountGroupFilter>(uiStore.getAccountGroupFilter() || {});
    const [needle, setNeedle] = useState('');
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(accountGroupPaging.getSettings());
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {facilityUuid} = filter;
        return (!!facilityUuid && facilityUuid !== 'ALL');
    }, [filter])

    async function fetch(needle?: string, filter?: AccountGroupFilter) {
        try {
            const page = await listAccountGroups(accountGroupPaging, needle, filter);
            setPage(page);
        } catch (err) {
            setError(err as any);
        }
    }

    useAsyncEffect(async () => {
        if (authStore.isRoot) {
            try {
                const page = await listFacilities();
                setFacilities(page.content.map(f => ({ label: f.name, value: f.uuid })))
            } catch (err) {
                console.error('failed to list facilities', err);
            }
        }
        await fetch(needle, filter);
    }, [])

    async function handleNeedleChange(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        accountGroupPaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        await fetch(needle, filter);
    }

    async function handlePagingChange(pageable: PagingSettings<any>) {
        accountGroupPaging.updateSettings(pageable);
        setPageable(pageable);
        await fetch(needle, filter);
    }

    async function handleFilterSubmit(f?: AccountFilter) {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        accountGroupPaging.updateSettings(adjusted);
        setPageable(adjusted);
        uiStore.setAccountGroupFilter(f);
        await fetch(needle, f);
        setShowFilter(false);
        setFilter(f);
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.accounts.groups} />
                    <AppBreadcrumbs>
                        <Crumb title={m.accounts.plural} path="/accounts" />
                        <Crumb title={m.common.groups} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <SearchInput onSubmit={handleNeedleChange} />
                        {authStore.isRoot && (
                            <Fragment>
                                <Box mx={1} />
                                <IconButton onClick={() => setShowFilter(true)}>
                                    <MBadge color="primary" variant="dot" invisible={!filterActive}>
                                        <FilterIcon />
                                    </MBadge>
                                </IconButton>
                            </Fragment>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(g => (
                            <TableRow key={g.uuid} onClick={() => navigate('/accounts/groups/' + g.uuid)}>
                                <TableCell>
                                    {g.name} {g.defaultOnSignup && <Chip color="primary" size="small" label={m.common.default} />}
                                </TableCell>
                                {authStore.isRoot && <TableCell>{g.facility.name}</TableCell>}
                                <TableCell>{g.expirationDate ? displayDate(g.expirationDate) : '-'}</TableCell>
                                <TableCell>{g.startHour ?? '.'}</TableCell>
                                <TableCell>{g.endHour ?? '.'}</TableCell>
                            </TableRow>
                        ))}
                    </Fragment>
                )}
            />
            <AppFab onClick={() => navigate('/accounts/groups/create')} children={<PlusIcon />}/>

            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SelectInput
                                label={m.facilities.singular}
                                options={facilities}
                                value={filter.facilityUuid ?? ''}
                                onChange={facilityUuid => setFilter({ ...filter, facilityUuid })}
                                searchable
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}
