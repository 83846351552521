import React from "react";
import {App} from "./App";
import {createRoot} from "react-dom/client";

const head = document.getElementsByTagName('head');
if (head) {
    const style = document.createElement('style');
    style.innerHTML = '#content { width: 100%; height: 100%; }';
    (head.item(0) as HTMLElement).append(style);
}

const root = createRoot(document.getElementById("content")!);
root.render(<App />)
