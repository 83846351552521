import React, {Fragment} from "react";
import {FormControlLabel, Checkbox as CB, Typography} from "@mui/material";

type CheckboxProps = {
    label: string,
    secondaryText?: string,
    name?: string,
    value?: boolean,
    onChange?: (value: boolean, name?: string) => void,
    disabled?: boolean,
    className?: string,
    color?: 'primary'|'secondary',
}

export const CheckboxInput = ({label, secondaryText, name, value, onChange, disabled, className, color}: CheckboxProps) => {
    return (
        <FormControlLabel
            control={
                <CB color={color || 'primary'}
                    checked={value}
                    onChange={e => { if (onChange) onChange(e.target.checked, name) }}
                />
            }
            label={<Fragment>
                <Typography>{label}</Typography>
                {secondaryText && (<small>{secondaryText}</small>)}
            </Fragment>}
            disabled={disabled}
            className={className}
        />
    )
}