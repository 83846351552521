import {createTheme} from "@mui/material/styles";
import {blueGrey} from "@mui/material/colors";
import {lighten} from "@mui/material";

import BVNPExtraLight from "./assets/BeVietnamPro-ExtraLight.woff2";
import BVNPRegular from "./assets/BeVietnamPro-Regular.woff2";
import BVNPSemiBold from "./assets/BeVietnamPro-SemiBold.woff2";
import BVNPBold from "./assets/BeVietnamPro-Bold.woff2";

export const drawerWidth = 280;
export const borderRadius = 10;
export const borderColor = blueGrey['50'];

const primaryColor = '#ff5e43';
const secondaryColor = '#d09e0b';

const baseTheme = createTheme();
export const theme = createTheme({
    typography: {
        fontFamily: `'BVNP', serif`,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 800,
        body1: {
            fontSize: '0.85rem',
            lineHeight: '1.5em'
        }
    },
    palette: {
        background: {
            default: '#fff'
        },
        grey: blueGrey,
        primary: {
            main: primaryColor,
            light: lighten(primaryColor, 0.5)
        },
        secondary: {
            main: secondaryColor
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'BVNP'; font-style: normal; font-display: swap;
                  font-weight: 300;
                  src: url(${BVNPExtraLight}) format('woff2');
                }
                @font-face {
                  font-family: 'BVNP'; font-style: normal; font-display: swap;
                  font-weight: 400;
                  src: url(${BVNPRegular}) format('woff2');
                }
                @font-face {
                  font-family: 'BVNP'; font-style: normal; font-display: swap;
                  font-weight: 600;
                  src: url(${BVNPSemiBold}) format('woff2');
                }
                @font-face {
                  font-family: 'BVNP'; font-style: normal; font-display: swap;
                  font-weight: 800;
                  src: url(${BVNPBold}) format('woff2');
                }
                a {
                  color: ${primaryColor};
                  text-decoration: none !important;
                  cursor: pointer;
                }
                a:hover {
                  color: ${lighten(primaryColor, 0.3)};
                }
            `,
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 7
                },
                input: {
                    fontSize: '0.9rem',
                    // padding: '16px 14px'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.9rem',
                    top: -2
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.9rem',
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: '10px !important',
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '0 24px 20px',
                    [baseTheme.breakpoints.down('sm')]: {
                        flexDirection: 'column-reverse',
                        '&>:not(:first-of-type)': {
                            margin: 0
                        }
                    }
                }
            }
        }
    }
});