import {createContext} from "react";
import {User} from "./types";

export const AuthContext = createContext({
    authenticated: false,
    setAuthenticated: (isAuth: boolean) => {}
});


export const UserContext = createContext({
    user: {} as User,
    setUser: (user: User) => {}
})