import React from "react";
import {CircularProgress, CircularProgressProps, LinearProgress, LinearProgressProps} from "@mui/material";

interface LoadingProps {
    type: 'linear'|'circular';
    CircularProgressProps?: CircularProgressProps,
    LinearProgressProps?: LinearProgressProps,
}

export function Loading(props: LoadingProps) {
    const {
        type = 'linear',
        CircularProgressProps = {
            size: 20
        },
        LinearProgressProps = {},
    } = props;

    if (type === 'circular') {
        return (<CircularProgress {...CircularProgressProps} />);
    }

    return (<LinearProgress {...LinearProgressProps} />)
}