import React, {ChangeEvent} from "react";
import {TextField, TextFieldProps} from "@mui/material";

interface TextInputProps extends Omit<TextFieldProps, 'onChange'> {
    onChange?: (value: any) => void,
}
export function TextInput(props: TextInputProps) {
    const {
        fullWidth = true,
        variant = 'outlined',
        onChange,
        ...rest
    } = props;

    function handleChange(ev: ChangeEvent<HTMLInputElement>) {
        if (onChange) {
            onChange(ev.target.value);
        }
    }

    return (
        <TextField
            onChange={handleChange}
            {...{ fullWidth, variant }}
            {...rest}
        />
    )
}