import {useMessages} from "../../i18n";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {authStore, uiStore} from "../../store";
import React, {Fragment, useCallback, useMemo, useState} from "react";
import {
    AccountTransaction, AccountTransactionPurpose,
    Page,
    PaymentFilter,
} from "../../data/types";
import {paymentPaging, PagingSettings} from "../../data/pagings";
import {ApiError} from "../../data/Api";
import {
    Badge as MBadge,
    Box,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton,
    TableCell,
    TableRow, Typography
} from "@mui/material";
import {AppBreadcrumbs, ContentTitle, Crumb} from "../../component/ContentLayout";
import {SearchInput} from "../../component/SearchInput";

import {
    displayAddressLine,
    displayDateTime,
    displayTransactionBillingName
} from "../../utils";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {FilterIcon, PlusIcon} from "../../icons";
import {listPayments} from "../../data/payments";
import useAsyncEffect from "use-async-effect";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {listFacilities} from "../../data/facilities";
import {MultiSelectInput} from "../../component/MultiSelectInput";
import {DateSelector} from "../../component/DateSelector";
import {formatAmountDigit} from "../../tools";
import {LabeledText} from "../../component/LabeledText";
import {theme} from "../../theme";
import {Badge} from "../../component/Badge";

export const PaymentList = () => {
    const m = useMessages();

    const columns: ColumnType = {
        'facility.name': { name: m.facilities.singular },
        'transactionId': { name: 'ID' },
        'timestamp': { name: m.common.timestamp },
        'account.firstName': { name: m.accounts.singular },
        'type': { name: m.common.type },
        'transactionAmount': { name: m.common.amount },

    }
    if (!authStore.isRoot) {
        delete columns['facility.name'];
    }

    const [page, setPage] = useState<Page<AccountTransaction>>();
    const [payment, setPayment] = useState<AccountTransaction>();
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<PaymentFilter>(uiStore.getPaymentFilter() || {});
    const [needle, setNeedle] = useState('');
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(paymentPaging.getSettings());
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {facilityUuid, from, until, purposes} = filter;
        return (!!facilityUuid && facilityUuid !== 'ALL') || from || until || purposes?.length;
    }, [filter])

    const fetch = useCallback(async (filter: PaymentFilter, needle?: string) => {
        try {
            const page = await listPayments(paymentPaging, filter, needle);
            setPage(page);
        } catch (err) {
            setError(err as any);
        }
    }, [])

    useAsyncEffect(async () => {
        if (authStore.isRoot) {
            try {
                const page = await listFacilities();
                setFacilities(page.content.map(f => ({ label: f.name, value: f.uuid })))
            } catch (err) {
                console.error('failed to list facilities', err);
            }
        }
        await fetch(filter, needle);
    }, [])

    async function handlePagingChange(pageable: PagingSettings<any>) {
        paymentPaging.updateSettings(pageable);
        setPageable(pageable);
        await fetch(filter, needle);
    }

    async function handleFilterSubmit(f?: PaymentFilter) {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        paymentPaging.updateSettings(adjusted);
        setPageable(adjusted);
        uiStore.setPaymentFilter(f);
        await fetch(f, needle);
        setShowFilter(false);
        setFilter(f);
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }

    async function handleNeedleSubmit(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        paymentPaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        await fetch(filter, needle);
    }

    function renderTransactionId(transaction?: AccountTransaction) {
        if (transaction && transaction.transactionId) return (
            <Badge
                size="small"
                label={transaction.transactionId}
                style={{
                    width: '100px',
                    background: theme.palette.grey["500"]
                }}
            />
        );
        return '-';
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.payments.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.payments.plural} path="/notices" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} >
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <SearchInput onSubmit={handleNeedleSubmit} />
                        <Box mx={1} />
                        <IconButton onClick={() => setShowFilter(true)}>
                            <MBadge color="primary" variant="dot" invisible={!filterActive}>
                                <FilterIcon />
                            </MBadge>
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(t => (
                            <TableRow key={'transaction-' + t.uuid} onClick={() => setPayment(t)}>
                                {authStore.isRoot && <TableCell>{t.facility?.name ?? '-'}</TableCell>}
                                <TableCell>{renderTransactionId(t)}</TableCell>
                                <TableCell>{displayDateTime(t.timestamp)}</TableCell>
                                <TableCell>{displayTransactionBillingName(t)}</TableCell>
                                <TableCell>{t.transactionPurpose}</TableCell>
                                <TableCell>{'€ ' + formatAmountDigit(t.transactionAmount)}</TableCell>
                            </TableRow>
                        ))}
                    </Fragment>
                )}
            />
            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        {authStore.isRoot && (
                            <Grid item sm={6} xs={12}>
                                <SelectInput
                                    label={m.facilities.singular}
                                    options={facilities}
                                    value={filter.facilityUuid ?? ''}
                                    onChange={facilityUuid => setFilter({ ...filter, facilityUuid })}
                                    searchable
                                />
                            </Grid>
                        )}
                        <Grid item sm={authStore.isRoot ? 6 : 12} xs={12}>
                            <MultiSelectInput
                                label='Filter'
                                options={[
                                    { label: m.payments.types.BallVendingMachine, value: AccountTransactionPurpose.Bvm },
                                    { label: m.payments.types.Rangefee, value: AccountTransactionPurpose.Rangefee },
                                    { label: m.payments.types.InAppPayment, value: AccountTransactionPurpose.InAppPayment },
                                ]}
                                value={filter.purposes}
                                onChange={(purposes: any[]) => setFilter({ ...filter, purposes })}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <DateSelector
                                label={m.common.timeframe.from}
                                date={filter.from}
                                onChange={d => setFilter({ ...filter, from: d || undefined })}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <DateSelector
                                label={m.common.timeframe.until}
                                date={filter.until}
                                onChange={d => setFilter({ ...filter, until: d || undefined })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {payment && (
                <Dialog
                    open={true} fullWidth maxWidth="sm"
                    onClose={() => setPayment(undefined)}
                >
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                px: 2
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: '2rem' }}>{m.payments.info}</Typography>
                            <Typography
                                align="center"
                                color="textSecondary"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {m.payments.singular}&nbsp;#&nbsp;{renderTransactionId(payment)}
                            </Typography>
                            <Box my={1} />
                            <Grid container spacing={2}>
                                <Grid item sm={3} xs={12}>
                                    <LabeledText label={m.common.amount}>
                                        € {payment.transactionAmount}
                                    </LabeledText>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <LabeledText label={m.common.timestamp}>
                                        {displayDateTime(payment.timestamp)}
                                    </LabeledText>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <LabeledText label={m.common.type}>
                                        {payment.transactionPurpose && m.transactions.purposes[payment.transactionPurpose]}
                                    </LabeledText>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={1}>&nbsp;</Box>
                        <Typography color="textSecondary" sx={{ textTransform: 'uppercase' }}><strong>{m.common.summary}</strong></Typography>
                        <Box my={2} />
                        <Grid container spacing={2}>
                            {payment.facility && (
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="overline"><strong>{m.facilities.singular}</strong></Typography>
                                    <Typography>{payment.facility.name}</Typography>
                                    <Typography>{displayAddressLine(payment.facility.address ?? {})}</Typography>
                                </Grid>
                            )}
                            <Grid item sm={6} xs={12}>
                                <Typography variant="overline"><strong>{m.accounts.singular}</strong></Typography>
                                <Typography>{displayTransactionBillingName(payment)}</Typography>
                                <Typography>{displayAddressLine(payment.billingAddress ?? {})}</Typography>
                            </Grid>
                        </Grid>
                        <Box my={1} />
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="overline"><strong>{m.wallets.singular}</strong></Typography>
                                <Typography>{payment ? m.wallets.types[payment.wallet.type] : '--'}</Typography>
                            </Grid>
                            {payment.device && (
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="overline"><strong>{m.devices.singular}</strong></Typography>
                                    <Typography>{payment.device.id + ' - ' + payment.device.name}</Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Box my={3} />
                        <Box
                            sx={{
                                background: theme.palette.grey["50"],
                                p: 2
                            }}
                        >
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography color="textSeondary">{payment.note}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSeondary" align="right">€ {formatAmountDigit(payment.transactionAmount)}</Typography>
                                </Grid>
                            </Grid>
                            <Box my={1}><Divider /></Box>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography sx={{ fontSize: '1.1rem' }}><strong>{m.payments.total}</strong></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontSize: '1.1rem' }} align="right"><strong>€ {formatAmountDigit(payment.transactionAmount)}</strong></Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={2} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button variant="outlined" onClick={() => setPayment(undefined)}>{m.actions.close}</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}