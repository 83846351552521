import React, {Fragment, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {
    FacilityCreate,
    FacilityDetails,
    FacilityList,
} from "./page/facility";
import {UserCreate, UserList} from "./page/user";
import {AdminDashboard, Preferences, RootDashboard} from "./page/general";
import {
    AccountCreate,
    AccountDetails, AccountGroupCreate, AccountGroupDetails, AccountGroupList,
    AccountList, AccountProfileConfirmation,
    AccountResetPassword,
    AccountSignUpConfirmation
} from "./page/account";
import {DeviceDetails, DeviceList} from "./page/device";
import {WalletDetails, WalletList, WalletTypeSelector} from "./page/wallet";
import {UserDetails} from "./page/user/UserDetails";
import {AppContainer} from "./component/AppContainer";
import {User, WalletType} from "./data/types";
import {AuthContext, UserContext} from "./data/context";
import {authStore} from "./store";
import {theme} from "./theme"
import {StatisticTransactionsView, StatisticActivitiesView, StatisticSelector} from "./page/statistic";
import {Login, ResetPassword, UserAcceptance} from "./page/auth";
import {NoticeDetails, NoticeList, NoticeCreate} from "./page/notice";
import {PaymentList} from "./page/payment";
import {Home} from "./page/general/Home";

export const App = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState<User>({} as User);

    useEffect(() => {
        authStore.load()
            .then(u => {
                if (u) {
                    setUser(u);
                    setAuthenticated(true);
                }
            });
    }, []);

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <StyledEngineProvider injectFirst>
                    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
                        <UserContext.Provider value={{ user, setUser }}>
                            <BrowserRouter>
                                <AppContainer>
                                    <Routes>
                                        <Route path="login" element={<Login />} />
                                        <Route path="reset-password/:token" element={<ResetPassword />} />
                                        <Route path="user-accepts/:token" element={<UserAcceptance />} />

                                        <Route path="/app/reset-password/:token" element={<AccountResetPassword />} />
                                        <Route path="/app/signup/cf/:type/:token" element={<AccountSignUpConfirmation />} />
                                        <Route path="/app/profile/cf/:type/:token" element={<AccountProfileConfirmation />} />

                                        <Route path="/preferences" element={<Preferences />} />

                                        <Route path="" element={<Home />}></Route>
                                        <Route path="dashboard" element={authStore.isRoot ? <RootDashboard /> : <AdminDashboard />} />

                                        {authStore.isRoot && (
                                            <Route path="facilities">
                                                <Route index element={<FacilityList />} />
                                                <Route path="create" element={<FacilityCreate />} />
                                                <Route path=":uuid" element={<FacilityDetails />} />
                                            </Route>
                                        )}

                                        <Route path="statistics">
                                            <Route index element={<StatisticSelector />} />
                                            <Route path="activities" element={<StatisticActivitiesView />} />
                                            <Route path="transactions" element={<StatisticTransactionsView />} />
                                        </Route>

                                        {(authStore.isRoot || authStore.isAdmin) && (
                                            <Route path="users">
                                                <Route index element={<UserList />} />
                                                <Route path="create" element={<UserCreate />} />
                                                <Route path=":uuid" element={<UserDetails />} />
                                            </Route>
                                        )}

                                        {(!authStore.isRoot && authStore.isAdmin) && (
                                            <Route path="facility">
                                                <Route index element={<FacilityDetails />} />
                                                {/*<Route path="permissions/create" element={<FacilityPermissionGroupCreate />} />*/}
                                                {/*<Route path="permissions/:uuid" element={<FacilityPermissionGroupDetails />} />*/}
                                            </Route>
                                        )}

                                        <Route path="accounts">
                                            <Route index element={<AccountList />} />
                                            <Route path="create" element={<AccountCreate />} />
                                            <Route path="groups" element={<AccountGroupList />} />
                                            <Route path="groups/create" element={<AccountGroupCreate />} />
                                            <Route path="groups/:uuid" element={<AccountGroupDetails />} />
                                            <Route path=":uuid" element={<AccountDetails />} />
                                        </Route>

                                        <Route path="devices">
                                            <Route index element={<DeviceList />} />
                                            <Route path=":id" element={<DeviceDetails />} />
                                        </Route>

                                        <Route path="wallets">
                                            <Route index element={authStore.isRoot ? <WalletTypeSelector /> : <WalletList />} />
                                            {authStore.isRoot && <Route path="international" element={<WalletList type={WalletType.International} />} />}
                                            {authStore.isRoot && <Route path="facility" element={<WalletList type={WalletType.Facility} />} />}
                                            <Route path=":uuid" element={<WalletDetails />} />
                                        </Route>

                                        <Route path="notices">
                                            <Route index element={<NoticeList />} />
                                            <Route path="create" element={<NoticeCreate />} />
                                            <Route path=":uuid" element={<NoticeDetails />} />
                                        </Route>

                                        <Route path="payments">
                                            <Route index element={<PaymentList />} />
                                        </Route>
                                    </Routes>
                                </AppContainer>
                            </BrowserRouter>
                        </UserContext.Provider>
                    </AuthContext.Provider>
                </StyledEngineProvider>
            </ThemeProvider>
        </Fragment>
    )
};
