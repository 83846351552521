import {AuthSession, LoginRequest} from "./types";
import {luxonDate} from "../tools";
import {Api} from "./Api";

export const authorize = async () => {
    try {
        const session = await Api.GET<AuthSession>("");
        if (session) {
            return parseAuthSession(session);
        }
    } catch (error) {
        console.error('Failed to authorized user', error);
    }
    return null;
}

export const login = async (request: LoginRequest) => {
    try {
        const response = await fetch('/api/center-auth/login', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        });
        if (response.ok) {
            return parseAuthSession(await response.json() as AuthSession);
        }
    } catch (error) {
        console.error('Failed to login user', error);
    }
    return null;
}

export const logout = async () => {
    try {
        await Api.DELETE<AuthSession>("");
    } catch (error) {
        console.error('Failed to logout user', error);
    }
}

export const resetPassword = async (email: string) => {
    try {
        const response = await fetch('/api/center-auth/reset-password', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        });
        if (response.ok) {
            return true;
        }
    } catch (error) {
        console.error('Failed to reset user password', error);
    }
    return false;
}

export const confirmResetPassword = async (token: string, password: string) => {
    try {
        const response = await fetch('/api/center-auth/reset-password/' + token, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password })
        });
        if (response.ok) {
            return true;
        }
    } catch (error) {
        console.error('Failed to confirm password reset', error);
    }
    return false;
}

export const changePassword = async (currentPassword: string, newPassword: string) => {
    await Api.PUT("/change-password", { currentPassword, newPassword });
}

export const updateProfile = async (jobTitle: string, name: string) => {
    await Api.PUT("/update-profile", { jobTitle, name });
}

function parseAuthSession(session: AuthSession) {
    session.expiredAt = luxonDate(session.expiredAt);
    return session;
}