import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ActionButton} from "./buttons";
import {useMessages} from "../i18n";

interface ConfirmDialogProps {
    open: boolean;
    title?: string;
    message?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export function ConfirmDialog({open, title, message, onConfirm, onCancel}: ConfirmDialogProps) {
    const m = useMessages();
    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            {title && <DialogTitle>{title}</DialogTitle>}
            {message && <DialogContent>{message}</DialogContent>}
            <DialogActions>
                {onConfirm && <ActionButton color="primary" onClick={onConfirm}>{m.actions.confirm}</ActionButton>}
                {onCancel && <ActionButton variant="text" onClick={onCancel}>{m.actions.cancel}</ActionButton>}
            </DialogActions>
        </Dialog>
    )
}