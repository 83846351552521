import React, {Fragment, useState} from "react";
import {Alert, Box, Button, Dialog, DialogContent, Grid, TableCell, TableRow, Typography} from "@mui/material";
import {formatAmountDigit, luxonDate} from "../tools";
import {AccountTransaction, AccountTransactionType, WalletType} from "../data/types";
import {useMessages} from "../i18n";
import {deepOrange, green} from "@mui/material/colors";
import {NewTab} from "../icons";
import {displayDateTime, displayTransactionBillingName, displayUserNameLine} from "../utils";
import {authStore} from "../store";
import {ActionButton} from "./buttons";

interface Props {
    transaction: AccountTransaction;
    view?: 'stats'|'dashboard';
    index?: number;

}

export function TransactionRow({transaction: t, index, view}: Props) {
    const [info, setInfo] = useState(false);
    const positiveValue = [AccountTransactionType.ReceiveCredit, AccountTransactionType.PurchaseCredit].indexOf(t.type) > -1;

    const colorSx = { color: positiveValue ? green['400'] : deepOrange['A400'] };
    const m = useMessages();

    function renderText() {
        let text = '';
        text += t.wallet?.facility?.name || 'PINPOINT';
        if (t.device) text += ' / ' + t.device.name;
        return text;
    }

    function renderAmount(t: AccountTransaction) {
        return (
            <Typography sx={colorSx}>
                <strong style={{ color: !t.clearedAt ? '#999' : undefined }}>{positiveValue ? '+' : '-'} € {formatAmountDigit(t.transactionAmount)}</strong>
            </Typography>
        )
    }

    function renderDashboardView() {
        return (
            <TableRow hover sx={{ '& > td': { fontSize: '0.7rem' } }} style={{ cursor: 'pointer' }} onClick={() => setInfo(true)}>
                <TableCell style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>{displayDateTime(t.timestamp)}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{displayTransactionBillingName(t)}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.wallet.type === WalletType.Facility ? m.common.club : m.common.international}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{m.transactions.types[t.type]}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.transactionPurpose && m.transactions.purposes[t.transactionPurpose]}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.device ? (t.device.name || t.device.id) : '-'}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">{renderAmount(t)}</TableCell>
            </TableRow>
        )
    }

    function renderStatView() {
        let accountName = displayTransactionBillingName(t);
        if (accountName.length > 20) accountName = accountName.substring(0, 17) + '...';
        return (
            <TableRow hover sx={{ '& > td': { fontSize: '0.7rem' } }} style={{ cursor: 'pointer' }} onClick={() => setInfo(true)}>
                <TableCell>{index}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>{displayDateTime(t.timestamp)}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>{t.clearedAt ? displayDateTime(t.clearedAt) : '-'}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{accountName}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{m.wallets.types[t.wallet.type]}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{m.transactions.types[t.type]}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.transactionPurpose && m.transactions.purposes[t.transactionPurpose]}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.device ? (t.device.name || t.device.id) : '-'}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{t.user ? displayUserNameLine(t.user) : '-'}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
                    {t.wallet.type === WalletType.Facility && renderAmount(t)}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
                    {t.wallet.type === WalletType.International && renderAmount(t)}
                </TableCell>
            </TableRow>
        )
    }

    function renderView() {
        return (
            <TableRow style={{ cursor: 'pointer' }} onClick={() => setInfo(true)}>
                <TableCell style={{ fontFamily: 'monospace' }}>{displayDateTime(t.timestamp)}</TableCell>
                <TableCell style={{ fontFamily: 'monospace' }}>{t.clearedAt ? displayDateTime(t.clearedAt) : '-'}</TableCell>
                <TableCell>{m.transactions.types[t.type]}</TableCell>
                <TableCell>{t.device?.name ?? '-'}</TableCell>
                {t.wallet.type !== WalletType.International && <TableCell>{t.user ? displayUserNameLine(t.user) : '-'}</TableCell>}
                <TableCell align="right">
                    {renderAmount(t)}
                </TableCell>
                <TableCell align="right">
                    <Typography><strong>€ {formatAmountDigit(t.newAmount)}</strong></Typography>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Fragment>
            {!view && renderView()}
            {view === 'stats' && renderStatView()}
            {view === 'dashboard' && renderDashboardView()}
            <Dialog open={info} onClose={() => setInfo(false)} fullWidth maxWidth="sm">
                <DialogContent>
                    <Typography fontSize="1.2rem"><strong>{m.transactions.types[t.type]}</strong></Typography>
                    <Box my={1} />
                    <Typography>{renderText()}</Typography>
                    <Box my={2} />
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <Typography variant="overline"><small>{m.common.timestamp}</small></Typography>
                            <Typography>{luxonDate(t.timestamp).toJSDate().toLocaleString()}</Typography>
                            <Box my={1} />
                            <Typography variant="overline"><small>{m.common.amount}</small></Typography>
                            <Typography sx={colorSx}>
                                <strong style={{ color: !t.clearedAt ? '#999' : undefined }}>{positiveValue ? '+' : '-'} € {formatAmountDigit(t.transactionAmount)}</strong>
                            </Typography>
                            <Box my={1} />
                            <Typography variant="overline"><small>{m.wallets.previousAmount}</small></Typography>
                            <Typography>€ {formatAmountDigit(t.previousAmount || 0)}</Typography>
                            {t.clearedAt && (t.wallet.type === WalletType.Facility || authStore.isRoot) && (
                                <Fragment>
                                    <Box my={1} />
                                    <Typography variant="overline"><small>{m.wallets.newAmount}</small></Typography>
                                    <Typography>€ {formatAmountDigit(t.newAmount || 0)}</Typography>
                                </Fragment>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography variant="overline"><small>{m.common.note}</small></Typography>
                            <Typography>{t.note ?? '-'}</Typography>
                            {t.stripeId && (
                                <Fragment>
                                    <Box my={1} />
                                    <Typography variant="overline"><small>Stripe</small></Typography>
                                    <br/>
                                    <Button href={'https://dashboard.stripe.com/payments/' + t.stripeId} target="_blank" endIcon={<NewTab />} size="small" variant="outlined">{m.actions.open}</Button>
                                </Fragment>
                            )}
                            {t.device && (
                                <Fragment>
                                    <Box my={1} />
                                    <Typography variant="overline"><small>{m.devices.singular}</small></Typography>
                                    <Typography>{t.device.name}</Typography>
                                </Fragment>
                            )}
                            {t.transactionPortName && (
                                <Fragment>
                                    <Box my={1} />
                                    <Typography variant="overline"><small>Port</small></Typography>
                                    <Typography>{t.transactionPortName}</Typography>
                                </Fragment>
                            )}
                            {t.transactionValue && (
                                <Fragment>
                                    <Box my={1} />
                                    <Typography variant="overline"><small>{m.common.quantity}</small></Typography>
                                    <Typography>{t.transactionValue}</Typography>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                    <Box my={2} />
                    {!t.clearedAt && (
                        <Alert severity="warning" variant="filled">{m.wallets.pendingHint}</Alert>
                    )}
                    <Box my={2} />
                    <Box sx={{ textAlign: 'right' }}>
                        <ActionButton
                            color="primary"
                            onClick={() => setInfo(false)}
                        >
                            {m.actions.close}
                        </ActionButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}