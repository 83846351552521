import {I18nResolver} from "i18n-ts";

const en = {
    nav: {
        group: {
            general: 'General',
            management: 'Management',
            settings: 'Settings'
        },
        auth: {
            login: 'Login',
            logout: 'Logout'
        }
    },
    loading: 'Loading...',
    emptyContent: 'No contents.',
    search: 'Search...',
    formats: {
        datetime: 'MMM dd yyyy',
        time: 'HH:mm'
    },
    common: {
        list: 'List',
        warning: 'Warning',
        irreversibleHint: 'This action cannot be undone.',
        identity: 'Identity',
        title: 'Title',
        name: 'Name',
        message: 'Message',
        firstName: 'First name',
        lastName: 'Last name',
        description: 'Description',
        address: {
            title: 'Address',
            addressLine1: 'Address line 1',
            addressLine2: 'Address line 2',
            city: 'City',
            zipCode: 'Zipcode',
            country: 'Country'
        },
        contactPerson: 'Contact Person',
        contactDetails: 'Contact Details',
        phone: 'Phone',
        email: 'Email',
        properties: 'Properties',
        type: 'Type',
        status: 'Status',
        months: {
            0: 'January',
            1: 'February',
            2: 'March',
            3: 'April',
            4: 'May',
            5: 'June',
            6: 'July',
            7: 'August',
            8: 'September',
            9: 'October',
            10: 'November',
            11: 'December',
        },
        date: 'Date',
        time: 'Time',
        datetime: 'Datetime',
        timestamp: 'Timestamp',
        timeframe: {
            from: 'From',
            until: 'Until'
        },
        createdAt: "Created at",
        registeredAt: "Registered at",
        publishedAt: 'Published at',
        active: 'Active',
        inactive: 'Inactive',
        password: 'Password',
        confirmPassword: 'Confirm password',
        confirmation: 'Confirmation',
        amount: 'Amount',
        reason: 'Reason',
        year: 'Year',
        month: 'Month',
        note: 'Note',
        default: 'Default',
        filter: 'Filter',
        all: 'All',
        international: 'International',
        transaction: 'Transaction',
        revenue: 'Revenue',
        club: 'Club',
        total: 'Total',
        sum: 'Sum',
        serviceFee: 'Pin Point Service Fee',
        payout: 'Pay-out',
        quantity: 'Quantity',
        groups: 'Groups',
        wait: 'Please wait...',
        unit: 'Unit',
        summary: 'Summary',
        direction: 'Direction',
        none: 'None',
    },
    actions: {
        create: 'Create',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        send: 'Send',
        close: 'Close',
        view: 'View',
        enable: 'Enabled',
        disable: 'Disable',
        approve: 'Approve',
        confirm: 'Confirm',
        open: 'Open',
        submit: 'Submit',
        export: 'Export',
        clearFilter: 'Clear filter',
        add: 'Add',
        publish: 'Publish',
        select: 'Select'
    },
    login: {
        title: 'Login',
        forgotPassword: 'Forgot password?',
        btn: 'Log in',
        error: 'E-Mail or password could be invalid.',
    },
    resetPassword: {
        title: 'Reset password',
        hint: 'Please enter your email address and follow the instruction.',
        backToLogin: 'Back to login',
        btn: 'Send',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        success: 'Please follow the instruction in the email to reset your password.',
        failed: 'Failed to reset your password. Please check your input and try again.',
        error: 'Cannot send reset password email. Please contact support.',
        newPasswordError: 'Cannot reset password.',
        newPasswordNotMatchError: 'Passwords not matched.',
        newPasswordSuccess: 'You can now login with your new password.'
    },
    unauthorize: {
        title: 'Unauthorized',
        message: 'Your session is expired. Please login to continue.',
        btn: 'Log in'
    },
    logout: {
        prompt: 'Do you want to log out?',
        btn: 'Log out',
    },
    taxedAmount: {
        net: 'Net',
        gross: 'Gross',
        tax: 'Tax',
        taxRate: 'Tax rate'
    },
    paging: {
        next: {
            load: 'Load next',
            page: 'Next page'
        },
        previous: {
            load: 'Load previous',
            page: 'Previous page'
        }
    },
    support: {
        title: 'Contact Support',
        message: 'Short description about the issue...'
    },
    error: {
        generic: {
            title: 'Something went wrong!',
            message: 'An error occurred while processing your request. Please try again.'
        },
        loadUser: {
            title: 'User not found!',
            message: 'We cannot load your user data. Please try to login again or contact support if this problem is persisting.'
        },
        invalidValue: 'Invalid value.',
        requiredField: 'Required field. Must not be empty.',
    },
    dashboard: {
        title: 'Dashboard'
    },
    facilities: {
        singular: 'Facility',
        plural: 'Facilities',
        allowedIntlWallet: 'Allow international wallet',
        automaticallyApproveSignUp: 'Automatically approve signup',
        offlineSessionDuration: 'Offline Session Duration (in Hour)',
        maxScanDuration: 'Maximale Scandauer (in Millisecond)',
        supportEmail: 'Support email',
        supportPhone: 'Support phone',
        types: {
            TestClub: 'Test Club',
            GolfClub: 'Golf Club',
            GolfCentral: 'Golf Central',
            GolfResort: 'Golf Resort',
            GolfClubCleaning: 'Golf Club Cleaning',
            SailingClub: 'Sailing Club',
            BilliardClub: 'Billiard Club',
            TennisClub: 'Tennis Club',
            ParkingHouse: 'Parking House',
            ParkingZone: 'Parking Zone',
        },
        permissionGroup: {
            singular: 'Permission Group',
            plural: 'Permission Groups',
            defaultOnSignUp: 'Automatically assigned this group to newly signed up account.',
        },
        transactionBookmarks: {
            singular: 'Transaction bookmark',
            plural: 'Transaction bookmarks',
            message: 'Transaction message',
            allowedCustomAmount: 'Custom amount',
            allowedCustomAmountHint: 'Allow customer to enter a different amount.',
            allowedCustomMessage: 'Custom message',
            allowedCustomMessageHint: 'Allow customer to enter a different message.',
            delete: {
                title: 'Delete transaction bookmark',
                message: 'Are you sure you want to delete this transaction bookmark?',
            },
        },
        create: {
            title: 'Create new Facility'
        },
        delete: {
            title: 'Delete facility',
            message: 'Are you sure you want to delete this facility? All related data will also be deleted.'
        },
        createPermissionGroup: {
            title: 'Create new Permission Group'
        },
        editPermissionGroup: {
            title: 'Edit Permission Group'
        },
        deletePermissionGroup: {
            title: 'Delete Group',
            message: 'Are you sure you want to delete this permission group?'
        },
        selectAllHint: "Leave this empty to select all facilities.",
    },
    users: {
        singular: 'User',
        plural: 'Users',
        role: 'Role',
        position: 'Position',
        create: {
            title: 'Create new User',
            existed: {
                title: 'User already existed',
                message: 'User is already existed in the system. Please use a different email address.'
            }
        },
        delete: {
            title: 'Delete User',
            message: 'Are you sure you want to delete this user? All related data will also be deleted.'
        },
        invitation: {
            title: 'Account Invitation',
            message: 'Please set your password to complete this process.',
            success: 'Your registration is completed. You can now log in.'
        },
        resetPassword: {
            btn: 'Reset Password',
            success: 'Reset password email successfully sent.'
        },
        takeOver: {
            btn: 'Login as User'
        }
    },
    devices: {
        singular: 'Device',
        plural: 'Devices',
        types: {
            Entrance: 'Entrance',
            BallVendingMachine: 'Balls VM'
        },
        modelNumber: 'Model-Nr.',
        serialNumber: 'Serial-Nr.',
        ports: {
            singular: 'Port',
            plural: 'Ports',
        },
        basePrice: 'Base price',
        enableRangefee: 'Enable Rangefee',
        allowOfflineService: 'Allow offline service (Only for Entrance device)',
        switchingTimeDuration: 'Switching time duration',
        switchDelayDuration: 'Switch delay duration',
        bulkPurchasing: 'Allow purchasing in bulk',
        bulkPurchasingHint: 'If this option is enabled. The above price will be applied for the total quantity bulk purchasing.',
        bulkQuantity: 'Bulk quantity (Balls)',
        automaticallyOpenedOnConnection: 'Automatically opened on connection',
        create: {
            title: 'Create new Device'
        },
        delete: {
            title: 'Delete Device',
            message: 'Are you sure you want to delete this device? All related data will also be deleted.'
        }
    },
    accounts: {
        singular: 'Account',
        plural: 'Accounts',
        permissions: 'Permissions',
        usePermissionGroup: 'Use Permission Group',
        username: 'Username',
        memberNumber: 'Member Number',
        associationNumber: 'ÖGV/DGV Number',
        groups: 'Account Groups',
        expirationDate: 'Expiration Date',
        startHour: 'Start hour',
        endHour: 'End hour',
        addAccounts: 'Add Accounts',
        removeAccounts: 'Remove Accounts',
        onlyEnabled: 'Only enabled',
        onlyDisabled: 'Only disabled',
        confirmation: {
            email: 'Your email address has been confirmed.',
            phone: 'Your phone number has been confirmed.',
            close: 'You can now close this page.'
        },
        create: {
            title: 'Create new Account',
            existed: {
                title: 'Account already existed',
                message: 'Account is already existed in the system. Please use a different email address.'
            }
        },
        createGroup: {
            title: 'Create new Group',
        },
        delete: {
            title: 'Delete Account',
            message: 'Are you sure you want to delete this account? All related data will also be deleted.'
        },
        deleteFromFacility: {
            title: 'Delete Account Club',
            message: 'Are you sure you want to delete this account from the club? All related data will also be deleted.'
        },
        deleteGroup: {
            title: 'Delete Group',
            message: 'Are you sure you want to delete this account group? All related data will also be deleted.'
        },
        activities: {
            SignedUp: 'Signed Up',
            Login: 'Login',
            Logout: 'Logout',
            FacilityRegistration: 'Facility Registration',
            OpenEntrance: 'Open Entrance',
            BvmTransaction: 'BVM Transaction',
            RangefeeTransaction: 'Rangefee Transaction',
            InAppPaymentTransaction : 'In App Payment Transaction',
        },
        rangefee: {
            btn: 'Add Rangefee',
            added: 'Rangefee added to account.',
        },
        discount: {
            fixedAmount: "Fixed amount",
        }
    },
    transactions: {
        plural: 'Transactions',
        recent: 'Recent transactions',
        direction: {
            BookIn: 'Book-In',
            BookOut: 'Book-Out',
        },
        types: {
            PurchaseCredit: 'Top-up',
            ReceiveCredit: 'Incoming',
            SubtractCredit: 'Deduction',
            ConsumeCredit: 'Spending',
        },
        totalBallQuantity: 'Total balls issued',
        purposes: {
            singular: 'Purpose',
            plural: 'Purposes',
            Bvm: 'Ball Vending Machine',
            Rangefee: 'Rangefee',
            InAppPayment : 'In App Payment',
        },
    },
    wallets: {
        singular: 'Wallet',
        plural: 'Wallets',
        balance: 'Balance',
        addAmount: 'Add amount',
        subtractAmount: 'Subtract amount',
        previousAmount: 'Previous amount',
        newAmount: 'New amount',
        clearedAt: 'Cleared at',
        facilityRequired: 'Please select a facility.',
        pendingHint: 'This payment is currently pending. The amount will be processed to wallet once the bank has confirmed the transaction.',
        clerk: 'Clerk',
        types: {
            International: 'International Wallet',
            Facility: 'Club Wallet'
        },
        onlyActive: 'Only show active Account Balance',
        onlyInactive: 'Only show inactive Account Balance',
        totalBalance: 'Total Balance',
    },
    payments: {
        singular: 'Payment',
        plural: 'Payments',
        types: {
            BallVendingMachine: 'Ball Vending Machine',
            Rangefee: 'Rangefee',
            InAppPayment: 'In App Payment',
        },
        info: 'Payment Info',
        total: 'Gesamt Betrag',
    },
    notices: {
        singular: 'Notice',
        plural: 'Notices',
        onlyPublished: 'Only published',
        onlyUnpublished: 'Only unpublished',
        publish: {
            prompt: 'Are you sure you want to publish this notice?'
        },
        create: {
            title: 'Create new Notice',
        },
        update: {
            title: 'Edit Notice',
        },
        delete: {
            prompt: 'Are you sure you want to delete this notice?'
        }
    },
    statistics: {
        title: 'Statistics',
        activities: 'Activities',
        transactions: 'Transactions',
        totalIntlBalance: 'Total balance of international wallets.',
        totalClubBalance: 'Total balance of club wallets.',
        totalDeviceCount: 'Total registered devices count.',
        totalAccountCount: 'Total registered accounts count.',
        selectFacility: 'Please select a facility.',
        hideZeroAmount: 'Hide zero amount transactions.',
        hideIncomplete: 'Hide incomplete transactions.',
    },
    preferences: {
        title: 'Preferences',
        language: 'Language',
        updateProfile: 'Update Profile',
        updateProfileSuccess: 'Profile successfully updated.',
        changePassword: 'Change Password',
        changePasswordError: 'Cannot change password. Please verify your input.',
        changePasswordSuccess: 'Password successfully changed.'
    },
    kpis: {
        facilities: {
            registration: 'Facility registrations',
        },
        accounts: {
            facilityWalletsBalance: 'Total facility wallet balance',
            internationalWalletsBalance: 'Total international wallet balance'
        }
    },
};

const de = {
    nav: {
        group: {
            general: 'Allgemein',
            management: 'Verwaltung',
            settings: 'Einstellungen'
        },
        auth: {
            login: 'Einloggen',
            logout: 'Ausloggen'
        }
    },
    loading: 'Laden...',
    emptyContent: 'Kein Inhalt.',
    search: 'Suche...',
    formats: {
        datetime: 'dd.MM.yyyy',
        time: 'HH:mm'
    },
    common: {
        list: 'Liste',
        warning: 'Achtung',
        irreversibleHint: 'Diese Aktion kann nicht rückgängig gemacht werden.',
        identity: 'Identität',
        title: 'Titel',
        name: 'Name',
        message: 'Nachricht',
        firstName: 'Vorname',
        lastName: 'Nachname',
        description: 'Beschreibung',
        address: {
            title: 'Adresse',
            addressLine1: 'Adresszeile 1',
            addressLine2: 'Adresszeile 2',
            city: 'Stadt',
            zipCode: 'Zipcode',
            country: 'Land'
        },
        contactPerson: 'Ansprechpartner',
        contactDetails: 'Kontaktdaten',
        phone: 'Telefonnummer',
        email: 'E-Mail',
        type: 'Typ',
        properties: 'Eigenschaften',
        status: 'Status',
        months: {
            0: 'Januar',
            1: 'Februar',
            2: 'März',
            3: 'April',
            4: 'Mai',
            5: 'Juni',
            6: 'Juli',
            7: 'August',
            8: 'September',
            9: 'Oktober',
            10: 'November',
            11: 'Dezember',
        },
        date: 'Datum',
        time: 'Uhrzeit',
        datetime: 'Datum und Uhrzeit',
        timestamp: 'Zeitstempel',
        timeframe: {
            from: 'Von',
            until: 'Bis'
        },
        createdAt: "Erstellt am",
        registeredAt: "Registriert am",
        publishedAt: 'Veröffentlicht am',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        confirmation: 'Bestätigung',
        amount: 'Betrag',
        reason: 'Grund',
        year: 'Jahr',
        month: 'Monat',
        note: 'Notiz',
        default: 'Standard',
        filter: 'Filter',
        all: 'Alle',
        international: 'International',
        transaction: 'Transaktion',
        revenue: 'Umsatz',
        club: 'Club',
        total: 'Gesamt',
        sum: 'Gesamtbetrag',
        serviceFee: 'Pin Point Service Gebühr',
        payout: 'Pay-out',
        quantity: 'Menge',
        groups: 'Gruppen',
        wait: 'Bitte warten...',
        unit: 'Einheit',
        summary: 'Zusammenfassung',
        direction: 'Richtung',
        none: 'Keine',
    },
    actions: {
        create: 'Anlegen',
        edit: 'Bearbeiten',
        save: 'Speichern',
        cancel: 'Abbrechen',
        delete: 'Löschen',
        send: 'Senden',
        close: 'Schließen',
        view: 'Ansicht',
        enable: 'Aktivieren',
        disable: 'Deaktivieren',
        approve: 'Bestätigen',
        confirm: 'Absenden',
        open: 'Öffnen',
        submit: 'Einreichen',
        export: 'Exportieren',
        clearFilter: 'Filter entfernen',
        add: 'Hinzufügen',
        publish: 'Veröffentlichen',
        select: 'Auswählen'
    },
    login: {
        title: 'Login',
        forgotPassword: 'Passwort vergessen?',
        btn: 'Einloggen',
        error: 'E-Mail oder Passwort könnten ungültig sein.'
    },
    resetPassword: {
        title: 'Passwort zurücksetzen',
        hint: 'Bitte gibst du deine E-Mail-Adresse ein und folgst du danach den Anleitung.',
        backToLogin: 'Zurück zum Login',
        btn: 'Absenden',
        currentPassword: 'Aktuelles Passwort',
        newPassword: 'Neue Passwort',
        confirmNewPassword: 'Neue Passwort bestätigen',
        success: 'Bitte folgst du  den Anleitung in der E-Mail, um deiner Passwort zurückzusetzen.',
        failed: 'Fehler. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
        error: 'Es ist ein Fehler aufgetreten. Bitte kontaktierst du den Support.',
        newPasswordError: 'Es ist ein Fehler aufgetreten',
        newPasswordNotMatchError: 'Passwörter nicht übereinstimmend.',
        newPasswordSuccess: 'Du kannst nun mit deinen neuen Passwort einloggen.'
    },
    unauthorize: {
        title: 'Nicht berechtigt',
        message: 'Ihre Session ist abgelaufen. Bitte loggen Sie ein.',
        btn: 'Einloggen'
    },
    logout: {
        prompt: 'Wollen Sie wirklich ausloggen?',
        btn: 'Ausloggen',
    },
    taxedAmount: {
        net: 'Netto',
        gross: 'Brutto',
        tax: 'Steuer',
        taxRate: 'Steuersatz'
    },
    paging: {
        next: {
            load: 'Weiter laden',
            page: 'Nächste Seite'
        },
        previous: {
            load: 'Vorherige laden',
            page: 'Letzte Seite'
        }
    },
    support: {
        title: 'Support kontaktieren',
        message: 'Kurz Beschreibung über das Problem...'
    },
    error: {
        generic: {
            title: 'Etwas ist schief gelaufen',
            message: 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
        },
        loadUser: {
            title: 'User nicht gefunden',
            message: 'Wir können Ihre Benutzerdaten nicht laden. Bitte versuchen Sie, sich erneut anzumelden, oder wenden Sie sich an den Support, wenn dieses Problem weiterhin besteht.'
        },
        invalidValue: 'Ungültiger Wert.',
        requiredField: 'Erforderliches Feld. Darf nicht leer sein.',
    },
    dashboard: {
        title: 'Dashboard'
    },
    facilities: {
        singular: 'Anlage',
        plural: 'Anlagen',
        allowedIntlWallet: 'Internationale Geldbörse zulassen',
        automaticallyApproveSignUp: 'Neue Anmeldung automatisch genehmigen',
        offlineSessionDuration: 'Offline-Sitzungsdauer (in Stunde)',
        maxScanDuration: 'Max. Scan-Dauer (in Millisekunde)',
        supportEmail: 'Support-E-Mail',
        supportPhone: 'Support-Telefonnummer',
        types: {
            TestClub: 'Testclub',
            GolfClub: 'Golfclub',
            GolfCentral: 'Golfzentral',
            GolfResort: 'Golfresort',
            GolfClubCleaning: 'Golfschläger Reiniger',
            SailingClub: 'Segelclub',
            BilliardClub: 'Billardclub',
            TennisClub: 'Tennis Club',
            ParkingHouse: 'Parkhaus',
            ParkingZone: 'Parkplatz',
        },
        permissionGroup: {
            singular: 'Berechtigungsgruppe',
            plural: 'Berechtigungsgruppen',
            defaultOnSignUp: 'Bei neu angemeldeten Konto wird diese Gruppe wird automatisch zugewiesen.',
        },
        transactionBookmarks: {
            singular: 'Transaktion-Bookmark',
            plural: 'Transaktion-Bookmarks',
            message: 'Grund der Transaktion',
            allowedCustomAmount: 'Individueller Betrag',
            allowedCustomAmountHint: 'Dem Kunden erlauben, einen anderen Betrag einzugeben.',
            allowedCustomMessage: 'Eigene Transaktion-Grund',
            allowedCustomMessageHint: 'Dem Kunden die Möglichkeit geben, eine andere Transaktion-Grund einzugeben.',
            delete: {
                title: 'Transaktionslesezeichen löschen',
                message: 'Sind Sie sicher, dass Sie dieses Transaktions-Lesezeichen löschen möchten?',
            },
        },
        create: {
            title: 'Neue Anlage anlegen'
        },
        delete: {
            title: 'Anlage löschen',
            message: 'Sind Sie sicher, dass Sie diese Anlage löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht.'
        },
        createPermissionGroup: {
            title: 'Neue Berechtigungsgruppe anlegen'
        },
        editPermissionGroup: {
            title: 'Berechtigungsgruppe bearbeiten'
        },
        deletePermissionGroup: {
            title: 'Gruppe löschen',
            message: 'Sind Sie sicher, dass Sie diese Berechtigungsgruppe löschen möchten?'
        },
        selectAllHint: "Lassen Sie dieses Feld leer, um alle Anlagen auszuwählen.",
    },
    users: {
        singular: 'User',
        plural: 'Users',
        role: 'Role',
        position: 'Abteilung',
        create: {
            title: 'Neu User anlegen',
            existed: {
                title: 'User bereits existiert',
                message: 'Der User ist bereits im System vorhanden. Bitte verwenden Sie eine andere E-Mail-Adresse.'
            }
        },
        delete: {
            title: 'User löschen',
            message: 'Sind Sie sicher, dass Sie diese User löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht.'
        },
        invitation: {
            title: 'Account Einldaung',
            message: 'Passwort erstellen und los legen.',
            success: 'Deine Registrierung ist abgeschlossen. Du kannst jetzt nun einloggen.'
        },
        resetPassword: {
            btn: 'Passwort zurücksetzen',
            success: 'Passwort zurücksetzen E-Mail wurde erfolgreich gesendet.'
        },
        takeOver: {
            btn: 'In Vertrettung einloggen'
        }
    },
    devices: {
        singular: 'Gerät',
        plural: 'Geräte',
        types: {
            Entrance: 'Zutritt',
            BallVendingMachine: 'Ball-Automat'
        },
        modelNumber: 'Modellnummer',
        serialNumber: 'Seriennummer',
        ports: {
            singular: 'Ausgang',
            plural: 'Ausgängen',
        },
        basePrice: 'Grundpreis',
        enableRangefee: 'Rangefee aktivieren',
        allowOfflineService: 'Offline-Nutzung erlaubt (nur für Zutritt Gerät)',
        switchingTimeDuration: 'Schaltzeit',
        switchDelayDuration: 'Schaltverzögerung',
        bulkPurchasing: 'Kauf in großen Mengen erlaubt',
        bulkPurchasingHint: 'Wenn diese Option aktiviert ist, der oben genannte Preis wird für die gesamte Große Menge angewendet.',
        bulkQuantity: 'Große Menge (Bälle)',
        automaticallyOpenedOnConnection: 'Automatisch geöffnet nach Verbindungsaufbau',
        create: {
            title: 'Neues Gerät anlegen'
        },
        delete: {
            title: 'Gerät löschen',
            message: 'Sind Sie sicher, dass Sie dieses Gerät löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht.'
        }
    },
    accounts: {
        singular: 'Konto',
        plural: 'Konten',
        permissions: 'Berechtigungen',
        username: 'Benutzername',
        usePermissionGroup: 'Berechtigungsgruppe verwenden',
        memberNumber: 'Mitgliednummer',
        associationNumber: 'ÖGV/DGV Nummer',
        groups: 'Account Gruppen',
        expirationDate: 'Abgelaufen Datum',
        startHour: 'Start Uhrzeit',
        endHour: 'Ende Uhrzeit',
        addAccounts: 'Konten hinzufügen',
        removeAccounts: 'Konten entfernen',
        onlyEnabled: 'Nur Aktiv',
        onlyDisabled: 'Nur Inaktiv',
        confirmation: {
            email: 'Ihre E-Mail Adresse wurde bestätigt.',
            phone: 'Ihre Telefonnummer wurde bestätigt.',
            close: 'Sie können diese Seite nun schließen.'
        },
        create: {
            title: 'Neues Konto erstellen',
            existed: {
                title: 'Konto bereits existiert',
                message: 'Der Konto ist bereits im System vorhanden. Bitte verwenden Sie eine andere E-Mail-Adresse.'
            }
        },
        createGroup: {
            title: 'Create new Group',
        },
        delete: {
            title: 'Konto löschen',
            message: 'Sind Sie sicher, dass Sie diese Konto von System löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht.'
        },
        deleteFromFacility: {
            title: 'Konto beim Club löschen',
            message: 'Sind Sie sicher, dass Sie diese Konto beim dem Club löschen möchten? Alle zugehörigen Daten werden ebenfalls gelöscht.'
        },
        deleteGroup: {
            title: 'Delete Group',
            message: 'Are you sure you want to delete this account group? All related data will also be deleted.'
        },
        activities: {
            SignedUp: 'Registriert',
            Login: 'Einloggen',
            Logout: 'Ausloggen',
            FacilityRegistration: 'Club Registrierung',
            OpenEntrance: 'Eingang öffnen',
            BvmTransaction: 'Ball-Automat Transaktion',
            RangefeeTransaction: 'Rangefee Transaktion',
            InAppPaymentTransaction : 'In-App-Zahlung Transaktion',
        },
        rangefee: {
            btn: 'Rangefee hinzufügen',
            added: 'Rangefee zum Konto hinzugefügt.',
        },
        discount: {
            fixedAmount: "Fixe Betrag",
        },
    },
    transactions: {
        plural: 'Transaktions',
        recent: 'Aktuelle Transaktionen',
        direction: {
            BookIn: 'Aufbuchungen',
            BookOut: 'Abbuchungen',
        },
        types: {
            PurchaseCredit: 'Aufladen',
            ReceiveCredit: 'Eingehend',
            SubtractCredit: 'Abzug',
            ConsumeCredit: 'Ausgaben',
        },
        totalBallQuantity: 'Gesamt ausgegebene Bälle',
        purposes: {
            singular: 'Verwendungszweck',
            plural: 'Verwendungszwecke',
            Bvm: 'Ball-Automat',
            Rangefee: 'Rangefee',
            InAppPayment : 'In-App-Zahlung',
        }
    },
    wallets: {
        singular: 'Geldbörse',
        plural: 'Geldbörsen',
        balance: 'Kontostand',
        addAmount: 'Betrag hinzufügen',
        subtractAmount: 'Betrag abziehen',
        previousAmount: 'Vorherige Guthaben',
        newAmount: 'Neue Guthaben',
        clearedAt: 'Freigegeben am',
        clerk: 'Sachbearbeiter',
        facilityRequired: 'Bitte wählen Sie einen Anlage aus.',
        pendingHint: 'Diese Zahlung ist derzeit noch nicht abgeschlossen. Der Betrag wird auf der Geldbörse überwiesen, sobald die Bank die Transaktion bestätigt hat.',
        types: {
            International: 'Internationale Geldbörse',
            Facility: 'Club Geldbörse'
        },
        onlyActive: 'Nur mit aktiven Kontostand',
        onlyInactive: 'Nur mit inaktiven Kontostand',
        totalBalance: 'Gesamt Kontostand'
    },
    payments: {
        singular: 'Zahlung',
        plural: 'Zahlungen',
        types: {
            BallVendingMachine: 'Ball-Automat',
            Rangefee: 'Rangefee',
            InAppPayment: 'In-App-Zahlung'
        },
        info: 'Zahlung-Details',
        total: 'Gesamt Betrag',
    },
    notices: {
        singular: 'Mitteilung',
        plural: 'Mitteilungen',
        onlyPublished: 'Nur veröffentlichen zeigen',
        onlyUnpublished: 'Nur unveröffentlicht zeigen',
        publish: {
            prompt: 'Sind Sie sicher, dass Sie diese Mitteilung veröffentlichen wollen?'
        },
        create: {
            title: 'Neue Mitteilung anlegen',
        },
        update: {
            title: 'Mitteilung ändern',
        },
        delete: {
            prompt: 'Sind Sie sicher, dass Sie diese Mitteilung löschen wollen?'
        }
    },
    statistics: {
        title: 'Statistik',
        activities: 'Aktivität',
        transactions: 'Buchungen',
        totalIntlBalance: 'Gesamtsaldo der internationalen Geldbörsen.',
        totalClubBalance: 'Gesamtsaldo der Club-Geldbörsen.',
        totalDeviceCount: 'Anzahl der registrierten Geräte.',
        totalAccountCount: 'Anzahl der registrierten Konten',
        selectFacility: 'Anlage muss erst ausgewählt werden.',
        hideZeroAmount: 'Transaktionen mit Nullbeträgen ausblenden.',
        hideIncomplete: 'Unvollständige Transaktionen ausblenden.',
    },
    preferences: {
        title: 'Preferences',
        language: 'Sprache',
        updateProfile: 'Profil bearbeiten',
        updateProfileSuccess: 'Profil wurde erfolgreich geändert.',
        changePassword: 'Passwort ändern',
        changePasswordError: 'Passwort könnte nicht ändern. Bitte kontrollieren Sie Ihre Eingaben',
        changePasswordSuccess: 'Passwort wurde erfolgreich geändert.'
    },
    kpis: {
        facilities: {
            registration: 'Club-Registrierungen',
        },
        accounts: {
            facilityWalletsBalance: 'Gesamtbetrag der Club-Geldbörse',
            internationalWalletsBalance: 'Gesamtbetrag der Internationale-Geldbörse'
        }
    },
};

const translations = {
    en: en,
    de: de,
    default: de
};

export const i18n = new I18nResolver(translations);
export const useMessages = () => i18n.translation;
