import React, {Fragment, PropsWithChildren, useState} from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box, Button,
    Dialog,
    DialogContent, Grid, Hidden,
    TextField,
    Typography
} from "@mui/material";
import {ApiError} from "../data/Api";
import {useMessages} from "../i18n";
import {theme} from "../theme";
import {errorGraphic} from "../graphic";
import {ExpandIcon, SendIcon, SupportIcon} from "../icons";

type ErrorPromptProps = {
    title?: string;
    message?: string;
    error?: Error|ApiError;
    enabledSupport?: boolean;
}

export const ErrorPrompt = ({title, message, error, enabledSupport, children}: PropsWithChildren<ErrorPromptProps>) => {
    const [formActive, setFormActive] = useState(false);

    const toggleForm = () => setFormActive(!formActive);

    const m = useMessages();
    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogContent sx={{
                padding: `${theme.spacing(2)}px !important`
            }}>
                <Grid container spacing={3}>
                    <Hidden smDown>
                        <Grid item md={5} sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box p={2}>
                                <img src={errorGraphic} alt="Error" style={{
                                    width: '100%',
                                    height: '100%'
                                }} />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item md={7} xs={12} sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Box py={2} width="100%">
                            {!formActive && (
                                <Fragment>
                                    <Typography sx={{
                                        fontSize: theme.typography.fontSize * 1.8,
                                        fontWeight: theme.typography.fontWeightRegular,
                                        lineHeight: '1em',
                                        marginBottom: theme.spacing(1)
                                    }}>{title || m.error.generic.title}</Typography>
                                    <Typography variant="body1" >{message || m.error.generic.message}</Typography>
                                    {error && (
                                        <Fragment>
                                            <Box my={2} />
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandIcon />}>
                                                    <Typography>Error log:</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <code>
                                                        {error.toString()}
                                                    </code>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Fragment>
                                    )}
                                    <Box my={3} />
                                    <Box display="flex">
                                        {children}
                                        {enabledSupport && (
                                            <Fragment>
                                                <Box mx={1} />
                                                <Button disableElevation variant="contained" color="secondary"
                                                        startIcon={<SupportIcon />} onClick={toggleForm}
                                                >
                                                    {m.support.title}
                                                </Button>
                                            </Fragment>
                                        )}
                                    </Box>
                                </Fragment>
                            )}
                            {formActive && (
                                <Fragment>
                                    <Typography sx={{
                                        fontSize: theme.typography.fontSize * 1.8,
                                        fontWeight: theme.typography.fontWeightRegular,
                                        lineHeight: '1em',
                                        marginBottom: theme.spacing(1)
                                    }}>{m.support.title}</Typography>
                                    <Box my={2} />
                                    <TextField fullWidth variant="outlined" multiline
                                               label={m.support.message} rows={4}
                                    />
                                    <Box my={3} />
                                    <Box display="flex">
                                        <Button disableElevation variant="contained" color="secondary" startIcon={<SendIcon />}>{m.actions.send}</Button>
                                        <Box mx={1} />
                                        <Button onClick={toggleForm}>{m.actions.cancel}</Button>
                                    </Box>
                                </Fragment>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};