import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {
    Box,
    Button,
    ButtonGroup, CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Snackbar, Typography
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {deleteUser, getUser, sendResetPassword, userTakeOver, updateUser} from "../../data/users";
import {EditFormProps, User, UserUpdateRequest} from "../../data/types";

import {useMessages} from "../../i18n";
import {DropDownIcon, EditIcon} from "../../icons";
import {UserForm} from "./UserForm";
import {UserRoleBadge} from "./UserRoleBadge";
import {LabeledText} from "../../component/LabeledText";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb, DeletePrompt} from "../../component/ContentLayout";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import { authStore } from "../../store";
import {AuthContext, UserContext} from "../../data/context";

export const UserDetails = () => {
    const navigate = useNavigate();
    const {uuid = ''} = useParams();

    const {setUser: setUserContext} = useContext(UserContext);
    const {setAuthenticated} = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [user, setUser] = useState<User>();
    const [editMode, setEditMode] = useState(false);
    const [resetSent, setResetSent] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getUser(uuid)
            .then(setUser)
            .catch(setError);
    }, []);

    const handleOnSave = (u: User) => {
        setEditMode(false);
        setUser(u);
    }

    const handleOnDelete = () => {
        deleteUser(uuid)
            .then(() => navigate('/users'))
            .catch(setError);
    }

    const handlePasswordReset = () => {
        if (user) {
            sendResetPassword(user.uuid)
                .then(() => setResetSent(true))
                .catch(setError);
        }
    }

    async function handleTakeOver() {
        if (uuid) {
            setInProgress(true);
            try {
                const session = await userTakeOver(uuid);
                authStore.handleSession(session);
                setUserContext(session.user);
                setAuthenticated(true);
                navigate('/dashboard', { replace: true });
                return;
            } catch (err) {
                console.error('failed to take over user');
            }
            setInProgress(false);
        }
    }

    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.users.singular} />
                    <AppBreadcrumbs>
                        <Crumb title={m.users.plural} path="/users" />
                        <Crumb title={m.actions.view} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <ButtonGroup color="primary" variant="contained" disableElevation >
                        <Button onClick={handlePasswordReset}>{m.users.resetPassword.btn}</Button>
                        {authStore.isRoot && (
                            <Fragment>
                                <Button onClick={ev => setAnchorEl(ev.target)}><DropDownIcon /></Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    <MenuItem onClick={handleTakeOver}>{m.users.takeOver.btn}</MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box my={3} />
            {user && (
                <Fragment>
                    <ContentBody padding={2}>
                        {!editMode && <Details user={user} onEdit={() => setEditMode(true)} />}
                        {editMode && (
                            <Edit data={user}
                                  onCancel={() => setEditMode(false)}
                                  onSave={handleOnSave}
                                  onError={setError}
                            />
                        )}
                    </ContentBody>
                    {editMode && (
                        <Fragment>
                            <Box my={3} />
                            <DeletePrompt title={m.users.delete.title}
                                          message={m.users.delete.message}
                                          onDelete={handleOnDelete}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}
            {error && (
                <ErrorPrompt error={error}
                             title={error.code === 409 ? m.users.create.existed.title : undefined}
                             message={error.code === 409 ? m.users.create.existed.message : undefined}
                >
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={resetSent}
                onClose={() => setResetSent(false)}
                message={m.users.resetPassword.success}
            />
            <Dialog open={inProgress} fullWidth maxWidth="sm" >
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={30} />
                    <Box my={1} />
                    <Typography>{m.common.wait}</Typography>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

const Details = ({user, onEdit}: { user: User, onEdit: () => void }) => {
    const m = useMessages();
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item lg={6} md={12} sm={12}>
                    <Box display="flex" flexDirection="row">
                        <LabeledText label={m.facilities.singular}>
                            {user.facility ? user.facility.name : ''}
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.users.position}>
                            {user.position}
                        </LabeledText>
                    </Box>
                    <Box my={2} />
                    <Box display="flex" flexDirection="row">
                        <LabeledText label={m.common.title}>
                            {user.title ?? '-'}
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.common.firstName}>
                            {user.firstName}
                        </LabeledText>
                        <Box mx={2} />
                        <LabeledText label={m.common.lastName}>
                            {user.lastName}
                        </LabeledText>
                    </Box>
                    <Box my={2} />
                    <LabeledText label={m.users.role}>
                        <UserRoleBadge role={user.role} />
                    </LabeledText>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                    <LabeledText label={m.common.email}>
                        <Link href={'mailto:' + user.email}>{user.email}</Link>
                    </LabeledText>
                    <Box my={2} />
                    <LabeledText label={m.common.phone}>
                        {user.phone ? (<Link href={'tel:' + user.phone}>{user.phone}</Link>) : '-'}
                    </LabeledText>
                </Grid>
            </Grid>
            <Box my={1} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary"
                        startIcon={<EditIcon/>}
                        onClick={onEdit}>{m.actions.edit}</Button>
            </Box>
        </Fragment>
    )
}

const Edit = ({data, onSave, onError, onCancel}: EditFormProps<User>) => {
    const [inProgress, setInProgress] = useState(false);

    const update = async (form: UserUpdateRequest) => {
        setInProgress(true);
        try {
            const result = await updateUser(data.uuid, form);
            onSave(result);
            return;
        } catch (error) {
            onError(error as any);
        }
        setInProgress(false);
    }

    return (
        <UserForm user={data}
                  inProgress={inProgress}
                  onCancel={onCancel}
                  onSave={update}
        />
    )
}