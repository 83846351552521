import React, {Fragment, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {
    Badge as MBadge,
    Box,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {listUsers} from "../../data/users";
import {Page, User, UserFilter, UserRole} from "../../data/types";
import {PagingSettings, userPaging} from "../../data/pagings";

import {useMessages} from "../../i18n";
import {displayUserNameLine} from "../../utils";
import {FilterIcon, PlusIcon} from "../../icons";
import {UserRoleBadge} from "./UserRoleBadge";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {AppBreadcrumbs, AppFab, ContentTitle, Crumb} from "../../component/ContentLayout";
import {SearchInput} from "../../component/SearchInput";
import {authStore, uiStore} from "../../store";
import useAsyncEffect from "use-async-effect";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {listFacilities} from "../../data/facilities";

export const UserList = () => {
    const navigate = useNavigate();
    const m = useMessages();

    const columns: ColumnType = {
        role: { name: m.users.role },
        facility: { name: m.facilities.singular },
        firstName: { name: m.common.name },
        email: { name: m.common.email },
        phone: { name: m.common.phone }
    }
    if (!authStore.isRoot) delete columns.facility;

    const [page, setPage] = useState<Page<User>>();
    const [needle, setNeedle] = useState('');
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(userPaging.getSettings());
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<UserFilter>(uiStore.getUserFilter() || {});
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {role, facilityUuid} = filter;
        return (!!role && role !== 'ALL' as any) || (!!facilityUuid && facilityUuid !== 'ALL');
    }, [filter])

    async function fetch(needle?: string, filter?: UserFilter) {
        try {
            const page = await listUsers(userPaging, needle, filter);
            setPage(page);
        } catch (err) {
            setError(err as any);
        }
    }

    useAsyncEffect(async () => {
        if (authStore.isRoot) {
            try {
                const page = await listFacilities();
                setFacilities(page.content.map(f => ({ label: f.name, value: f.uuid })));
            } catch (err) {
                console.error('failed list facilities', err);
            }
        }
        await fetch();
    }, []);

    async function handleNeedleChange(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        userPaging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        await fetch(needle, filter);
    }

    async function handlePagingChange(pageable: PagingSettings<any>) {
        userPaging.updateSettings(pageable);
        setPageable(pageable);
        await fetch(needle, filter);
    }

    async function handleFilterSubmit(f?: UserFilter) {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        userPaging.updateSettings(adjusted);
        setPageable(adjusted);
        await fetch(needle, f);
        setShowFilter(false);
        setFilter(f);
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }

    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={m.users.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.users.plural} path="/users" />
                        <Crumb title={m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <SearchInput onSubmit={handleNeedleChange} />
                    <Box mx={1} />
                    <IconButton onClick={() => setShowFilter(true)}>
                        <MBadge color="primary" variant="dot" invisible={!filterActive}>
                            <FilterIcon />
                        </MBadge>
                    </IconButton>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentTable page={page}
                          pageable={pageable}
                          columns={columns}
                          onPageableChange={handlePagingChange}
                          renderTableBody={(
                              <Fragment>
                                  {page && page.content.map(u => (
                                      <TableRow key={u.uuid} onClick={() => navigate('/users/' + u.uuid)}>
                                          <TableCell><UserRoleBadge role={u.role} /></TableCell>
                                          {authStore.isRoot && <TableCell>{u.facility ? u.facility.name : ''}</TableCell>}
                                          <TableCell>{displayUserNameLine(u)}</TableCell>
                                          <TableCell>{u.email}</TableCell>
                                          <TableCell>{u.phone}</TableCell>
                                      </TableRow>
                                  ))}
                              </Fragment>
                          )}
            />
            <AppFab onClick={() => navigate('/users/create')} children={<PlusIcon />}/>

            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item sm={authStore.isRoot ? 4 : 12} xs={12}>
                            <SelectInput label={m.users.role}
                                         options={[
                                             { label: m.common.all, value: 'ALL' },
                                             { label: UserRole.User, value: UserRole.User },
                                             { label: UserRole.Admin, value: UserRole.Admin }
                                         ]}
                                         value={filter.role || 'ALL'}
                                         onChange={role => setFilter({ ...filter, role })}
                            />
                        </Grid>
                        {authStore.isRoot && (
                            <Grid item sm={8} xs={12}>
                                <SelectInput label={m.facilities.singular}
                                             options={facilities}
                                             value={filter.facilityUuid ?? ''}
                                             onChange={facilityUuid => setFilter({ ...filter, facilityUuid })}
                                             searchable
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
};