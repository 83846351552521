import {
    AccountFilter,
    AccountGroupFilter,
    DeviceFilter,
    FacilityFilter, NoticeFilter, PaymentFilter,
    UserFilter,
    WalletFilter
} from "../data/types";

const LAST_PATH = 'uiLastPath';
const FACILITY_FILTER = 'uiFacilityFilter';
const DEVICE_FILTER = 'uiDeviceFilter';
const NOTICE_FILTER = 'uiNoticeFilter';
const ACCOUNT_FILTER = 'uiAccountFilter';
const ACCOUNT_GROUP_FILTER = 'uiAccountGroupFilter';
const USER_FILTER = 'uiUserFilter';
const WALLET_FILTER = 'uiWalletFilter';
const PAYMENT_FILTER = 'uiPaymentFilter';

class UiStore {

    setLastPath(path: string) {
        localStorage.setItem(LAST_PATH, path);
    }

    getLastPath() {
        return localStorage.getItem(LAST_PATH) || undefined;
    }

    clearLastPath() {
        localStorage.removeItem(LAST_PATH);
    }

    setFacilityFilter(data: FacilityFilter) {
        localStorage.setItem(FACILITY_FILTER, JSON.stringify(data))
    }

    getFacilityFilter() {
        const filter = localStorage.getItem(FACILITY_FILTER);
        return filter ? JSON.parse(filter) as FacilityFilter : undefined;
    }

    setDeviceFilter(data: DeviceFilter) {
        localStorage.setItem(DEVICE_FILTER, JSON.stringify(data))
    }

    getDeviceFilter() {
        const filter = localStorage.getItem(DEVICE_FILTER);
        return filter ? JSON.parse(filter) as DeviceFilter : undefined;
    }

    setNoticeFilter(data: NoticeFilter) {
        localStorage.setItem(NOTICE_FILTER, JSON.stringify(data))
    }

    getNoticeFilter() {
        const filter = localStorage.getItem(NOTICE_FILTER);
        return filter ? JSON.parse(filter) as NoticeFilter : undefined;
    }

    setAccountFilter(data: AccountFilter) {
        localStorage.setItem(ACCOUNT_FILTER, JSON.stringify(data));
    }

    getAccountFilter() {
        const filter = localStorage.getItem(ACCOUNT_FILTER);
        return filter ? JSON.parse(filter) as AccountFilter : undefined;
    }

    setAccountGroupFilter(data: AccountGroupFilter) {
        localStorage.setItem(ACCOUNT_GROUP_FILTER, JSON.stringify(data))
    }

    getAccountGroupFilter() {
        const filter = localStorage.getItem(ACCOUNT_GROUP_FILTER);
        return filter ? JSON.parse(filter) as AccountGroupFilter : undefined;
    }

    setUserFilter(data: UserFilter) {
        localStorage.setItem(USER_FILTER, JSON.stringify(data));
    }

    getUserFilter() {
        const filter = localStorage.getItem(USER_FILTER);
        return filter ? JSON.parse(filter) as UserFilter : undefined;
    }

    setWalletFilter(data?: WalletFilter) {
        localStorage.setItem(WALLET_FILTER, JSON.stringify(data))
    }

    getWalletFilter() {
        const filter = localStorage.getItem(WALLET_FILTER);
        return filter ? JSON.parse(filter) as WalletFilter : undefined;
    }

    setPaymentFilter(data: PaymentFilter) {
        localStorage.setItem(PAYMENT_FILTER, JSON.stringify(data));
    }

    getPaymentFilter() {
        const filter = localStorage.getItem(PAYMENT_FILTER);
        return filter ? JSON.parse(filter) as PaymentFilter : undefined;
    }

    clear() {
        localStorage.clear();
    }
}

export const uiStore = new UiStore();