import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import React, {useEffect, useState} from "react";
import {useMessages} from "../i18n";
import {luxonDate} from "../tools";

export const DateSelector = ({label, date, onChange}: { label?: string, date?: DateTime, onChange: (date: DateTime|null) => void }) => {
    const [value, setValue] = useState<DateTime|null>(date || null);

    useEffect(() => {
        if (!date) {
            setValue(null);
        } else if (value !== date) {
            setValue(date || null)
        }
    }, [date])

    const m = useMessages();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label || m.common.date}
                format={m.formats.datetime}
                value={value ? luxonDate(value).toJSDate() : null}
                onChange={d => {
                    setValue(d ? luxonDate(d) : null);
                    onChange(d ? luxonDate(d) : null);
                }}
                closeOnSelect={true}
                sx={{ width: '100%' }}
            />
        </LocalizationProvider>
    )
}