import React from "react";
import {green, indigo} from "@mui/material/colors";
import {DeviceType} from "../data/types";
import {useMessages} from "../i18n";
import {Badge} from "./Badge";
import {DoorIcon, GolfIcon, UnknownDeviceIcon} from "../icons";

export const DeviceTypeBadge = ({type}: {type: DeviceType}) => {
    const m = useMessages();
    switch (type) {
        case DeviceType.Entrance:
            return (<Badge label={m.devices.types[type]} style={{ background: green['A700'] }} icon={<DoorIcon fontSize="inherit" />} />);
        case DeviceType.BallVendingMachine:
            return (<Badge label={m.devices.types[type]} style={{ background: indigo['A200'] }} icon={<GolfIcon fontSize="inherit" />} />);
        default:
            return (<Badge label={type} icon={<UnknownDeviceIcon fontSize="inherit" />} />);
    }
}