import React from "react"
import {Button, ButtonProps, useMediaQuery} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {LockIcon} from "../icons";
import {theme} from "../theme";
import {Link} from "react-router-dom";

interface ActionButton extends ButtonProps {
    loading?: boolean;
}

export function ActionButton(props: ActionButton) {
    const {
        loading = false,
        disableElevation = true,
        variant = 'contained',
        color,
        fullWidth = useMediaQuery(theme.breakpoints.down('sm')),
        children,
        ...rest
    } = props;

    if (loading) {
        return (
            <LoadingButton
                loadingPosition="start"
                startIcon={<LockIcon />}
                {...{ loading, disableElevation, variant, color, fullWidth }}
                {...rest}
            >
                {children}
            </LoadingButton>
        )
    }

    return (
        <Button
            {...{ disableElevation, variant, color, fullWidth }}
            {...rest}
        >
            {children}
        </Button>
    )
}

interface LinkButtonProps extends ButtonProps {
    to: string;
}

export function LinkButton(props: LinkButtonProps) {
    const {
        to = '#',
        disableElevation = true,
        variant = 'contained',
        color = 'primary',
        fullWidth = useMediaQuery(theme.breakpoints.down('sm')),
        children,
        ...rest
    } = props;

    return (
        <Button
            component={Link}
            {...{ to, disableElevation, variant, color, fullWidth }}
            {...rest as any}
        >
            {children}
        </Button>
    )
}