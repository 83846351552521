import React, {Fragment, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {
    Badge as MBadge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";
import {ApiError} from "../../data/Api";
import {listFacilities} from "../../data/facilities";
import {Page, Wallet, WalletFilter, WalletTotalBalance, WalletType} from "../../data/types";
import {computeWalletTotalBalance, listWallets} from "../../data/wallets";
import {PagingSettings, walletIntlPaging, walletPaging} from "../../data/pagings";

import {FilterIcon} from "../../icons";
import {useMessages} from "../../i18n";
import {authStore, uiStore} from "../../store";
import {displayAccountNameLine} from "../../utils";
import {formatAmountDigit, luxonDate} from "../../tools";
import {SearchInput} from "../../component/SearchInput";
import {ColumnType, ContentTable} from "../../component/ContentTable";
import {SelectInput, SelectOption} from "../../component/SelectInput";
import {AppBreadcrumbs, ContentBody, ContentTitle, Crumb} from "../../component/ContentLayout";
import useAsyncEffect from "use-async-effect";
import {ErrorPrompt} from "../../component/ErrorPrompt";
import {LabeledText} from "../../component/LabeledText";

export const WalletList = ({type}: { type?: WalletType }) => {
    const navigate = useNavigate();
    const m = useMessages();

    let paging = walletPaging;
    let columns: ColumnType = {
        'facility.name': { name: m.facilities.singular },
        'account': { name: m.accounts.singular },
        'amount': { name: m.wallets.balance },
        'createdAt': { name: m.common.createdAt, align: 'right' }
    }

    if (WalletType.International === type) {
        delete columns['facility.name'];
        paging = walletIntlPaging;
    }

    const [totalBalance, setTotalBalance] = useState<WalletTotalBalance>();
    const [page, setPage] = useState<Page<Wallet>>();
    const [facilities, setFacilities] = useState<SelectOption[]>([]);
    const [filter, setFilter] = useState(uiStore.getWalletFilter() || {});
    const [needle, setNeedle] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof columns>>(paging.getSettings());
    const [error, setError] = useState<ApiError>();

    const filterActive = useMemo(() => {
        const {active, facilityUuid} = filter;
        return (!!active && active !== 'ALL') || (WalletType.Facility === type && !!facilityUuid && facilityUuid !== 'ALL');
    }, [filter, type]);

    async function fetch(needle?: string, filter?: WalletFilter) {
        let walletType = WalletType.International
        if (authStore.isRoot && type) walletType = type;
        try {
            const page = await listWallets(walletPaging, walletType, needle, filter);
            setPage(page);
            const totalBalance = await computeWalletTotalBalance(walletType, filter?.facilityUuid);
            setTotalBalance(totalBalance);
        } catch (err) {
            setError(err as any);
        }
    }

    useAsyncEffect(async () => {
        if (authStore.isRoot) {
            try {
                const page = await listFacilities();
                setFacilities(page.content.map(f => ({ label: f.name, value: f.uuid })))
            } catch (err) {
                console.error('failed to list facilities', err);
            }
        }
        await fetch(needle, filter);
    }, [type])

    async function handleNeedleChange(needle: string) {
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        paging.updateSettings(adjusted)
        setPageable(adjusted);
        setNeedle(needle);
        await fetch(needle, filter);
    }

    async function handlePagingChange(pageable: PagingSettings<any>) {
        paging.updateSettings(pageable);
        setPageable(pageable);
        await fetch(needle, filter);
    }

    async function handleFilterSubmit(f?: WalletFilter) {
        f = f || filter;
        const adjusted = {
            ...pageable,
            pageNumber: 0
        };
        paging.updateSettings(adjusted);
        setPageable(adjusted);
        uiStore.setWalletFilter(f);
        await fetch(needle, f);
        setShowFilter(false);
        setFilter(f);
    }

    async function resetFilter() {
        await handleFilterSubmit({});
    }

    const title = (WalletType.Facility === type ? m.facilities.singular : m.common.international) + ' ' + m.wallets.plural;
    return (
        <Fragment>
            <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                    <ContentTitle title={authStore.isRoot ? title : m.wallets.plural} />
                    <AppBreadcrumbs>
                        <Crumb title={m.wallets.plural} path="/wallets" />
                        <Crumb title={authStore.isRoot ? title : m.common.list} />
                    </AppBreadcrumbs>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <SearchInput onSubmit={handleNeedleChange} />
                    <Box mx={1} />
                    <IconButton onClick={() => setShowFilter(true)}>
                        <MBadge color="primary" variant="dot" invisible={!filterActive}>
                            <FilterIcon />
                        </MBadge>
                    </IconButton>
                </Grid>
            </Grid>
            <Box my={3} />
            <ContentBody padding={3}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    {filter.facilityUuid && WalletType.Facility === type && (
                        <Fragment>
                            <LabeledText label={m.facilities.singular}>
                                {facilities.find(f => f.value === filter.facilityUuid)?.label ?? '-'}
                            </LabeledText>
                            <Box mx={2} />
                        </Fragment>
                    )}
                    <LabeledText label={m.wallets.totalBalance}>
                        <span style={{ fontSize: '2rem', marginTop: 10, display: 'inline-block' }}>€ {totalBalance?.totalBalance ? formatAmountDigit(totalBalance.totalBalance) : '-'}</span>
                    </LabeledText>
                </Box>
            </ContentBody>
            <Box my={3} />
            <ContentTable
                page={page}
                pageable={pageable}
                columns={columns}
                onPageableChange={handlePagingChange}
                renderTableBody={(
                    <Fragment>
                        {page && page.content.map(w => (
                            <TableRow key={w.uuid} onClick={() => navigate('/wallets/' + w.uuid)}>
                                {WalletType.International !== type && <TableCell>{w.facility ? w.facility.name : ''}</TableCell>}
                                <TableCell style={{ whiteSpace: 'nowrap' }}>{displayAccountNameLine(w.account)}</TableCell>
                                <TableCell style={{ width: '35%' }}>€ {formatAmountDigit(w.amount)}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{luxonDate(w.createdAt).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </Fragment>
                )}
            />
            <Dialog open={showFilter} fullWidth maxWidth="sm">
                <DialogTitle>{m.common.filter}</DialogTitle>
                <DialogContent>
                    <Box my={1} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SelectInput label={m.common.status}
                                         options={[
                                             { label: m.common.all, value: 'ALL' },
                                             { label: m.wallets.onlyActive, value: '1' },
                                             { label: m.wallets.onlyInactive, value: '0' },
                                         ]}
                                         value={filter.active || 'ALL'}
                                         onChange={active => setFilter({ ...filter, active })}
                            />
                        </Grid>
                        {authStore.isRoot && WalletType.International !== type && (
                            <Grid item xs={12}>
                                <SelectInput label={m.facilities.singular}
                                             options={facilities}
                                             value={filter.facilityUuid ?? ''}
                                             onChange={facilityUuid => setFilter({ ...filter, facilityUuid })}
                                             searchable
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{m.actions.clearFilter}</Button>
                    <Box sx={{ mx: 2, flexGrow: 1 }} />
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleFilterSubmit()}>{m.actions.confirm}</Button>
                    <Button onClick={() => setShowFilter(false)}>{m.actions.close}</Button>
                </DialogActions>
            </Dialog>
            {error && (
                <ErrorPrompt error={error}>
                    <Button variant="contained" disableElevation onClick={() => setError(undefined)}>{m.actions.close}</Button>
                </ErrorPrompt>
            )}
        </Fragment>
    )
}