import React from "react";
import {alpha, Switch} from "@mui/material";
import {green} from "@mui/material/colors";
import {LabeledText} from "./LabeledText";
import {theme} from "../theme";

type Props = {
    label?: string,
    checked?: boolean,
    onChange?: (checked: boolean) => void,
    align?: 'right'
}

export const SwitchInput = ({label, checked, onChange, align}: Props) => {
    const switchSx = {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[600],
        }
    };

    const handleChange = (value?: boolean) => {
        if (onChange) onChange(value === true)
    }

    if (label) {
        return (
            <LabeledText label={label} align={align}>
                <Switch checked={checked} onChange={ev => handleChange(ev.target.checked)} sx={switchSx} />
            </LabeledText>
        )
    }

    return <Switch checked={checked} onChange={ev => handleChange(ev.target.checked)} sx={switchSx} />;
}