import XLSX from "xlsx";
import {AccountTransaction, Facility, Timeframe, TransactionProps, WalletType} from "../../data/types";
import {formatAmount, luxonDate} from "../../tools";
import {displayAccountNameLine, displayDateTime, displayUserNameLine} from "../../utils";
import {computeColumnLengths, formatCellAsNumber, getTypeName} from "./excels";

export function exportFacilityTransactionExcel(facility: Facility, timeframe: Timeframe, transactions: AccountTransaction[], sum: TransactionProps) {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheet, '');

    XLSX.utils.sheet_add_aoa(worksheet, [['Statistik']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(worksheet, [['Club: ' + facility.name]], { origin: 'A2' });
    XLSX.utils.sheet_add_aoa(worksheet, [[`Zeittraum: ${luxonDate(timeframe.from).toFormat('dd.MM.yyyy')} - ${luxonDate(timeframe.until).toFormat('dd.MM.yyyy')}`]], { origin: 'A3' });

    const headers = ["Zeitstempel", "Freigegeben am", "Account", "Geldbörse", "Typ", "Gerät", "Ausgang #", "Transaktion Menge", "Bälle", "Sachbearbeiter", "Betrag (Club)", "Betrag (Pin Point)", "Vorige Kontostand", 'Neue Kontostand', "Notiz"];
    let rows: Record<string, string>[] = transactions.map(t => ({
        timestamp: displayDateTime(t.timestamp),
        clearedAt: t.clearedAt ? displayDateTime(t.clearedAt) : '',
        account: displayAccountNameLine(t.wallet.account),
        wallet: t.wallet.type === WalletType.Facility ? 'Club' : 'Pin Point',
        type: getTypeName(t.type),
        device: t.device ? (t.device.name || t.device.id) : '',
        port: t.transactionPortName ?? '',
        value: String(t.transactionValue ?? ''),
        balls: String(t.ballQuantity ?? ''),
        clerk: t.user ? displayUserNameLine(t.user) : '',
        amountClub: t.wallet.type === WalletType.Facility ? formatAmount(t.transactionAmount) : '',
        amountIntl: t.wallet.type === WalletType.International ? formatAmount(t.transactionAmount) : '',
        previousBalance: t.previousAmount ? formatAmount(t.previousAmount) : '',
        newBalance: t.newAmount ? formatAmount(t.newAmount) : '',
        note: t.note ?? '-'
    }));

    XLSX.utils.sheet_add_json(worksheet, rows, { origin: "A5" });
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A5" });

    formatCellAsNumber(worksheet, 5, 7, rows.length);
    formatCellAsNumber(worksheet, 5, 8, rows.length);
    formatCellAsNumber(worksheet, 5, 10, rows.length, '0.00');
    formatCellAsNumber(worksheet, 5, 11, rows.length, '0.00');
    formatCellAsNumber(worksheet, 5, 12, rows.length, '0.00');
    formatCellAsNumber(worksheet, 5, 13, rows.length, '0.00');

    worksheet["!cols"] = computeColumnLengths(rows);

    let nextRow = rows.length + 1 + 3 + 2;
    const footerNodes = [
        { key: 'Gesamt Aufbuchungen (Club)', value: sum.totalBookInClub },
        { key: 'Gesamt Aufbuchungen (International)', value: sum.totalBookOutIntl },
        { key: 'Gesamt Aufbuchungen', value: sum.totalBookIn },
        { key: 'Gesamt Abbuchungen (Club)', value: sum.totalBookOutClub },
        { key: 'Gesamt Abbuchungen (International)', value: sum.totalBookOutIntl },
        { key: 'Gesamt Abbuchungen', value: sum.totalBookOut },
        { key: 'Gesamt ausgegebene Bälle', value: sum.totalBallQuantity, format: '0' },
        { key: `Pin Point Service Gebühr (${facility.pinpointServiceFee ?? '0'} %)`, value: sum.serviceFee },
        { key: 'Pay-Out', value: sum.payout },
    ]

    const merges = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 11 } },
    ];

    for (let node of footerNodes) {
        nextRow += 1;
        XLSX.utils.sheet_add_aoa(worksheet, [[node.key]], { origin: { r: nextRow, c: 0 } });
        XLSX.utils.sheet_add_aoa(worksheet, [[formatAmount(node.value)]], { origin: { r: nextRow, c: 9 } });

        formatCellAsNumber(worksheet, nextRow, 9, 1, node.format ?? '0.00');

        merges.push({ s: { r: nextRow, c: 0 }, e: { r: nextRow, c: 8 } });
        merges.push({ s: { r: nextRow, c: 9 }, e: { r: nextRow, c: 11 } });
    }

    worksheet["!merges"] = merges;

    XLSX.writeFile(workbook, `Club Buchungen - ${facility.name} - ${luxonDate(timeframe.from).toFormat('dd.MM.yyyy')} - ${luxonDate(timeframe.until).toFormat('dd.MM.yyyy')}.xlsx`, { compression: true });
}