import {AuthSession, User, UserRole} from "../data/types";
import {authorize} from "../data/auth";
import {luxonDate} from "../tools";

const UserStorageKey = 'user';
const FacilityStorageKey = 'facility';
const SessionStorageKey = 'session';

type FacilityStoredData = {
    uuid: string,
    name: string
}

type UserStoredData = {
    uuid: string,
    role?: UserRole;
}

type SessionStoredData = {
    token: string,
    expiredAt: number
}

class AuthorizedUserStore {

     setFacility(uuid: string, name: string) {
         localStorage.setItem(FacilityStorageKey, JSON.stringify({ uuid, name }));
     }

     setUser(uuid: string, role?: UserRole) {
         localStorage.setItem(UserStorageKey, JSON.stringify({ uuid, role }));
     }

    setSession(token: string, expiredAt: number) {
        localStorage.setItem(SessionStorageKey, JSON.stringify({ token, expiredAt }));
    }

    handleSession(session: AuthSession) {
        const {user, expiredAt, token} = session;
        this.setUser(user.uuid, user.role);
        if (user.facility) this.setFacility(user.facility.uuid, user.facility.name);
        this.setSession(token, luxonDate(expiredAt).toJSDate().getTime());
    }

     get facility() {
         const data = localStorage.getItem(FacilityStorageKey);
         return data ? JSON.parse(data) as FacilityStoredData : undefined;
     }

     get user() {
         const data = localStorage.getItem(UserStorageKey);
         return data ? JSON.parse(data) as UserStoredData : undefined;
     }

    get session() {
        const data = localStorage.getItem(SessionStorageKey);
        return data ? JSON.parse(data) as SessionStoredData : null;
    }

    private static isNotExpired(session: SessionStoredData) {
        return session && new Date().getTime() < session.expiredAt;
    }

     get isAdmin() {
         return !!this.user && UserRole.Admin === this.user?.role;
     }

     get isRoot() {
         return !!this.user && UserRole.Root === this.user?.role;
     }

    get authenticated() {
        const session = this.session;
        return session && AuthorizedUserStore.isNotExpired(session);
    }

    get token() {
        const session = this.session;
        return session ? session.token : '';
    }

     load(): Promise<User|undefined> {
         return authorize()
             .then(s => {
                 if (s) {
                     this.setUser(s.user.uuid, s.user.role);
                     if (UserRole.Root !== s.user.role && s.user.facility) this.setFacility(s.user.facility.uuid, s.user.facility.name);
                 }
                 return s?.user;
             });
     }
}

export const authStore = new AuthorizedUserStore();