import {User} from "./types";
import {authStore} from "../store";

function prepareHeader() {
    let headers: any = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    };
    if(authStore.authenticated) {
        headers = {
            ...headers,
            'Authorization': `Bearer ${authStore.token}`,
        };
    }
    return headers;
}

// export const me = async () => {
//     let user;
//     try {
//         const response = await fetch('/api/me', { headers: prepareHeader() });
//         if (response.ok) {
//             user = await response.json() as User;
//         }
//     } catch (error) {
//         console.error('Failed to fetch current user', error);
//     }
//     return user;
// };

export const confirmAccount = async (email?: string, phone?: string) => {
    try {
        let path = '/api/auth/confirm?';
        if (!!email) path += 'email=' + email;
        if (!!phone) path += 'phone=' + phone;
        await fetch(path, { headers: prepareHeader(), method: 'POST' });
    } catch (error) {
        console.error('Failed to fetch current user', error);
    }
}

export const confirmProfileChange = async (email?: string, phone?: string) => {
    try {
        let path = '/api/auth/profile-confirm?';
        if (!!email) path += 'email=' + email;
        if (!!phone) path += 'phone=' + phone;
        await fetch(path, { headers: prepareHeader(), method: 'POST' });
    } catch (error) {
        console.error('Failed to fetch current user', error);
    }
}

export const resetPassword = async (token: string, password: string) => {
    let path = '/api/auth/reset-password/' + token;
    const response = await fetch(path, { headers: prepareHeader(), method: 'PUT', body: JSON.stringify({ password, confirmPassword: password })});
    if (!response.ok) throw new Error('Failed to reset password.');
}